import { lazy } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, RouteObject } from "react-router";

import { ProfileLayout } from "~/layouts/ProfileLayout";

import { LoyaltyBenefitsModal } from "~/features/loyalty-benefits-modal/LoyaltyBenefitsModal";
import { ChangeEmail } from "~/features/change-email/ChangeEmail";
import { RemoveAccount } from "~/features/remove-account/RemoveAccount";

import { routeMapper } from "~/helpers/route-mapper";

import { useBaseConfig } from "~/config/MyAccountConfigContext";

import { formatNestedPath } from "./formatNestedPath";

const HomePage = lazy(() => import("~/pages/HomePage"));
const ProfilePage = lazy(() => import("~/pages/profile/index"));

const NewsletterPreferencesPage = lazy(
  () => import("~/pages/profile/NewsletterPreferencesPage"),
);

const LoyaltyCardsPage = lazy(() => import("~/pages/profile/LoyaltyCardsPage"));
const ChangePasswordPage = lazy(
  () => import("~/pages/profile/ChangePasswordPage"),
);

const PersonalInformationPage = lazy(
  () => import("~/pages/profile/PersonalInformationPage"),
);

const ReceiptDetailPage = lazy(
  () => import("~/pages/receipt/ReceiptDetailPage"),
);

const LoyaltyPointMutationsPage = lazy(
  () => import("~/pages/LoyaltyPointMutationsPage"),
);

const OrderDetailsPage = lazy(() => import("~/pages/order/OrderDetailsPage"));

const PurchaseDetailsPage = lazy(
  () => import("~/pages/purchase/PurchaseDetailsPage"),
);

const PurchasesPage = lazy(() => import("~/pages/PurchasesPage"));

const SavedConfigurationsPage = lazy(
  () => import("~/pages/SavedConfigurationsPage"),
);

export function useAuthenticatedRoutes(): RouteObject[] {
  const { t } = useTranslation("urls");
  const { country } = useBaseConfig();

  return [
    {
      element: <ProfileLayout />,
      children: [
        {
          path: t("/myaccount"),
          element: <HomePage />,
          children: [
            {
              path: t("/loyalty-benefits"),
              element: <LoyaltyBenefitsModal />,
            },
          ],
        },
        {
          path: t("/profile"),
          children: [
            {
              index: true,
              element: <ProfilePage />,
            },
            {
              path: formatNestedPath(
                t("/profile/personal-information"),
                t("/profile"),
              ),
              element: <PersonalInformationPage />,
              children: [
                {
                  path: t("/profile/personal-information/change-email"),
                  element: <ChangeEmail />,
                },
                {
                  path: t("/profile/personal-information/delete"),
                  element: <RemoveAccount />,
                },
              ],
            },
            {
              path: formatNestedPath(
                t("/profile/change-password"),
                t("/profile"),
              ),
              element: <ChangePasswordPage />,
            },
            {
              path: formatNestedPath(t("/profile/newsletter"), t("/profile")),
              element: <NewsletterPreferencesPage />,
            },
            {
              path: formatNestedPath(t("/profile/loyaltycards"), t("/profile")),
              element: <LoyaltyCardsPage />,
            },
          ],
        },
        {
          path: t("/saved-configurations"),
          element: <SavedConfigurationsPage />,
        },
        {
          path: t("/purchases"),
          element: <PurchasesPage />,
        },
        {
          path: t("/orders"),
          children: [
            {
              index: true,
              element: <Navigate replace to={t("/purchases")} />,
            },
            {
              path: ":code",
              element: <OrderDetailsPage />,
            },
          ],
        },
        {
          path: `${t("/purchases")}/:code`,
          element: <PurchaseDetailsPage />,
        },
        {
          path: `${t("/receipts")}`,
          element: <Navigate to={t("/purchases")} />,
        },
        {
          path: `${t("/receipts")}/:receiptId`,
          element: <ReceiptDetailPage />,
        },
        {
          path: t("/loyaltyMutations"),
          element: <LoyaltyPointMutationsPage />,
        },
        {
          path: t("/remove-account"),
          element: <Navigate replace to={t("/profile/personal-information")} />,
        },

        // Redirects for guest mode routes
        {
          path: t("/login"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/sign-up"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/forgot-password"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/profile/newsletter"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
      ].map((route) => routeMapper(country, route)),
    },
  ];
}
