import { forwardRef, type SVGProps } from "react";

export interface UserIconProps extends SVGProps<SVGSVGElement> {}

export const User = forwardRef<SVGSVGElement, UserIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6448 6.40003C17.6448 9.93467 14.8141 12.8001 11.3224 12.8001C7.83063 12.8001 5 9.93467 5 6.40003C5 2.86539 7.83063 0 11.3224 0C14.8141 0 17.6448 2.86539 17.6448 6.40003ZM16.1448 6.40003C16.1448 9.12356 13.9685 11.3001 11.3224 11.3001C8.67626 11.3001 6.5 9.12356 6.5 6.40003C6.5 3.6765 8.67626 1.5 11.3224 1.5C13.9685 1.5 16.1448 3.6765 16.1448 6.40003Z"
      fill="currentColor"
    />
    <path
      d="M2.51865 23.2601C2.88473 19.6035 5.9351 16.7498 9.6439 16.7498H13.5954C17.3042 16.7498 20.3545 19.6035 20.7206 23.2601C20.7614 23.6672 21.0892 23.9999 21.4984 23.9999C21.9076 23.9999 22.2425 23.667 22.2087 23.2592C21.8373 18.7733 18.1228 15.2498 13.5954 15.2498H9.6439C5.11645 15.2498 1.40197 18.7733 1.03055 23.2592C0.996781 23.667 1.33173 23.9999 1.74092 23.9999C2.15011 23.9999 2.47789 23.6672 2.51865 23.2601Z"
      fill="currentColor"
    />
  </svg>
));

(User as React.FC).displayName = "User";
