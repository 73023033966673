import { getKioskData as getKioskDataFromUtil } from "@intergamma/kiosk-util";

export interface KioskData {
  isKiosk: boolean;
  store: string | null;
  storeDepartment: string | null;
}

export async function getKioskData(): Promise<KioskData> {
  const kioskData = await getKioskDataFromUtil();

  if (!kioskData.isKiosk) {
    return {
      isKiosk: false,
      store: null,
      storeDepartment: null,
    };
  }

  return {
    isKiosk: true,
    store: kioskData.store,
    storeDepartment: kioskData.storeDepartment,
  };
}
