import { createHttpClient } from "@intergamma/account";

import type {
  OptIns,
  UpdateOptInsBody,
  OptInsResponse,
  OptInsRequestParams,
} from "./types/OptIns";

export function createOptInsService(MYACCOUNT_URL: string) {
  const optInsClient = createHttpClient(`${MYACCOUNT_URL}/api`, {
    withCredentials: true,
  });

  return {
    getOptIns({ email, hash }: OptInsRequestParams): Promise<OptIns> {
      const url =
        hash && email
          ? `/optins?hash=${encodeURIComponent(
              hash,
            )}&email=${encodeURIComponent(email)}`
          : "/optins";

      return optInsClient.get<OptInsResponse>(url);
    },
    updateOptIns(
      body: UpdateOptInsBody,
      { hash }: OptInsRequestParams,
    ): Promise<OptIns> {
      const url = hash ? `/optins?hash=${encodeURIComponent(hash)}` : "/optins";

      return optInsClient.post<OptInsResponse>(url, body);
    },
  };
}
