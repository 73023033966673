import { media } from "@intergamma/theme";

import styled, { css, DefaultTheme } from "styled-components";

interface CardProps {
  theme: DefaultTheme;
  $actionable?: boolean;
  $stretch?: boolean;
  $standAlone?: boolean;
}

export const CardTitle = styled.h2`
  margin: 0;
  font-family: ${(props) => props.theme.brandFont};
  font-size: ${fontSize}em;
  font-weight: bold;
  text-transform: ${textTransform};
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const CardSubtitle = styled.h3`
  margin: 0;
  font-family: ${(props) => props.theme.baseFont};
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.4em;
  color: ${(props) => props.theme.mutedColor};
`;

export const CardContent = styled.div<{ $secondary?: boolean }>`
  font-family: ${(props) => props.theme.baseFont};
  background-color: ${(props) =>
    props.$secondary ? props.theme.footerBackgroundColor : "transparent"};
  background-color: "transparent";
  margin-top: 0;
  padding-bottom: 0;

  &:first-child {
    border-top-left-radius: ${(props) => props.theme.borderRadius}px;
    border-top-right-radius: ${(props) => props.theme.borderRadius}px;
  }
  &:last-child {
    border-bottom-left-radius: ${(props) => props.theme.borderRadius}px;
    border-bottom-right-radius: ${(props) => props.theme.borderRadius}px;
  }

  ${media.md`
    margin-top: 0;
    padding-bottom: 0;
  `};
`;

export const Card = styled.div<Omit<CardProps, "theme">>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius}px;
  border: ${({ theme, $standAlone }) =>
    !$standAlone ? `1px solid ${theme.colors.grey200}` : "none"};
  color: inherit;
  transition: all 0.2s;
  height: ${(props) => props.$stretch && "100%"};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      opacity: 0.6;
    `};

  &:hover {
    ${(props) =>
      (props.onClick || props.$actionable) &&
      css`
        border-color: ${props.theme.mutedColor};
        opacity: 1;
      `};
  }

  ${CardTitle} {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${CardTitle} + ${CardSubtitle} {
    padding-top: 5px;
  }

  ${CardTitle}, ${CardSubtitle}, ${CardContent} {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:first-child {
      padding-top: 1rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }

    ${media.md`
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    `};
  }
`;

function fontSize(props: CardProps) {
  return {
    karwei_nl: 1.3,
    gamma_nl: 1.3,
    gamma_be: 1.1,
  }[props.theme.name];
}

function textTransform(props: CardProps) {
  return {
    karwei_nl: "none",
    gamma_nl: "none",
    gamma_be: "uppercase",
  }[props.theme.name];
}
