import {
  ComponentProps,
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  useId,
  useState,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Eye, EyeHidden } from "../icons";

import { PasswordStrength } from "./helpers/calculatePasswordStrength";
import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";
import { inputStyles } from "./styles";

type PasswordFieldProps = ComponentPropsWithRef<"input"> & {
  label: string;
  description?: ReactNode;
  error?: ReactNode;
  info?: ComponentProps<typeof Label>["info"];
  passwordStrength?: PasswordStrength;
  optional?: ComponentProps<typeof Label>["optional"];
};

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function PasswordField(
    {
      className,
      description,
      error,
      info,
      label,
      optional,
      passwordStrength = PasswordStrength.UNDEFINED,
      ...inputProps
    },
    ref,
  ) {
    const id = useId();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
      <div className={cn("flex flex-col", className)}>
        <Label htmlFor={id} info={info} optional={optional}>
          {label}
        </Label>
        <div className="relative">
          {passwordStrength !== PasswordStrength.UNDEFINED && (
            <div
              className={cn(
                "absolute -bottom-0.5 h-3 motion-safe:transition-all",
                passwordStrength === PasswordStrength.SHORT &&
                  "w-1/3 bg-brand-new gamma:rounded-bl-md",
                (passwordStrength === PasswordStrength.WEAK ||
                  passwordStrength === PasswordStrength.AVERAGE) &&
                  "w-2/3 bg-ignew-functional-tertiary-500 gamma:rounded-bl-md",
                passwordStrength === PasswordStrength.STRONG &&
                  "w-full bg-ignew-functional-secondary-500 gamma:rounded-b-md",
              )}
            />
          )}
          <input
            type={isPasswordVisible ? "text" : "password"}
            id={id}
            ref={ref}
            className={cn(
              inputStyles({ error }),
              "relative pr-14 focus:shadow-none",
            )}
            {...inputProps}
          />
          <div className="absolute right-4 top-0 grid h-full place-items-center">
            <button
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              className="size-6 rounded bg-transparent outline-none ring-ignew-functional-focus-400 ring-offset-1 focus-visible:ring-3"
            >
              {isPasswordVisible ? <EyeHidden /> : <Eye />}
            </button>
          </div>
        </div>
        {error && <Error className="mt-1">{error}</Error>}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
