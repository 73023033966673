import { getDomain as S } from "@intergamma/common/formula";
import w from "js-cookie";
import { Amplify as y } from "aws-amplify";
import { useMemo as L, useState as C } from "react";
import { dispatchAdobeEvent as m } from "@intergamma/adobe-tracking";
import { signIn as P, confirmSignIn as k } from "aws-amplify/auth";
import { useQueryClient as v } from "@tanstack/react-query";
import { useTranslation as A } from "react-i18next";
import { l as d } from "./datadog-logging-Ds9IeYxC.js";
import { useConfig as T } from "@intergamma/config";
import { c as F } from "./index-C2EW4nef.js";
const M = (e) => {
  const r = typeof document < "u" && document.location.href.match(/\/\/([^:/]+)/), o = (r ? r[1] : "").indexOf("acceptatie") < 0;
  switch (e) {
    case "gamma_be":
      return {
        userPoolId: o ? "eu-west-1_KwrkK0ufX" : "eu-west-1_j0JUmjRMW",
        // GAMMA BE ACC - USER POOL ID
        userPoolClientId: o ? "6bi3hbinnkcq1h12ctlige9f08" : "1te9tbna7h0sd6bemnevublnts"
        // GAMMA BE ACC - CLIENT ID
      };
    case "karwei_nl":
      return {
        userPoolId: o ? "eu-west-1_Ogv9zM2Zp" : "eu-west-1_AzWkTXNK8",
        // KARWEI ACC - USER POOL ID
        userPoolClientId: o ? "6lqki7p47e0q3etbn87pvb56op" : "7crtb2r6sfb9spi854qq0qcp1b"
        // KARWEI ACC - CLIENT ID
      };
    case "gamma_nl":
    default:
      return {
        userPoolId: o ? "eu-west-1_OHzUYP2T4" : "eu-west-1_Sm2PRyfmS",
        // GAMMA NL ACC - USER POOL ID
        userPoolClientId: o ? "78km7bhfrsa50ajiebbi8ng65q" : "64pji0q6h1armolasivbn51ftq"
        // GAMMA NL ACC - CLIENT ID
      };
  }
};
function N(e) {
  const r = w.get();
  Object.keys(r).forEach((t) => {
    t.startsWith("CognitoIdentityServiceProvider") && w.remove(t, { path: "/", domain: e });
  });
}
function K(e) {
  N(S(e)), y.configure({
    Auth: {
      Cognito: M(e)
    }
  });
}
function R(e) {
  const r = F(e, {
    withCredentials: !0
  });
  return {
    async getGatewayCSRF() {
      return (await r.get(
        "/gateway/session"
      )).csrfToken;
    },
    async login(t) {
      const o = await this.getGatewayCSRF();
      return r.post("/gateway/addcard/login", t, {
        headers: {
          "X-IG-CSRF-TOKEN": o
        }
      });
    }
  };
}
const U = () => {
  const { MYACCOUNT_URL: e } = T(), r = L(
    () => R(e),
    [e]
  );
  return {
    legacyLogin: (o) => new Promise((a, f) => {
      r.login(o).then(() => a()).catch(() => f());
    })
  };
};
function X(e = "loginHeader", r = !0) {
  const { t } = A(["ig-header", "register", "login-form"]), [o, a] = C(!1), [f, h] = C(), p = v(), { legacyLogin: I } = U();
  return {
    authenticate: ({
      body: n,
      disableAdobeEvent: _
    }) => (a(!0), n.password ? new Promise((l, u) => {
      P({
        username: n.customerId,
        password: n.password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      }).then(() => (_ || m({
        type: e === "loginHeader" ? "login_header" : "login",
        data: {
          user_selected_element: "password",
          kdbid: n.customerId,
          kdbid_hashed: n.hashedId
        }
      }), a(!1), r && p.resetQueries(), d.info("Login", {
        details: "Successful"
      }), l())).catch((g) => {
        const { name: i, message: s } = g;
        I({ email: n.email, password: n.password }).then(() => (a(!1), r && p.resetQueries(), d.info("Legacy login", {
          details: "Successful"
        }), l)).catch(() => {
          d.error("Login", {
            details: "Failed",
            errorDetails: `${i}: ${s}`
          }), m({
            type: e === "loginHeader" ? "login_error_header" : "login_error",
            data: {
              user_message_type: `${i}: ${s}`
            }
          });
          let c = t("ig-header:loginSomethingWentWrong");
          return i === "NotAuthorizedException" && (c = t("ig-header:loginInvalidPassword"), s === "Password attempts exceeded" && (c = t("ig-header:loginTooManyAttempts"))), i === "UserNotFoundException" && (c = t("ig-header:loginUserNotFound")), a(!1), h(c), u();
        });
      });
    }) : new Promise((l, u) => {
      k({
        challengeResponse: n.verificationCode ?? ""
      }).then((g) => {
        const { isSignedIn: i, nextStep: s } = g;
        return a(!1), i ? (d.info("Login via code", {
          details: "Successful"
        }), _ || m({
          type: e === "loginHeader" ? "login_header" : "login",
          data: {
            user_selected_element: "code",
            kdbid: n.customerId,
            kdbid_hashed: n.hashedId
          }
        }), r && p.resetQueries(), l()) : (s.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE" && (d.warn("Login via code", {
          details: "Invalid code"
        }), h(t("register:errorNotValidCode"))), u());
      }).catch((g) => {
        const { name: i, message: s } = g;
        m({
          type: e === "loginHeader" ? "login_error_header" : "login_error",
          data: {
            user_message_type: `${i}: ${s}`
          }
        });
        let c = t("ig-header:loginSomethingWentWrong");
        return i === "NotAuthorizedException" ? (c = t("login-form:loginCodeMaxAttempts"), d.error("Login via code", {
          details: "Reached max attempts",
          errorDetails: `${i}: ${s}`
        })) : d.error("Login via code", {
          details: "Failed",
          errorDetails: `${i}: ${s}`
        }), a(!1), h(c), u();
      });
    })),
    error: f,
    isLoading: o
  };
}
export {
  K as c,
  M as g,
  X as u
};
