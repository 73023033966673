import { forwardRef, type SVGProps } from "react";

export interface FeedbackIconProps extends SVGProps<SVGSVGElement> {}

export const Feedback = forwardRef<SVGSVGElement, FeedbackIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M6.24997 8.25C6.24997 7.83579 6.58576 7.5 6.99997 7.5H17C17.4142 7.5 17.75 7.83579 17.75 8.25C17.75 8.66421 17.4142 9 17 9H6.99997C6.58576 9 6.24997 8.66421 6.24997 8.25Z"
        fill="currentColor"
      />
      <path
        d="M6.99997 11.5C6.58576 11.5 6.24997 11.8358 6.24997 12.25C6.24997 12.6642 6.58576 13 6.99997 13H13C13.4142 13 13.75 12.6642 13.75 12.25C13.75 11.8358 13.4142 11.5 13 11.5H6.99997Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75006 5C1.75006 2.92893 3.42899 1.25 5.50006 1.25H18.5C20.571 1.25 22.25 2.92893 22.25 5V16.2555C22.25 18.3266 20.571 20.0055 18.5 20.0055H6.82982L4.77261 22.1849C3.68564 23.3365 1.74998 22.5673 1.75 20.9837L1.75006 5ZM5.50006 2.75C4.25742 2.75 3.25006 3.75736 3.25006 5L3.25 20.9837C3.25 21.2099 3.52652 21.3198 3.6818 21.1553L5.961 18.7407C6.10269 18.5906 6.29999 18.5055 6.50641 18.5055H18.5C19.7426 18.5055 20.75 17.4981 20.75 16.2555V5C20.75 3.75736 19.7426 2.75 18.5 2.75H5.50006Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Feedback as React.FC).displayName = "Feedback";
