import { createHttpClient } from "@intergamma/account";

import { mapReceiptResponse } from "./mappers/mapReceiptResponse";
import { mapReceiptSummaryResponse } from "./mappers/mapReceiptSummaryResponse";
import type { Receipt, ReceiptResponse } from "./types/Receipt";
import type {
  ReceiptSummaryResponse,
  ReceiptSummary,
} from "./types/ReceiptSummary";

export function createReceiptService(MYACCOUNT_URL: string) {
  const receiptsClient = createHttpClient(`${MYACCOUNT_URL}/api/receipts`, {
    withCredentials: true,
  });

  return {
    getReceipts(): Promise<ReceiptSummary[]> {
      return receiptsClient
        .get<ReceiptSummaryResponse[]>("/")
        .then((response) => mapReceiptSummaryResponse(response));
    },
    getReceipt(receiptId: string): Promise<Receipt> {
      return receiptsClient
        .get<ReceiptResponse>(`/${receiptId}`)
        .then((receipt) => mapReceiptResponse(receipt));
    },
  };
}
