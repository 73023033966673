import { jsx as e } from "react/jsx-runtime";
import * as m from "@radix-ui/react-dialog";
const r = ({ children: i, ...t }) => /* @__PURE__ */ e(
  m.Title,
  {
    ...t,
    className: "mb-4 text-[1.4rem] font-medium leading-none text-brand-primary",
    children: i
  }
);
export {
  r as DialogTitle
};
