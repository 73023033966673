import {
  ComponentProps,
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  useId,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";
import { inputStyles } from "./styles";

type TextFieldProps = ComponentPropsWithRef<"input"> & {
  label: string;
  description?: ReactNode;
  error?: ReactNode;
  info?: ComponentProps<typeof Label>["info"];
  icon?: ReactNode;
  optional?: ComponentProps<typeof Label>["optional"];
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function TextField(
    {
      className,
      label,
      description,
      error,
      info,
      optional,
      type = "text",
      icon = null,
      ...inputProps
    },
    ref,
  ) {
    const id = useId();

    return (
      <div className={cn("flex flex-col", className)}>
        <Label htmlFor={id} info={info} optional={optional}>
          {label}
        </Label>
        <div className="relative">
          <input
            type={type}
            id={id}
            ref={ref}
            className={inputStyles({ error, type })}
            {...inputProps}
          />
          {icon && (
            <div className="absolute right-[14px] top-[14px]">{icon}</div>
          )}
        </div>
        {error && typeof error !== "boolean" && (
          <Error className="mt-1">{error}</Error>
        )}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
