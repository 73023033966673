// TODO: Move redirects to Hosting instead of client side redirects
export const gammaNlBeRedirects = {
  "/my/mijn-gegevens": "/my/nl/mijn-gegevens/persoonlijke-gegevens",
  "/my/profile": "/my/nl/mijn-gegevens/persoonlijke-gegevens",
  "/my/personal-information": "/my/nl/mijn-gegevens/persoonlijke-gegevens",
  "/my/preferences": "/my/nl/mijn-gegevens/nieuwsbrief",
  "/my/change-email": "/my/nl/mijn-gegevens/e-mailadres-wijzigen",
  "/my/change-password": "/my/nl/mijn-gegevens/wachtwoord-wijzigen",
  "/my/loyaltycards": "/my/nl/mijn-gegevens/gamma-plus-kaart",
  "/my/orders": "/my/nl/mijn-bestellingen",
  "/my/remove-account": "/my/nl/verzoek-tot-verwijderen-account",
  "/my/loyaltyMutations": "/my/nl/loyalty-transactie-overzicht",
  "/my/orderstatus": "/my/nl/bestelstatus-opvragen",
  "/my/logout": "/my/nl/uitloggen",
  "/my/forgot-password": "/my/nl/nieuw-wachtwoord-aanvragen",
  "/my/password-recovery": "/my/nl/nieuw-wachtwoord-aanvragen/verificatie",
  "/my/register-loyalty-card": "/my/nl/gamma-plus-kaart/registreren",
  "/my/register-loyalty-card/activate": "/my/nl/gamma-plus-kaart/activeer",
  "/my/giftcard": "/my/nl/cadeaukaart",
  "/my/request-return": "/my/nl/aanmelden-retour",
  "/my/newsletter/subscribe": "/my/nl/aanmelden-nieuwsbrief",
  "/my/service-and-contact/contact": "/my/nl/service-en-contact/contact",
  "/my/success/register": "/my/nl/account-aanmaken-bevestigd",
  "/my/service-en-contact": "/my/nl/service-en-contact/contact",
  "/my/login": "/my/nl/inloggen",
  "/my/confirm-email": "/my/nl/e-mailadres-bevestigen",
  "/my/profile/edit/email":
    "/my/nl/mijn-gegevens/persoonlijke-gegevens/e-mailadres-wijzigen",
  "/my/profile/edit/newsletters": "/my/nl/mijn-gegevens/nieuwsbrief",
  "/my/profile/edit/personal_info":
    "/my/nl/mijn-gegevens/persoonlijke-gegevens",
  "/my/profile/edit/password": "/my/nl/mijn-gegevens/wachtwoord-wijzigen",
  "/my/profile/edit/loyaltycards": "/my/nl/mijn-gegevens/gamma-plus-kaart",
  "/my/password_recovery": "/my/nl/nieuw-wachtwoord-aanvragen/verificatie",
  "/my/order": "/my/nl/bestelstatus-opvragen",
  "/my/return": "/my/nl/aanmelden-retour",
  "/my/giftcard/showBalance": "/my/nl/cadeaupas",
  "/my/verificatie-nieuwsbrief-aanvraag": "/my/nl/verificatie/newsletter",
  "/my/gamma-plus-kaart": "/my/nl/mijn-gegevens/gamma-plus-kaart",
  "/my/purchases": "/my/nl/mijn-aankopen",
};

export const gammaFrBeRedirects = {
  "/my/mes-donnees": "/my/fr/mes-donnees/mes-donnes-personnelles",
  "/my/profile": "/my/fr/mes-donnees/mes-donnes-personnelles",
  "/my/personal-information": "/my/fr/mes-donnees/mes-donnes-personnelles",
  "/my/preferences": "/my/fr/mes-donnees/lettre-info",
  "/my/change-email": "/my/fr/mes-donnees/changer-ladresse-mail",
  "/my/change-password": "/my/fr/mes-donnees/changer-le-mot-de-passe",
  "/my/loyaltycards": "/my/fr/mes-donnees/carte-gamma-plus",
  "/my/orders": "/my/fr/mes-commandes",
  "/my/remove-account": "/my/fr/demande-de-suppression-de-mon-compte",
  "/my/loyaltyMutations": "/my/fr/GAMMAplus-liste-transactions",
  "/my/fr/loyalty-transactie-overzicht": "/my/fr/GAMMAplus-liste-transactions",
  "/my/orderstatus": "/my/fr/etat-de-la-commande",
  "/my/logout": "/my/fr/se-deconnecter",
  "/my/forgot-password": "/my/fr/demander-nouveau-mot-de-passe",
  "/my/password-recovery": "/my/fr/demander-nouveau-mot-de-passe/verification",
  "/my/register-loyalty-card": "/my/fr/carte-gamma-plus/registre",
  "/my/register-loyalty-card/activate": "/my/fr/carte-gamma-plus/activer",
  "/my/giftcard": "/my/fr/carte-cadeau",
  "/my/request-return": "/my/fr/inscription-retour",
  "/my/newsletter/subscribe": "/my/fr/inscrire-lettre-info",
  "/my/service-and-contact/contact": "/my/fr/service-en-contact/contact",
  "/my/success/register": "/my/fr/creer-un-compte-confirme",
  "/my/service-en-contact": "/my/fr/service-en-contact/contact",
  "/my/login": "/my/fr/se-connecter",
  "/my/confirm-email": "/my/fr/confirmer-ladresse-mail",
  "/my/profile/edit/email":
    "/my/fr/mes-donneesmes-donnes-personnelles/mes-donnes-personnelles/changer-ladresse-mail",
  "/my/profile/edit/newsletters": "/my/fr/mes-donnees/lettre-info",
  "/my/profile/edit/personal_info":
    "/my/fr/mes-donnees/mes-donnes-personnelles",
  "/my/profile/edit/password": "/my/fr/mes-donnees/changer-le-mot-de-passe",
  "/my/profile/edit/loyaltycards": "/my/fr/mes-donnees/carte-gamma-plus",
  "/my/password_recovery": "/my/fr/demander-nouveau-mot-de-passe/verification",
  "/my/order": "/my/fr/etat-de-la-commande",
  "/my/return": "/my/fr/inscription-retour",
  "/my/giftcard/showBalance": "/my/fr/carte-cadeau",
  "/my/verification-lettre-info-demande": "/my/fr/verification/newsletter",
  "/my/carte-gamma-plus": "/my/fr/mes-donnees/carte-gamma-plus",
  "/my/purchases": "/my/fr/mes-achats",
};
