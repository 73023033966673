import { gamma_be as y, gamma_nl as $, karwei_nl as x } from "./nextjs.js";
import m, { css as r, createGlobalStyle as c } from "styled-components";
const o = r, l = {
  md: (...e) => o`
    @media only screen and (min-width: 768px) {
      ${o(...e)};
    }
  `,
  lg: (...e) => o`
    @media only screen and (min-width: 1024px) {
      ${o(...e)};
    }
  `,
  xl: (...e) => o`
    @media only screen and (min-width: 1264px) {
      ${o(...e)};
    }
  `
}, h = Object.keys(l), b = h.reduce((e, t) => {
  const i = t.charAt(0).toUpperCase() + t.slice(1), n = m.div`
    display: none;
    ${l[t]`
      display: initial;
    `};
  `;
  return n.displayName = `Media.${i}`, e[i] = n, e;
}, {}), u = c`
  * {
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
  }
  body {
    font-family: ${(e) => e.theme.baseFont};
  }
  input,
  textarea,
  select,
  button {
    font-size: 100%;
    font-family: inherit;
    padding: 0 12px;
    background: #fff;
    border: 1px solid ${(e) => e.theme.formInputBorderColor};
    border-radius: ${(e) => e.theme.borderRadius}px;
    height: 46px;
    &:focus {
      outline: 0;
      border-color: ${(e) => e.theme.buttonColor};
      box-shadow: 0 0 0 3px ${(e) => e.theme.buttonHighlightColor};
    }
  }
  a {
    color: ${(e) => e.theme.linkColor};
    text-decoration: underline;
    &:focus {
      outline: 0;
      border-radius: ${(e) => e.theme.borderRadius / 2}px;
      box-shadow: 0 0 0 3px ${(e) => e.theme.linkHighlightColor};
    }
    &:hover,
    &:active {
      color: ${(e) => e.theme.linkHoverColor};
      text-decoration: none;
    }
  }
  p {
    line-height: 1.4em;
    margin: 1em 0;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  h1, h2, h3 {
    font-family: ${(e) => e.theme.brandFont};
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  blockquote {
    margin: 1em 18px;
  }
  ul, ol {
    margin: 1em 0;
    padding: 0 0 0 18px;
    line-height: 1.4em;
  }
  li {
    margin: 5px 0;
  }

  ::-moz-selection {
    background: ${(e) => e.theme.selectionBackground};
  }
  ::selection {
    background: ${(e) => e.theme.selectionBackground};
  }
  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #b3b3b3;
    opacity: 1;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="search"] {
    -webkit-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-ms-clear {
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    width: 0;
    height: 0;
  }

  ${(e) => e.theme.fonts && e.includeFonts !== !1 && e.theme.fonts.map(
  ({
    family: t,
    file: i,
    woff2file: n,
    style: s = "normal",
    weight: d = "normal"
  }) => {
    const a = [];
    return e.useWoff2Font && n ? a.push(
      `url("${e.assetsPublicPath || ""}/fonts/${n}") format("woff2")`
    ) : console && console.warn(
      `woff2 font has not been enabled for the font-family ${t}. It is adviced to upgrade in order to save bytes.`
    ), a.push(
      `url("${e.assetsPublicPath || ""}/fonts/${i}") format("woff")`
    ), r`
          @font-face {
            font-family: "${t}";
            src: ${a.join(",")};
            font-style: ${s};
            font-weight: ${d};
            font-display: fallback;
          }
        `;
  }
)}
`;
export {
  u as GlobalStyle,
  b as Media,
  y as gamma_be,
  $ as gamma_nl,
  x as karwei_nl,
  l as media
};
