import { forwardRef, type SVGProps } from "react";

export interface DocumentIconProps extends SVGProps<SVGSVGElement> {}

export const Document = forwardRef<SVGSVGElement, DocumentIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 4C2.25 2.48122 3.48122 1.25 5 1.25H8.5V2.75H5C4.30964 2.75 3.75 3.30964 3.75 4V21C3.75 21.6904 4.30964 22.25 5 22.25H19C19.6904 22.25 20.25 21.6904 20.25 21V4C20.25 3.30964 19.6904 2.75 19 2.75H15.5V1.25H19C20.5188 1.25 21.75 2.48122 21.75 4V21C21.75 22.5188 20.5188 23.75 19 23.75H5C3.48122 23.75 2.25 22.5188 2.25 21V4Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 9.25C6.75 8.83579 7.08579 8.5 7.5 8.5H16.5C16.9142 8.5 17.25 8.83579 17.25 9.25C17.25 9.66421 16.9142 10 16.5 10H7.5C7.08579 10 6.75 9.66421 6.75 9.25Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 13.25C6.75 12.8358 7.08579 12.5 7.5 12.5H16.5C16.9142 12.5 17.25 12.8358 17.25 13.25C17.25 13.6642 16.9142 14 16.5 14H7.5C7.08579 14 6.75 13.6642 6.75 13.25Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 17.25C6.75 16.8358 7.08579 16.5 7.5 16.5H12.5C12.9142 16.5 13.25 16.8358 13.25 17.25C13.25 17.6642 12.9142 18 12.5 18H7.5C7.08579 18 6.75 17.6642 6.75 17.25Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 1.5H10C9.44772 1.5 9 1.94772 9 2.5C9 3.05228 9.44772 3.5 10 3.5H14C14.5523 3.5 15 3.05228 15 2.5C15 1.94772 14.5523 1.5 14 1.5ZM10 0C8.61929 0 7.5 1.11929 7.5 2.5C7.5 3.88071 8.61929 5 10 5H14C15.3807 5 16.5 3.88071 16.5 2.5C16.5 1.11929 15.3807 0 14 0H10Z"
          fill="currentColor"
        />
      </svg>
    </svg>
  ),
);

(Document as React.FC).displayName = "Document";
