import { ComponentPropsWithRef } from "react";

import { Anchor, anchorStyle } from "@intergamma/anchor";
import { Button } from "@intergamma/button";

import { Link as ReactLink } from "react-router";

type BaseLinkProps = ComponentPropsWithRef<typeof ReactLink>;

interface ILinkProps extends BaseLinkProps {
  isButton?: true;
  isButtonSmall?: boolean;
  isButtonSecondary?: boolean;
  to: string;
}

const baseUrlMatch = document.location.href.match(/https?:\/\/([^/]+)/);
const baseUrl = baseUrlMatch?.[0] ?? "";

export function Link({
  isButton,
  isButtonSmall = false,
  isButtonSecondary = false,
  to,
  ...props
}: ILinkProps) {
  const hasProtocol =
    to.startsWith("https:") ||
    to.startsWith("http:") ||
    to.startsWith("tel:") ||
    to.startsWith("mailto:") ||
    to.startsWith("file:");

  const isInternal = !hasProtocol || to.startsWith(`${baseUrl}/my/`);

  if (isButton) {
    return (
      <Button
        as={isInternal ? ReactLink : "a"}
        $small={isButtonSmall}
        $secondary={isButtonSecondary}
        {...(isInternal ? { to } : { href: to })}
        {...props}
      />
    );
  }

  if (!isInternal) {
    return <Anchor href={to} {...props} />;
  }

  return <ReactLink className={anchorStyle} to={to} {...props} />;
}
