function a() {
  return "dataLayer" in window && window.dataLayer.some((e) => (e == null ? void 0 : e.event) === "gtm.load");
}
function i(e) {
  let t = 0;
  const o = window.setInterval(() => {
    if (t += 1, a()) {
      window.clearInterval(o), e();
      return;
    }
    t >= 40 && window.clearInterval(o);
  }, 300);
}
async function w() {
  return a() ? Promise.resolve() : new Promise((e) => {
    i(e);
  });
}
const d = [];
let r = !1;
if (typeof window < "u") {
  const e = () => {
    r = !0;
    for (const n of d)
      s(n);
  };
  document.readyState === "loading" ? window.addEventListener("DOMContentLoaded", e, {
    once: !0
  }) : e();
}
function s(e) {
  if (!r) {
    d.push(e);
    return;
  }
  window.dispatchEvent(
    new CustomEvent(
      e.type,
      "data" in e ? { detail: e.data } : void 0
    )
  );
}
export {
  s as dispatchAdobeEvent,
  i as setupPolling,
  w as waitForGALoad
};
