/*
  This should move to the design-system
  */

import styled, { keyframes, useTheme } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(-30deg);
  }

  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-30deg);
  }
`;

export const LoaderAnimation = styled.div`
  transform-origin: 5px 6px;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${rotate} 900ms infinite ease-in-out;
  }
`;

export const Loader = () => {
  const { brandColor } = useTheme();

  return (
    <div
      className="absolute z-10 flex size-full items-center justify-center rounded-xl bg-ignew-neutral-0"
      data-testid="loader"
    >
      <LoaderAnimation>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.94649 12.8309C2.10097 11.986 1.51744 10.9134 1.26333 9.73719C1.09172 8.9429 1.07508 8.12325 1.21035 7.3221C1.30174 6.78085 1.95564 6.62931 2.34377 7.01745L4.4912 9.16487C5.07699 9.75066 6.02673 9.75066 6.61252 9.16487L8.29232 7.48508C8.8781 6.89929 8.8781 5.94954 8.29232 5.36376L6.04091 3.11235C5.66109 2.73253 5.79615 2.09209 6.32136 1.97953C7.21741 1.7875 8.14418 1.78718 9.03904 1.98398C10.3004 2.26139 11.439 2.91736 12.3039 3.86507C13.1689 4.81277 13.7196 6.00753 13.883 7.2912C14.0223 8.38525 13.8339 9.35315 13.5425 10.2833C13.451 10.5753 13.5339 10.8958 13.7644 11.0972L21.0132 17.4291C21.3214 17.6893 21.5709 18.0118 21.7458 18.3764C21.9207 18.7409 22.0173 19.1395 22.0293 19.547C22.0414 19.9545 21.9688 20.362 21.816 20.7438C21.6633 21.1257 21.4337 21.4735 21.1418 21.7653C20.8506 22.0564 20.5037 22.2855 20.1229 22.4381C19.7421 22.5908 19.3356 22.6637 18.929 22.6523C18.5225 22.641 18.1246 22.5455 17.7605 22.372C17.3963 22.1985 17.0738 21.9506 16.8131 21.6441L10.4426 14.4158C10.241 14.1871 9.92171 14.1049 9.63174 14.1989C8.67545 14.5091 7.62072 14.7564 6.47368 14.5911C5.13232 14.3978 3.89705 13.7813 2.94649 12.8309Z"
            fill={brandColor}
          />
        </svg>
      </LoaderAnimation>
    </div>
  );
};
