import { media } from "@intergamma/theme";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";

import { useSessionData } from "../session-data/SessionDataContext";

import { NavigationBarcode } from "./components/NavigationBarcode";
import { LoggedInNavigation } from "./LoggedInNavigation";

export function MainLoggedInNavigation() {
  const customer = useSessionData();
  const { t } = useTranslation("urls");
  const { pathname } = useLocation();
  const isOnDashboard =
    pathname === t("/myaccount") || `${pathname}/` === t("/myaccount");

  if (!customer.isLoggedIn) {
    return null;
  }

  return (
    <StyledNav $isOnDashboard={isOnDashboard} title="main">
      <StyledList>
        {customer.loyaltyCards.length ? (
          <NavigationBarcode
            loyaltyCardNumber={customer.loyaltyCards[0].cardNumber}
          />
        ) : null}
        <LoggedInNavigation />
      </StyledList>
    </StyledNav>
  );
}

export const StyledNav = styled.nav<{ $isOnDashboard: boolean }>`
  padding: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: ${({ $isOnDashboard }) => !$isOnDashboard && "none"};
  margin-bottom: 1rem;

  ${media.md`
    display: flex;
    padding: 0.75rem 0;
    max-width: 17.5rem;
    margin-bottom: 0;
  `};
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
`;
