import type {
  OrderOverviewResponse,
  OrderOverview,
} from "../types/OrderOverview";

import { mapLocalDateResponse } from "~/services/mappers/mapLocalDateResponse";
import { mapMoneyResponse } from "~/services/mappers/mapMoneyResponse";

export function mapOrdersOverviewResponse(
  response: OrderOverviewResponse[],
): OrderOverview[] {
  return response.map((order) => mapOrderOverviewResponse(order));
}

function mapOrderOverviewResponse(
  orderResponse: OrderOverviewResponse,
): OrderOverview {
  return {
    ...orderResponse,
    creationDate: mapLocalDateResponse(orderResponse.creationDate),
    totalPrice: mapMoneyResponse(orderResponse.totalPrice),
  };
}
