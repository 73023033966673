import { forwardRef, type SVGProps } from "react";

export interface ReloadIconProps extends SVGProps<SVGSVGElement> {}

export const Reload = forwardRef<SVGSVGElement, ReloadIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M19.6233 7.93199C19.2589 8.12902 18.8038 7.99339 18.6068 7.62904C17.274 5.16454 14.6895 3.5 11.7251 3.5C7.97512 3.5 4.8314 6.16676 4.06562 9.73721L6.15693 8.12191C6.48407 7.86923 6.95477 7.93082 7.20827 8.25948C7.46177 8.58814 7.40207 9.05941 7.07493 9.31208L3.70847 11.9123C3.6411 11.9913 3.5576 12.0562 3.46307 12.1018L2.71678 12.6783L0.0906128 7.81225C-0.106518 7.44699 0.0286733 6.99194 0.392571 6.79586C0.756469 6.59978 1.21127 6.73693 1.4084 7.10219L2.61678 9.34119C3.55621 5.14283 7.27235 2 11.7251 2C15.2676 2 18.3447 3.99092 19.9262 6.91552C20.1233 7.27987 19.9876 7.73496 19.6233 7.93199Z" />
      <path d="M4.00364 15.9969C4.36973 15.8031 4.8236 15.9428 5.01739 16.3089C6.34002 18.8074 8.94312 20.5 11.9323 20.5C15.6823 20.5 18.826 17.8332 19.5918 14.2628L17.5004 15.8781C17.1733 16.1308 16.7026 16.0692 16.4491 15.7405C16.1956 15.4119 16.2553 14.9406 16.5825 14.6879L19.949 12.0876C20.0163 12.0087 20.0998 11.9438 20.1943 11.8982L20.9406 11.3217L23.5668 16.1877C23.7639 16.553 23.6287 17.0081 23.2648 17.2041C22.9009 17.4002 22.4461 17.2631 22.249 16.8978L21.0406 14.6589C20.1012 18.8572 16.385 22 11.9323 22C8.3601 22 5.2612 19.9756 3.69168 17.0106C3.49789 16.6446 3.63756 16.1907 4.00364 15.9969Z" />
    </svg>
  ),
);

(Reload as React.FC).displayName = "Reload";
