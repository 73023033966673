import { jsxs as w, Fragment as re, jsx as e } from "react/jsx-runtime";
import { createContext as Be, useContext as Ye, useMemo as se, useCallback as Y, useState as S, useEffect as k, useId as je, useRef as ue } from "react";
import { useTranslation as _, Trans as Ee } from "react-i18next";
import { useConfig as z } from "@intergamma/config";
import { useActiveVariant as He, useVariants as ze } from "@intergamma/experiments/hooks";
import { PrivacyMask as X } from "@intergamma/privacy-mask";
import { dispatchAdobeEvent as C } from "@intergamma/adobe-tracking";
import { calculatePasswordStrength as Ve, useStrengthText as Oe, TextField as me, PasswordField as Re, CheckboxField as _e, VerifyCodeField as Fe } from "@intergamma/ui/form";
import { Button as Z } from "@intergamma/ui/button";
import { Edit as we, Correct as Ie } from "@intergamma/icons";
import { ExclamationMark as ke, CheckMark as Ke } from "@intergamma/ui/icons";
import { Snackbar as ge } from "@intergamma/ui/snackbar";
import { cn as W } from "@intergamma/common/cn";
import { useSearchParams as Qe, useLocation as Je, Navigate as Xe, useNavigate as Ze } from "react-router";
import { useForm as ve, Controller as he } from "react-hook-form";
import { zodResolver as be } from "@hookform/resolvers/zod";
import { ArrowRight as et } from "react-feather";
import { datadogRum as ne } from "@datadog/browser-rum";
import { isAxiosError as ye } from "axios";
import { useMutation as fe, useQuery as tt } from "@tanstack/react-query";
import { c as rt, a as nt } from "./index-C2EW4nef.js";
import { l as pe } from "./datadog-logging-Ds9IeYxC.js";
import { z as V } from "zod";
import st from "@intergamma/mailcheck";
import { isSyntheticTest as ot, getMobileOperatingSystem as it, MobileOperatingSystem as at } from "@intergamma/common";
import { toast as Ce } from "react-toastify";
import { signIn as lt } from "aws-amplify/auth";
import { LoyaltyCard as ct } from "@intergamma/loyalty-card";
var A = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.SIGN_UP = 1] = "SIGN_UP", r[r.VERIFY = 2] = "VERIFY", r[r.FORGOT_PASSWORD = 3] = "FORGOT_PASSWORD", r))(A || {}), te = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.RESEND_VERIFICATION = 1] = "RESEND_VERIFICATION", r[r.RESEND_SUCCESS = 2] = "RESEND_SUCCESS", r))(te || {}), Ue = /* @__PURE__ */ ((r) => (r.CODE = "CODE", r.HASH = "HASH", r))(Ue || {});
const dt = {
  signUpRequestBody: {
    email: "",
    password: "",
    locale: "nl",
    optIn: !1,
    verificationMethod: "CODE",
    passwordless: !1
  },
  setSignUpRequestBody: () => {
  },
  setSignUpResponse: () => {
  },
  loginAfterSignUp: !1,
  setLoginAfterSignUp: () => {
  },
  setVerificationError: () => {
  },
  setFlow: () => {
  }
}, Te = Be(dt), ut = Te.Provider, le = () => Ye(Te);
function ce(r) {
  const n = rt(`${r}/api`, {
    withCredentials: !0
  });
  return {
    getAccountState(t) {
      return n.get(
        `/account/?email=${encodeURIComponent(t)}`
      );
    },
    resendVerification(t) {
      return n.post(
        "/signup/resendsignupverification",
        t
      );
    },
    signUp(t) {
      return n.post("/signup", t);
    },
    verify(t, c, l) {
      return n.post(`/verify/${t}?hash=${c}`, {
        hash: c,
        customerId: l
      });
    },
    passwordReset(t) {
      return n.post("/password-reset", t);
    },
    verifyPasswordReset(t) {
      return n.post("/verify/password-reset", t);
    }
  };
}
const Ae = (r) => {
  switch (r) {
    case "nl_BE":
    case "nl-BE":
      return "nl_BE";
    case "fr_BE":
    case "fr-BE":
      return "fr_BE";
    default:
      return "nl";
  }
};
class O extends Error {
  constructor(n) {
    super(n), this.name = "TranslatedError";
  }
}
function mt({ onSuccess: r }) {
  const { MYACCOUNT_URL: n } = z(), { t, i18n: c } = _(["register", "errors"]), l = se(
    () => ce(n),
    [n]
  ), d = Y(() => {
    ne.addAction("signup");
  }, []);
  function s({ password: o, email: u, optIn: a, passwordless: i }) {
    return l.signUp({
      email: u,
      password: o,
      locale: Ae(c.language),
      optIn: a,
      verificationMethod: "CODE",
      passwordless: i
    }).catch((p) => {
      var m, f, R;
      if (ye(p) && ((f = (m = p.response) == null ? void 0 : m.data) != null && f.status)) {
        const y = ((R = p.response) == null ? void 0 : R.data).status;
        if (y === "UserSignUpInvalidEmail")
          throw new O(t("register:invalidEmail"));
        if (y === "UserSignUpPasswordInvalid")
          throw new O(t("register:invalidSignupPassword"));
        if (y === "UserSignUpAlreadyExists")
          throw new O(t("register:userAlreadyExists"));
      }
      throw pe.error(
        i ? "Passwordless signup" : "Signup",
        {
          details: "Failed"
        },
        p
      ), new O(
        `${t("errors:somethingWentWrong")} ${t(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return fe({
    mutationFn: s,
    throwOnError: !1,
    onSuccess: (o, u) => {
      d(), C({
        type: "sign_up_interaction",
        data: {
          user_selected_element: "Sign_up verification",
          user_message_value: `Commercial opt-in: ${u.optIn}`,
          user_selected_value: `Verification method: ${u.passwordless ? "passwordless" : "email"}`
        }
      }), r(o, u);
    },
    onError: (o) => {
      C({
        type: "sign_up_error",
        data: {
          error: o == null ? void 0 : o.message
        }
      });
    }
  });
}
const qe = (r, n, t) => ({
  gamma: {
    nl: {
      loyaltyProgram: `${t}/voordeelpas`,
      requirementsLoyalty: `${t}/klantenservice/algemene-voorwaarden/voordeelpas`
    },
    "nl-BE": {
      loyaltyProgram: `${t}/nl/klanteninformatie/gammapluskaart`,
      requirementsLoyalty: `${t}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`
    },
    "fr-BE": {
      loyaltyProgram: `${t}/fr/infos-consommateur/carte-gammaplus`,
      requirementsLoyalty: `${t}/fr/infos-consommateur/carte-gammaplus`
    }
  },
  karwei: {
    nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    },
    karwei_nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    }
  }
})[r][n], gt = "account";
function Me() {
  const { t: r } = _(["register", "errors"]), { MYACCOUNT_URL: n } = z(), t = se(
    () => ce(n),
    [n]
  ), [c, l] = S(void 0), d = Y(async () => c ? t.getAccountState(c).catch((i) => {
    throw C({
      type: "login_email_check_error",
      data: {
        error: (i == null ? void 0 : i.message) || ""
      }
    }), new O(
      `${r("errors:somethingWentWrong")} ${r(
        "errors:ContactCustomerSupport"
      )}`
    );
  }) : null, [c, t, r]), { data: s, isFetching: o, refetch: u, error: a } = tt({
    queryKey: [gt, c],
    queryFn: d,
    throwOnError: !1
  });
  return {
    accountState: s,
    isFetching: o,
    error: a,
    refetch: u,
    getAccountState: (i) => {
      l(i);
    }
  };
}
function ft({
  isPasswordless: r
}) {
  const { t: n } = _(["register"]);
  return V.object({
    email: V.string().min(1, n("register:emailRequired")).email(n("register:emailInvalid")),
    ...r ? {} : {
      password: V.string().min(8, n("register:minPasswordLength")).max(50, n("register:maxPasswordLength"))
    },
    requirements: V.literal(!0, {
      errorMap: () => ({ message: n("register:requirementsError") })
    }),
    optIn: V.boolean()
  });
}
function pt({ setShowLoyaltyBenefits: r }) {
  var I, ae;
  const { t: n, i18n: t } = _([
    "common",
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), c = He("abtest_drl_2343-drl_1"), { AControl: l, BAdvantages: d } = ze("abtest_drl_3287-drl_2"), [s] = Qe(), o = s.get("method") === "passwordless", { signUpRequestBody: u, setSignUpRequestBody: a, setFlow: i } = le(), p = ft({ isPasswordless: o }), {
    mutate: m,
    error: f,
    isPending: R
  } = mt({
    onSuccess: (v, g) => {
      a((L) => ({ ...L, ...g })), i({
        flow: A.VERIFY,
        verificationData: {
          customerId: v.customerId,
          control: v.control,
          email: g.email,
          hashedId: v.hashedId
        }
      }), r == null || r(!0), o && pe.info("Passwordless signup", {
        details: "Successful"
      });
    }
  }), [E, y] = S(null), { brand: N, SHOPFRONT_URL: T } = z(), { getAccountState: F, accountState: q, isFetching: D } = Me(), [K, G] = S(null), x = qe(N, t.language, T), h = (x == null ? void 0 : x.requirementsLoyalty) ?? "/", B = (x == null ? void 0 : x.loyaltyProgram) ?? "/", {
    email: j,
    password: b,
    optIn: oe
  } = u, U = ve({
    defaultValues: {
      email: j,
      password: b,
      requirements: !1,
      optIn: oe
    },
    resolver: be(p)
  }), H = U.watch("password"), M = se(
    () => Ve(H),
    [H]
  ), ie = Oe(M);
  k(() => {
    f != null && f.message && G(f.message);
  }, [f]);
  const de = U.handleSubmit(
    (v) => {
      F(v.email), y({
        ...v,
        locale: "nl",
        // FIXME: get locale from somewhere?
        verificationMethod: "CODE",
        password: o ? window.crypto.randomUUID() : v.password,
        passwordless: o
      });
    },
    (v) => {
      for (const { message: g } of Object.values(v))
        C({
          type: "sign_up_error",
          data: {
            error: g
          }
        });
    }
  );
  return k(() => {
    q && !D && (!q.hasAccount && E ? m({
      email: E.email,
      password: E.password,
      optIn: E.optIn,
      passwordless: E.passwordless
    }) : G(n("register:userAlreadyExists")));
  }, [
    q,
    D,
    n,
    E,
    m,
    a
  ]), k(() => {
    C({
      type: "sign_up_interaction",
      data: {
        user_selected_element: "Sign-up pop-up form"
      }
    });
  }, []), /* @__PURE__ */ w(re, { children: [
    /* @__PURE__ */ e("h1", { className: "mb-4 text-200 font-bold", children: n("login-form:buttonLabelNewAccount") }),
    /* @__PURE__ */ w(
      "form",
      {
        noValidate: !0,
        onSubmit: (v) => (v.stopPropagation(), de(v)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
            me,
            {
              type: "email",
              label: n("common:EmailAddress"),
              error: (I = U.formState.errors.email) == null ? void 0 : I.message,
              autoCapitalize: "off",
              autoComplete: "email",
              disabled: !0,
              icon: /* @__PURE__ */ e(
                "button",
                {
                  className: W(
                    "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                    "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                  ),
                  onClick: () => {
                    C({
                      type: "login_interaction",
                      data: {
                        user_selected_element: "Sign-up pop-up change e-mail link click"
                      }
                    }), i({ flow: A.LOGIN });
                  },
                  type: "button",
                  children: /* @__PURE__ */ e(we, {})
                }
              ),
              ...U.register("email")
            }
          ) }) }) }),
          !o && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e(
            Re,
            {
              autoComplete: "new-password",
              autoFocus: !0,
              label: n("login-form:Password"),
              error: (ae = U.formState.errors.password) == null ? void 0 : ae.message,
              passwordStrength: M,
              description: ie,
              ...U.register("password")
            }
          ) }) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            he,
            {
              control: U.control,
              name: "requirements",
              defaultValue: !1,
              render: ({ field: v, fieldState: g }) => {
                var L;
                return /* @__PURE__ */ e(
                  _e,
                  {
                    ref: v.ref,
                    name: v.name,
                    checked: v.value,
                    "data-testid": "requirements-checkbox",
                    onCheckedChange: (Q) => {
                      Q !== "indeterminate" && v.onChange(Q);
                    },
                    error: (L = g.error) == null ? void 0 : L.message,
                    label: /* @__PURE__ */ e(
                      Ee,
                      {
                        ns: "register",
                        i18nKey: "requirements",
                        defaultValue: "Met het aanmaken van een account, ga ik akkoord met de <terms-link>voorwaarden</terms-link> en profiteer ik van alle gratis voordelen van de <loyalty-link>{{loyaltyCardName}}</loyalty-link>",
                        components: {
                          "terms-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: h,
                              rel: "noreferrer",
                              className: "underline",
                              children: "voorwaarden"
                            }
                          ),
                          "loyalty-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: B,
                              rel: "noreferrer",
                              className: "underline",
                              children: "pas"
                            }
                          )
                        }
                      }
                    )
                  }
                );
              }
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            he,
            {
              control: U.control,
              name: "optIn",
              defaultValue: !1,
              render: ({ field: v, fieldState: g }) => {
                var L;
                return /* @__PURE__ */ e(
                  _e,
                  {
                    ref: v.ref,
                    name: v.name,
                    checked: v.value,
                    onCheckedChange: (Q) => {
                      Q !== "indeterminate" && v.onChange(Q);
                    },
                    error: (L = g.error) == null ? void 0 : L.message,
                    label: n("register:optinNewsletter", {
                      context: c === "B-new" ? "FiveHundred" : void 0
                    })
                  }
                );
              }
            }
          ) }),
          K && /* @__PURE__ */ e(ge, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: K }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            Z,
            {
              loading: D || R,
              className: "w-full text-center",
              type: "submit",
              children: n("register:description")
            }
          ) }),
          /* @__PURE__ */ e(l, { children: /* @__PURE__ */ w("div", { children: [
            /* @__PURE__ */ e("b", { children: n("register:alreadyHaveAccount") }),
            /* @__PURE__ */ e("br", {}),
            /* @__PURE__ */ e(
              "button",
              {
                className: W(
                  "underline gamma:rounded-md",
                  "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                ),
                onClick: () => i({ flow: A.LOGIN }),
                children: n("register:loginUrl")
              }
            )
          ] }) }),
          /* @__PURE__ */ e(d, { children: /* @__PURE__ */ w("div", { className: "flex flex-col gap-2", children: [
            /* @__PURE__ */ e("h2", { className: "mt-2 text-200 font-bold", children: n("login-form:accountCreationAdvantages_Title") }),
            /* @__PURE__ */ e("ul", { className: "flex flex-col gap-2 list-none", children: n("login-form:accountCreationAdvantages", {
              returnObjects: !0,
              defaultValue: [
                "Meteen korting met je GAMMA Voordeelpas",
                "Ontvang 250 punten t.w.v. € 2.50",
                "Al je aankopen en retouren op één plek"
              ]
            }).map((v) => /* @__PURE__ */ w("li", { className: "flex gap-2 items-baseline", children: [
              /* @__PURE__ */ e(et, { className: "size-4 shrink-0 text-ignew-functional-primary-300" }),
              v
            ] }, v)) })
          ] }) })
        ]
      }
    )
  ] });
}
function $e() {
  const { MYACCOUNT_URL: r } = z(), { i18n: n, t } = _(["register"]), c = se(
    () => ce(r),
    [r]
  ), l = Y((s) => {
    ne.addAction("resend_verification_failed", {
      errorDetails: s == null ? void 0 : s.message
    });
  }, []);
  function d({
    email: s,
    sms: o,
    phoneNumber: u
  }) {
    return c.resendVerification({
      email: s,
      locale: Ae(n.language),
      sms: o,
      phoneNumber: u,
      verificationMethod: "CODE"
    }).catch((a) => {
      var i;
      if (C({
        type: "resend_verification_error",
        data: {
          user_message_type: (a == null ? void 0 : a.message) || "",
          verificationMethod: o ? "sms" : "email"
        }
      }), ye(a) && ((i = a.response) == null ? void 0 : i.data).status === "ResendInvalidEmail")
        throw new O(
          t(o ? "register:phoneNumberAlreadyExists" : "register:userAlreadyExists")
        );
    }).finally(() => {
      C({
        type: "resend_verification",
        data: {
          verificationMethod: o ? "sms" : "email"
        }
      });
    });
  }
  return fe({
    mutationFn: d,
    throwOnError: !1,
    onError: (s) => {
      l(s);
    }
  });
}
function ht({
  email: r,
  goToStep: n,
  goToFlow: t
}) {
  const { t: c } = _("login-form"), { mutate: l, isPending: d, isSuccess: s, data: o } = $e();
  k(() => {
    s && o && t({
      flow: A.VERIFY,
      verificationData: {
        customerId: o.customerId,
        control: o.control,
        email: o.email,
        hashedId: o.hashedId
      }
    });
  }, [s, n, t, o]);
  const u = () => {
    C({ type: "sign_up_change_email" }), n(te.LOGIN);
  };
  return /* @__PURE__ */ w("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ w("div", { className: "grid gap-4", children: [
      /* @__PURE__ */ e("p", { children: c("bodyNoVerfication") }),
      /* @__PURE__ */ e("p", { children: c("bodyNoVerification2") }),
      /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e("strong", { children: r }) }) })
    ] }),
    /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      Z,
      {
        className: "w-full",
        loading: d,
        onClick: () => l({ email: r }),
        children: c("buttonLabelResendEmail")
      }
    ) }),
    /* @__PURE__ */ e("div", { className: "text-center", children: /* @__PURE__ */ e(
      "button",
      {
        className: W(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: u,
        children: c("buttonLabelOtherEmail")
      }
    ) })
  ] });
}
function wt({ email: r }) {
  const { t: n } = _("login-form");
  return /* @__PURE__ */ w("div", { className: "grid gap-4", children: [
    /* @__PURE__ */ e("p", { children: n("bodyConfirmEmailSent") }),
    /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e("strong", { children: r }) }) }),
    /* @__PURE__ */ e("p", { children: n("bodyEmailSteps") })
  ] });
}
function vt({ onSubmit: r, isLoading: n }) {
  const { t } = _("login-form"), { brand: c, country: l } = z();
  let d = [];
  c === "gamma" ? d = l === "be" || l === "BE" ? ["2613", "2614", "2617", "2618", "2629"] : ["2611", "2615", "2616"] : c === "karwei" && (d = ["2612", "2619"]);
  const o = V.object({
    loyaltyCardNumber: V.string().min(1, t("cardNumberRequired")).length(13, t("LoyaltyCardNumberInvalid")).refine((i) => d.some((p) => i.startsWith(p)), {
      message: t("LoyaltyCardNumberInvalid")
    }).refine(
      (i) => i.split("").reduce(
        (m, f, R) => R % 2 === 0 ? m + Number(f) : m + 3 * Number(f),
        0
      ) % 10 === 0,
      {
        message: t("LoyaltyCardNumberInvalid")
      }
    )
  }), u = ve({
    mode: "onBlur",
    defaultValues: {
      loyaltyCardNumber: ""
    },
    resolver: be(o)
  }), a = u.handleSubmit((i) => {
    r(i.loyaltyCardNumber);
  });
  return /* @__PURE__ */ w(re, { children: [
    /* @__PURE__ */ w("div", { className: "relative flex p-4 items-center", children: [
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" }),
      /* @__PURE__ */ e("span", { className: "flex-shrink mx-4 text-ignew-neutral-500 uppercase", children: t("or") }),
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" })
    ] }),
    /* @__PURE__ */ w(
      "form",
      {
        noValidate: !0,
        onSubmit: (i) => (i.stopPropagation(), a(i)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e(
            he,
            {
              rules: {
                required: t("cardNumberRequired"),
                pattern: {
                  value: /^\d+$/,
                  message: t("LoyaltyCardNumberInvalid")
                }
              },
              render: ({ field: { onChange: i, ...p } }) => {
                var m;
                return /* @__PURE__ */ e(
                  me,
                  {
                    onChange: (f) => {
                      (f.target.value === "" || /^[0-9\b]+$/.test(f.target.value)) && i(f.target.value);
                    },
                    type: "text",
                    label: t("enterLoyaltyCardNumber"),
                    maxLength: 13,
                    inputMode: "numeric",
                    error: (m = u.formState.errors.loyaltyCardNumber) == null ? void 0 : m.message,
                    ...p
                  }
                );
              },
              control: u.control,
              name: "loyaltyCardNumber",
              defaultValue: ""
            }
          ),
          /* @__PURE__ */ e(Z, { type: "submit", variant: "secondary", loading: n, children: t("RegisterLoyaltyCardNumber") })
        ]
      }
    )
  ] });
}
function bt({
  showTermsAndConditions: r,
  showPasswordField: n
}) {
  const { t } = _(["ig-header", "login"]);
  return V.object({
    email: V.string().min(1, t("ig-header:loginEmailRequired")).email(t("ig-header:loginEmailInvalid")),
    password: n ? V.string().min(1, t("ig-header:loginRequired")) : V.string(),
    requirements: r ? V.literal(!0, {
      errorMap: () => ({ message: t("login:requirementsError") })
    }) : V.any()
  });
}
function yt({
  isLoading: r,
  onLogin: n,
  errorMessage: t,
  setShowLoyaltyBenefits: c,
  loyaltyCardLogin: l,
  initLocation: d,
  loginData: s
}) {
  var xe;
  const { t: o, i18n: u } = _([
    "ig-header",
    "login",
    "urls",
    "login-form",
    "register",
    "common"
  ]), a = Je(), { signUpRequestBody: i, setSignUpRequestBody: p, setFlow: m, loginAfterSignUp: f } = le(), { brand: R, SHOPFRONT_URL: E } = z(), y = je(), [N, T] = S(
    !!(s != null && s.email)
  ), [F, q] = S(void 0), [D, K] = S(!1), [G, x] = S(te.LOGIN), h = qe(R, u.language, E), B = (h == null ? void 0 : h.requirementsLoyalty) ?? "/", j = (h == null ? void 0 : h.loyaltyProgram) ?? "/", {
    accountState: b,
    getAccountState: oe,
    refetch: U,
    error: H,
    isFetching: M
  } = Me(), ie = f ? i.email : ((xe = a.state) == null ? void 0 : xe.email) || (s == null ? void 0 : s.email), de = bt({
    showTermsAndConditions: D,
    showPasswordField: N
  }), I = ve({
    defaultValues: {
      email: ie || "",
      password: "",
      requirements: !1
    },
    resolver: be(de)
  }), ae = () => {
    F && (I.setValue("email", F.full), q(void 0), C({ type: "email_suggestion_click" }));
  }, v = I.watch("email"), g = async (P) => {
    const ee = await I.trigger("email");
    P && ee && (P === i.email && b ? U() : oe(P), p((J) => ({
      ...J,
      email: P,
      password: ""
    })), C({ type: "login_email_check" }));
  }, L = Y(
    (P) => {
      P != null && P.length && (b != null && b.customerId) && (C({
        type: "login_interaction",
        data: {
          user_selected_element: "Login by code"
        }
      }), m({
        flow: A.VERIFY,
        verificationData: {
          email: P,
          customerId: b.customerId,
          loginViaCode: !0,
          hashedId: b.hashedId || ""
        }
      }));
    },
    [b, m]
  );
  k(() => {
    s && !b && !M && oe(s.email);
  }, [s, b, M, oe]), k(() => {
    if (b && !M) {
      const { hasAccount: P, hasLoyaltyCard: ee, verified: J } = b;
      P ? J ? s != null && s.forcePasswordFlow || ot ? (T(!0), I.setFocus("password")) : L(i.email) : x(te.RESEND_VERIFICATION) : m({ flow: A.SIGN_UP }), P && !ee && (K(!0), typeof c < "u" && c(!0));
    }
  }, [
    b,
    M,
    c,
    y,
    m,
    i.email
  ]), k(() => {
    f && typeof c < "u" && c(!0);
  }, [f, c]), k(() => {
    G === te.LOGIN && C({
      type: "login_interaction",
      data: {
        user_selected_element: N ? "Login pop-up" : "Login / Signup email check pop-up",
        ...!N && { user_selected_value: d }
      }
    });
  }, [G, N, d]);
  const Q = I.handleSubmit((P) => {
    const { email: ee, password: J } = P;
    b != null && b.customerId && J && n({
      body: {
        customerId: b.customerId,
        email: ee,
        password: J,
        hashedId: b.hashedId || ""
      }
    });
  });
  return /* @__PURE__ */ e("div", { "data-testid": "login-form", children: ((P) => {
    var ee, J;
    switch (P) {
      case te.LOGIN:
        return /* @__PURE__ */ w(re, { children: [
          /* @__PURE__ */ w(
            "form",
            {
              noValidate: !0,
              onSubmit: ($) => {
                $.stopPropagation(), Q($);
              },
              className: "flex flex-col gap-4",
              children: [
                /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: o(N ? "login-form:loginTitle" : "login-form:titleLogInOrSignUp") }),
                /* @__PURE__ */ e("p", { children: N ? /* @__PURE__ */ w(re, { children: [
                  o("login-form:bodyWelcomeBack"),
                  /* @__PURE__ */ e("br", {}),
                  o("login-form:bodyPasswordRequest")
                ] }) : o("login-form:bodyUnsureAccount") }),
                /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
                  me,
                  {
                    type: "email",
                    label: o("common:EmailAddress"),
                    error: (ee = I.formState.errors.email) == null ? void 0 : ee.message,
                    autoCapitalize: "off",
                    autoComplete: "email",
                    disabled: N,
                    "data-testid": "login-email-input",
                    description: F ? /* @__PURE__ */ w("span", { role: "alert", children: [
                      o("common:DidYouMean"),
                      " ",
                      /* @__PURE__ */ w(
                        "button",
                        {
                          className: W(
                            "underline",
                            "gamma:rounded-md outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                          ),
                          onClick: ae,
                          children: [
                            "@",
                            F.domain
                          ]
                        }
                      ),
                      "?"
                    ] }) : void 0,
                    icon: N && /* @__PURE__ */ e(
                      "button",
                      {
                        className: W(
                          "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                        ),
                        "data-testid": "login-change-email",
                        onClick: ($) => {
                          $.preventDefault(), C({
                            type: "login_interaction",
                            data: {
                              user_selected_element: "Login pop-up change e-mail link click"
                            }
                          }), I.setValue("email", ""), T(!1);
                        },
                        type: "button",
                        children: /* @__PURE__ */ e(we, {})
                      }
                    ),
                    ...I.register("email", {
                      onChange($) {
                        st(
                          $.target.value,
                          q
                        );
                      }
                    })
                  }
                ) }) }) }),
                /* @__PURE__ */ e(
                  "div",
                  {
                    className: N ? "" : "h-0 overflow-hidden absolute",
                    children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e(
                      Re,
                      {
                        ...!N && {
                          tabIndex: -1,
                          "aria-hidden": "true"
                        },
                        autoComplete: N ? "current-password" : "off",
                        label: o("ig-header:loginPasswordLabel"),
                        error: ((J = I.formState.errors.password) == null ? void 0 : J.message) || t,
                        ...I.register("password")
                      }
                    ) })
                  }
                ),
                D && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
                  he,
                  {
                    control: I.control,
                    name: "requirements",
                    defaultValue: !1,
                    render: ({ field: $, fieldState: We }) => {
                      var Le;
                      return /* @__PURE__ */ e(
                        _e,
                        {
                          ref: $.ref,
                          name: $.name,
                          checked: $.value,
                          onCheckedChange: (Pe) => {
                            Pe !== "indeterminate" && $.onChange(Pe);
                          },
                          error: (Le = We.error) == null ? void 0 : Le.message,
                          label: /* @__PURE__ */ w(Ee, { ns: "login", i18nKey: "requirements", children: [
                            "Met het aanmaken van een account, ga ik akkoord met de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: B,
                                rel: "noreferrer",
                                children: "voorwaarden"
                              }
                            ),
                            "en profiteer ik van alle gratis voordelen van de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: j,
                                rel: "noreferrer",
                                children: "pas"
                              }
                            )
                          ] })
                        }
                      );
                    }
                  }
                ) }),
                (H == null ? void 0 : H.message) && /* @__PURE__ */ e(ge, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: H.message }),
                /* @__PURE__ */ e("div", { children: N ? /* @__PURE__ */ e(
                  Z,
                  {
                    loading: r,
                    className: "w-full text-center",
                    "data-testid": "login-submit",
                    type: "submit",
                    children: o("ig-header:Login")
                  }
                ) : /* @__PURE__ */ e(
                  Z,
                  {
                    loading: M,
                    className: "w-full text-center",
                    onClick: () => g(v),
                    "data-testid": "login-next",
                    children: o("login-form:buttonlabel")
                  }
                ) }),
                N && b && /* @__PURE__ */ w("div", { className: "text-center", children: [
                  /* @__PURE__ */ e("div", { className: "mb-4", children: /* @__PURE__ */ e(
                    "button",
                    {
                      className: W(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: ($) => {
                        $.preventDefault(), C({
                          type: "login_interaction",
                          data: {
                            user_selected_element: "Login pop-up password reset link click"
                          }
                        }), m({
                          flow: A.FORGOT_PASSWORD,
                          forgotPasswordData: {
                            email: I.getValues().email,
                            customerId: b.customerId,
                            hashedId: b.hashedId || ""
                          }
                        });
                      },
                      children: o("ig-header:loginForgotPassword")
                    }
                  ) }),
                  /* @__PURE__ */ e(
                    "button",
                    {
                      className: W(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: () => {
                        L(I.getValues().email);
                      },
                      children: o("login-form:loginWithCodeLink")
                    }
                  )
                ] })
              ]
            }
          ),
          typeof l < "u" && /* @__PURE__ */ e(
            vt,
            {
              onSubmit: l,
              isLoading: r
            }
          )
        ] });
      case te.RESEND_VERIFICATION:
        return /* @__PURE__ */ w(re, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: o("login-form:loginTitle") }),
          /* @__PURE__ */ e(
            ht,
            {
              email: i.email,
              goToStep: x,
              goToFlow: m
            }
          )
        ] });
      case te.RESEND_SUCCESS:
        return /* @__PURE__ */ w(re, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: o("register:titleIsThatYou") }),
          /* @__PURE__ */ e(wt, { email: i.email })
        ] });
      default:
        return null;
    }
  })(G) });
}
function Ct() {
  const { MYACCOUNT_URL: r } = z(), { setFlow: n, setSignUpResponse: t, setVerificationError: c } = le(), { t: l } = _(["register", "errors", "login"]), d = Y(() => {
    ne.addAction("verified_code");
  }, []), s = Y((a) => {
    ne.addAction("verified_code_failed", {
      errorDetails: a == null ? void 0 : a.message
    });
  }, []), o = se(
    () => ce(r),
    [r]
  );
  function u({ verificationCode: a, customerId: i }) {
    return o.verify("signup", `${i}_${a}`, i).catch((p) => {
      var m;
      if (ye(p)) {
        const f = (m = p.response) == null ? void 0 : m.data;
        if (f.status === "VerifySignUpInvalidToken")
          if (f.httpStatus === 410)
            t(void 0), c(l("register:tooManyWrongAttempts")), n({ flow: A.SIGN_UP });
          else
            throw new O(l("register:errorNotValidCode"));
      }
      throw pe.error("Verify code form failed", {}, p), (p == null ? void 0 : p.response.status) === 429 ? new O(l("errors:tooManyVerificationAttempts")) : new O(
        `${l("errors:somethingWentWrong")} ${l(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return fe({
    mutationFn: u,
    throwOnError: !1,
    onSuccess: () => {
      C({
        type: "verify_email"
      }), d();
    },
    onError: (a) => {
      C({
        type: "verify_email_error",
        data: {
          error: a == null ? void 0 : a.message
        }
      }), s(a);
    }
  });
}
const De = (r) => /^\d+$/.test(r);
function St() {
  const [r, n] = S(!1), t = Y(async (c) => {
    n(!0);
    try {
      return await lt({
        username: c,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
    } catch {
      return null;
    } finally {
      n(!1);
    }
  }, []);
  return {
    isPending: r,
    requestVerificationCode: t
  };
}
function Nt({
  navigateToLogin: r = !1,
  email: n,
  customerId: t,
  hashedId: c
}) {
  const { setFlow: l, setLoginAfterSignUp: d } = le(), { t: s } = _(["verification", "common", "urls"]);
  return k(() => {
    Ce.success(s("verification:signupVerificationSuccessDescription"), {
      icon: Ie
    }), l({ flow: A.LOGIN }), d(!0), C({
      type: "sign_up_activation",
      data: { kdbid: t, kdbid_hashed: c }
    });
  }, [s, l, d, t, c]), r ? /* @__PURE__ */ e(
    Xe,
    {
      to: { pathname: s("urls:/login"), search: "type=new" },
      state: { email: n },
      replace: !0
    }
  ) : null;
}
const Se = 4;
function _t({
  verificationData: r,
  navigateToLogin: n = !1,
  onLogin: t,
  confirmSignInError: c
}) {
  const { customerId: l, hashedId: d, email: s, control: o, loginViaCode: u } = r, { t: a } = _(["register", "common", "login-form"]), [i, p] = S(""), [m, f] = S(null), [R, E] = S(!1), y = ue(null), N = ue(null), [T, F] = S(!1), { setFlow: q, signUpRequestBody: D, setVerificationError: K } = le(), {
    isPending: G,
    requestVerificationCode: x
  } = St(), h = ue(t), {
    mutate: B,
    error: j,
    data: b
  } = $e(), {
    mutate: oe,
    isSuccess: U,
    isError: H,
    error: M
  } = Ct();
  k(() => {
    var g;
    (g = document.getElementById("code")) == null || g.focus();
  }, [G]), k(() => {
    u && x(l);
  }, [u, l, x]), k(() => {
    H && F(!1);
  }, [H]), k(() => {
    var g;
    U && h.current && ((g = D.password) != null && g.length) ? (C({
      type: "sign_up_activation",
      data: { kdbid: l, kdbid_hashed: d }
    }), h.current({
      body: {
        email: s,
        customerId: l,
        password: D.password,
        hashedId: d
      },
      disableAdobeEvent: !0
    })) : F(!1);
  }, [U, h, D, l, s, d]);
  const ie = (g) => {
    if (g == null || g.stopPropagation(), g == null || g.preventDefault(), K(void 0), y.current && clearTimeout(y.current), !(i.length === Se)) {
      f(a("register:errorNotValidCode"));
      return;
    }
    l && !u && (f(null), F(!0), oe({ verificationCode: i, customerId: l })), u && h.current && (f(null), F(!0), h.current({
      body: {
        email: s,
        customerId: l,
        verificationCode: i,
        hashedId: d
      }
    }));
  };
  k(() => {
    var g;
    return i.length === Se && De(i) ? ((g = N.current) == null || g.blur(), y.current = setTimeout(() => {
      ie();
    }, 1e3)) : y.current && clearTimeout(y.current), () => {
      y.current && clearTimeout(y.current);
    };
  }, [i]), k(() => {
    const g = (M == null ? void 0 : M.message) || (j == null ? void 0 : j.message);
    g && (f(g), setTimeout(() => {
      var L;
      (L = document.getElementById("code")) == null || L.focus();
    }, 1));
  }, [j, M]);
  const de = (g) => {
    const { value: L } = g.target, Q = L.replace(/\D/g, "");
    p(Q);
  }, I = (g) => {
    g.preventDefault(), p(""), f(null), r.loginViaCode ? x(l) : B({
      email: s
    }), Ce.success(a("register:successMessageResendCode"), { icon: Ie }), E(!0), setTimeout(() => {
      E(!1);
    }, 3e4);
  }, ae = (g) => {
    C({
      type: "login_interaction",
      data: {
        user_selected_element: "Login by password"
      }
    }), q({
      flow: A.LOGIN,
      loginData: {
        email: g,
        // If a user has no password it would not show the password input.
        // This forces the input to show up.
        forcePasswordFlow: !0
      }
    });
  };
  if (k(() => {
    var g;
    c && (F(!1), f(c), (g = N.current) == null || g.focus());
  }, [c, m, a, q]), U && !T)
    return /* @__PURE__ */ e(
      Nt,
      {
        navigateToLogin: n,
        email: s,
        hashedId: d,
        customerId: l
      }
    );
  const v = (b == null ? void 0 : b.control) || o;
  return /* @__PURE__ */ w("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: a(u ? "login-form:loginCodeSentTitle" : "register:titleIsThatYou") }),
    /* @__PURE__ */ e("div", { children: a(u ? "login-form:loginCodeSentDescription" : "register:bodyCodeSentTo") }),
    /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e(
      me,
      {
        label: "",
        value: s,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: W(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => {
              q({ flow: A.LOGIN }), C({ type: "sign_up_change_email" });
            },
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(we, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ e("p", { children: a(u ? "login-form:codeValidity" : "register:bodyFillCode") }),
    /* @__PURE__ */ w(
      "form",
      {
        className: "flex flex-col gap-4",
        noValidate: !0,
        onSubmit: ie,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Fe,
            {
              label: a("login-form:verifyNewPasswordCodeLabel"),
              value: i,
              "data-testid": "code",
              onChange: de,
              autoFocus: !0,
              disabled: G,
              maxLength: Se,
              controlCharacter: v,
              description: a("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${v}`
              }
            }
          ),
          m && /* @__PURE__ */ e(ge, { variant: "error", children: m }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            Z,
            {
              type: "submit",
              className: "w-full",
              loading: T || G,
              children: a(T ? "register:isVerifying" : "register:confirm")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: W(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: R,
              onClick: (g) => I(g),
              children: a("register:buttonLabelResendCode")
            }
          ) }),
          u && /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: W(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              onClick: () => {
                ae(s);
              },
              children: a("login-form:loginPasswordLink")
            }
          ) })
        ]
      }
    )
  ] });
}
function Ge() {
  const { MYACCOUNT_URL: r } = z(), { t: n } = _(["register", "errors"]), t = se(
    () => ce(r),
    [r]
  ), c = Y(() => {
    ne.addAction("request_new_password");
  }, []), l = Y(
    (s) => {
      ne.addAction("request_new_password_failed", {
        reason: s
      });
    },
    []
  );
  function d(s) {
    return t.passwordReset({ email: s, verificationMethod: Ue.CODE }).catch((o) => {
      throw pe.error("Reset password request failed", {}, o), (o == null ? void 0 : o.response.status) === 429 ? new O(n("errors:tooManyVerificationAttempts")) : new O(
        `${n("errors:somethingWentWrong")} ${n(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return fe({
    mutationFn: d,
    onSuccess: () => {
      c();
    },
    onError: (s) => {
      l((s == null ? void 0 : s.message) || "");
    }
  });
}
function Et({ email: r, onSuccess: n }) {
  var m;
  const { t } = _([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), c = V.object({
    password: V.string().min(8, t("register:minPasswordLength")).max(50, t("register:maxPasswordLength"))
  }), l = ve({
    defaultValues: {
      password: ""
    },
    resolver: be(c)
  }), d = l.watch("password"), s = se(
    () => Ve(d),
    [d]
  ), o = Oe(s), { mutate: u, isPending: a, error: i } = Ge(), p = l.handleSubmit((f) => {
    u(r, {
      onSuccess({ control: R }) {
        n(f.password, R);
      }
    });
  });
  return /* @__PURE__ */ w(
    "form",
    {
      noValidate: !0,
      onSubmit: (f) => {
        f.stopPropagation(), p(f);
      },
      className: "flex flex-col gap-4",
      children: [
        /* @__PURE__ */ w("div", { className: "grid gap-4", children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: t("login-form:chooseNewPasswordTitle") }),
          /* @__PURE__ */ e("p", { children: t("login-form:chooseNewPasswordBody") })
        ] }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e(
          Re,
          {
            autoFocus: !0,
            autoComplete: "new-password",
            label: t("login-form:Password"),
            error: (m = l.formState.errors.password) == null ? void 0 : m.message,
            passwordStrength: s,
            description: o,
            ...l.register("password")
          }
        ) }) }),
        (i == null ? void 0 : i.message) && /* @__PURE__ */ e(ge, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: i.message }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
          Z,
          {
            disabled: a,
            loading: a,
            className: "w-full text-center",
            children: t("login-form:buttonlabel")
          }
        ) })
      ]
    }
  );
}
function Rt() {
  const { MYACCOUNT_URL: r } = z(), { t: n } = _(["register", "errors"]), t = se(
    () => ce(r),
    [r]
  ), c = Y(() => {
    ne.addAction("verified_new_password");
  }, []), l = Y((s) => {
    ne.addAction("verifiy_new_password_failed", {
      reason: s
    });
  }, []);
  function d(s) {
    return t.verifyPasswordReset(s).catch((o) => {
      var u;
      if (ye(o)) {
        const a = (u = o.response) == null ? void 0 : u.data;
        if (a.status === "VerifyPasswordResetInvalidToken")
          throw a.httpStatus === 410 ? new O(n("register:tooManyWrongAttempts")) : new O(n("register:errorNotValidCode"));
      }
      throw pe.error("Verify code form failed", {}, o), (o == null ? void 0 : o.response.status) === 429 ? new O(n("errors:tooManyVerificationAttempts")) : new O(
        `${n("errors:somethingWentWrong")} ${n(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return fe({
    mutationFn: d,
    throwOnError: !1,
    onSuccess: () => {
      C({ type: "login_change_password" }), c();
    },
    onError: (s) => {
      l((s == null ? void 0 : s.message) || "");
    }
  });
}
const Ne = 4;
function It({
  email: r,
  password: n,
  customerId: t,
  control: c,
  onSuccess: l
}) {
  const { t: d } = _([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [s, o] = S(""), [u, a] = S(null), i = ue(null), p = ue(null), { setFlow: m } = le(), {
    mutate: f,
    isPending: R,
    error: E
  } = Rt(), {
    data: y,
    mutate: N,
    error: T
  } = Ge(), [F, q] = S(!1), D = (h) => {
    const { value: B } = h.target, j = B.replace(/\D/g, "");
    o(j);
  }, K = (h) => {
    if (h == null || h.stopPropagation(), h == null || h.preventDefault(), i.current && clearTimeout(i.current), !(s.length === Ne)) {
      a(d("register:errorNotValidCode"));
      return;
    }
    f({ newPassword: n, customerId: t, code: s }, { onSuccess: l });
  };
  k(() => {
    var h;
    return s.length === Ne && De(s) ? ((h = p.current) == null || h.blur(), i.current = setTimeout(() => {
      K();
    }, 1e3)) : i.current && clearTimeout(i.current), () => {
      i.current && clearTimeout(i.current);
    };
  }, [s]), k(() => {
    const h = (E == null ? void 0 : E.message) || (T == null ? void 0 : T.message);
    h && (a(h), setTimeout(() => {
      var B;
      (B = p.current) == null || B.focus();
    }, 1));
  }, [T, E]);
  const G = (h) => {
    h.preventDefault(), o(""), N(r, {
      onSuccess() {
        Ce.success(d("register:successMessageResendCode"), {
          icon: Ie
        }), a(null);
      }
    }), q(!0), setTimeout(() => {
      q(!1);
    }, 3e4);
  }, x = (y == null ? void 0 : y.control) || c;
  return /* @__PURE__ */ w(re, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: d("login-form:verifyNewPasswordTitle") }),
    /* @__PURE__ */ e("div", { className: "mb-4", children: d("login-form:verifyNewPasswordBody") }),
    /* @__PURE__ */ e(X, { children: /* @__PURE__ */ e(
      me,
      {
        label: "",
        value: r,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: W(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => m({ flow: A.LOGIN }),
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(we, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ w(
      "form",
      {
        className: "flex flex-col gap-4 mt-4",
        noValidate: !0,
        onSubmit: K,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Fe,
            {
              label: d("login-form:verifyNewPasswordCodeLabel"),
              value: s,
              "data-testid": "code",
              autoFocus: !0,
              onChange: D,
              disabled: R,
              maxLength: Ne,
              controlCharacter: x,
              description: d("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${x}`
              }
            }
          ),
          u && /* @__PURE__ */ e(ge, { variant: "error", children: u }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Z, { className: "w-full", loading: R, children: d(R ? "register:isVerifying" : "register:confirm") }) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              className: W(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: F,
              onClick: (h) => G(h),
              children: d("register:buttonLabelResendCode")
            }
          ) })
        ]
      }
    )
  ] });
}
function kt({
  values: r,
  onLogin: n
}) {
  const [t, c] = S({
    step: "CHOOSE_PASSWORD",
    email: r.email
  }), { t: l } = _(["change-password"]);
  return /* @__PURE__ */ w(re, { children: [
    t.step === "CHOOSE_PASSWORD" && /* @__PURE__ */ e(
      Et,
      {
        email: t.email,
        onSuccess: (d, s) => {
          c({
            step: "VERIFY_REQUEST",
            email: r.email,
            password: d,
            control: s
          });
        }
      }
    ),
    t.step === "VERIFY_REQUEST" && /* @__PURE__ */ e(
      It,
      {
        customerId: r.customerId,
        email: t.email,
        password: t.password,
        control: t.control,
        onSuccess: () => {
          Ce.success(l("change-password:successfulSave")), n({
            body: {
              email: t.email,
              customerId: r.customerId,
              password: t.password,
              hashedId: r.hashedId
            },
            disableAdobeEvent: !0
          });
        }
      }
    )
  ] });
}
function sr(r) {
  const [n, t] = S({ flow: A.LOGIN }), [c, l] = S(), [d, s] = S(void 0), { i18n: o } = _(), [u, a] = S(!1), [i, p] = S(
    {
      email: "",
      password: "",
      locale: Ae(o.language),
      optIn: !1,
      verificationMethod: "CODE",
      passwordless: !1
    }
  );
  return /* @__PURE__ */ e("div", { className: "px-4 py-6", children: /* @__PURE__ */ w(ut, { value: {
    signUpRequestBody: i,
    setSignUpRequestBody: p,
    setFlow: t,
    loginAfterSignUp: u,
    setLoginAfterSignUp: a,
    signUpResponse: c,
    setSignUpResponse: l,
    setVerificationError: s,
    verificationError: d
  }, children: [
    n.flow === A.LOGIN && /* @__PURE__ */ e(yt, { ...r, loginData: n.loginData }),
    n.flow === A.SIGN_UP && /* @__PURE__ */ e(
      pt,
      {
        setShowLoyaltyBenefits: r.setShowLoyaltyBenefits
      }
    ),
    n.flow === A.VERIFY && /* @__PURE__ */ e(
      _t,
      {
        verificationData: n.verificationData,
        navigateToLogin: r.isMyAccount,
        confirmSignInError: r.errorMessage,
        onLogin: r.onLogin
      }
    ),
    n.flow === A.FORGOT_PASSWORD && /* @__PURE__ */ e(
      kt,
      {
        values: n.forgotPasswordData,
        onLogin: r.onLogin
      }
    )
  ] }) });
}
function or({
  loyaltyCardNumber: r,
  close: n
}) {
  const { t, i18n: c } = _(["urls", "loyalty-benefits"]), { brand: l, MYACCOUNT_URL: d } = z(), s = Ze(), o = () => {
    n(), s(t("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, u = `${d}/api/app/loyalty/cards/apple-wallet-pass`, a = it() === at.IOS, i = async (p) => {
    const m = p.currentTarget;
    if (!(m != null && m.href))
      try {
        const f = await nt(), E = await (await fetch(u, {
          headers: {
            ...f,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), y = window.URL.createObjectURL(E);
        m.download = `${l}.pkpass`, m.href = y, m.click(), m.removeAttribute("href"), m.removeAttribute("download"), window.URL.revokeObjectURL(y);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
  return /* @__PURE__ */ w("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ w("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: t("loyalty-benefits:titleCongratsNewVdp") }),
      a && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: t("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ w("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(Ke, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ w(Ee, { t, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      ct,
      {
        language: c.language,
        cardNumber: r
      }
    ) }) }),
    a && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: i, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: c.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(Z, { onClick: o, className: "w-full", children: t("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  sr as A,
  A as F,
  or as L,
  wt as R,
  pt as S,
  _t as V,
  Te as a,
  ut as b,
  yt as c,
  vt as d,
  ht as e,
  Nt as f,
  qe as g,
  Me as h,
  dt as i,
  $e as j,
  mt as k,
  Ct as l,
  te as m,
  Ue as n,
  le as u,
  De as v
};
