import { forwardRef, type SVGProps } from "react";

export interface ReceiptIconProps extends SVGProps<SVGSVGElement> {}

export const Receipt = forwardRef<SVGSVGElement, ReceiptIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 21.75C12.5858 21.75 12.25 21.4142 12.25 21C12.25 20.5858 12.5858 20.25 13 20.25L15.5 20.25C15.6381 20.25 15.75 20.1381 15.75 20V5.22222C15.75 4.67081 15.928 4.17128 16.2068 3.75L6.46572 3.75L6.38438 3.7529C4.63556 3.81515 3.25 5.25091 3.25 7.00084L3.25 20C3.25 20.1381 3.36193 20.25 3.5 20.25H6C6.41421 20.25 6.75 20.5858 6.75 21C6.75 21.4142 6.41421 21.75 6 21.75H3.5C2.5335 21.75 1.75 20.9665 1.75 20L1.75 7.00084C1.75 4.44325 3.77505 2.34483 6.33102 2.25385L6.43904 2.25L19 2.25C20.7949 2.25 22.25 3.70507 22.25 5.5V7C22.25 7.9665 21.4665 8.75 20.5 8.75H17.25L17.25 20C17.25 20.9665 16.4665 21.75 15.5 21.75H13ZM17.25 7.25V5.22222C17.25 4.87816 17.4297 4.51718 17.7764 4.22155C18.1244 3.92481 18.58 3.75 19 3.75C19.9665 3.75 20.75 4.5335 20.75 5.5V7C20.75 7.13807 20.6381 7.25 20.5 7.25H17.25Z"
      />
      <path d="M5.25 16C5.25 15.5858 5.58579 15.25 6 15.25L13 15.25C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75L6 16.75C5.58579 16.75 5.25 16.4142 5.25 16Z" />
      <path d="M6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75H13C13.4142 12.75 13.75 12.4142 13.75 12C13.75 11.5858 13.4142 11.25 13 11.25H6Z" />
      <path d="M5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H10C10.4142 7.25 10.75 7.58579 10.75 8C10.75 8.41421 10.4142 8.75 10 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8Z" />
      <path d="M8.5 20.25C8.08579 20.25 7.75 20.5858 7.75 21C7.75 21.4142 8.08579 21.75 8.5 21.75H10.5C10.9142 21.75 11.25 21.4142 11.25 21C11.25 20.5858 10.9142 20.25 10.5 20.25H8.5Z" />
    </svg>
  ),
);

(Receipt as React.FC).displayName = "Receipt";
