/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    _DATADOG_SYNTHETICS_BROWSER: any;
  }
}

export const isSyntheticTest = !!(
  typeof window !== "undefined" && window._DATADOG_SYNTHETICS_BROWSER
);
