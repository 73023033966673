import { LoyaltyCard } from "@intergamma/loyalty-card";
import { media } from "@intergamma/theme";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface NavigationBarcodeProps {
  loyaltyCardNumber: string;
}

export function NavigationBarcode({
  loyaltyCardNumber,
}: NavigationBarcodeProps) {
  const { t, i18n } = useTranslation("common");

  return (
    <StyledContainer>
      <StyledTitle>{t("MyAccount")}</StyledTitle>
      <StyledBarCodeContainer>
        <LoyaltyCard cardNumber={loyaltyCardNumber} language={i18n.language} />
      </StyledBarCodeContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f5f5;

  ${media.md`
    display: none;
  `}
`;

const StyledBarCodeContainer = styled.div`
  padding: 1rem;
`;

const StyledTitle = styled.h4`
  margin: 0;
  padding: 0.25rem 0;
  text-align: center;
  line-height: 28px;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`;
