import { DialogExtended, DialogBottomSheet } from "@intergamma/dialog";
import { LoyaltyBenefits } from "@intergamma/account";
import { media } from "@intergamma/theme";
import { useConfig } from "@intergamma/config";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useSessionData } from "../session-data/SessionDataContext";

export function LoyaltyBenefitsModal() {
  const { t } = useTranslation("urls");
  const customer = useSessionData();
  const navigate = useNavigate();
  const { isMobile } = useConfig();

  const close = () => {
    navigate(t("/myaccount"), { replace: true });
  };

  if (!customer.isLoggedIn) {
    close();
  }

  const dialogContent = (
    <LoyaltyBenefitsContainer>
      <LoyaltyBenefits
        loyaltyCardNumber={customer.defaultLoyaltyCardNumber}
        close={close}
      />
    </LoyaltyBenefitsContainer>
  );

  if (isMobile) {
    return (
      <DialogBottomSheet open onOpenChange={close}>
        {dialogContent}
      </DialogBottomSheet>
    );
  }

  return (
    <DialogExtended open onOpenChange={close}>
      {dialogContent}
    </DialogExtended>
  );
}

const LoyaltyBenefitsContainer = styled.div`
  padding: 1rem;

  ${media.md`
    padding: 0rem;
  `}
`;
