const y = "#000", g = "#FFF", n = "#003878", B = "rgba(0, 56, 120, 0.2)", T = "#000545", t = "#F0F2F6", u = "#A1B5C5", x = "#7D98AA", s = "#6D818D", S = "#51646F", f = "#36444D", A = "#E55026", M = "#B93A16", d = "#74c200", X = "#69aa0a", G = "#FF7300";
const uo = {
  name: "gamma_be",
  domain: "gamma.be",
  gamma: !0,
  brandName: "GAMMA",
  contentfulSpaceId: "2oqwvxysf41a",
  contentfulToken: "bdbdeba23f7ce4133a995c715a38bb2e161163a579cb925a27a2d3f4e44c9e76",
  formulaLoyaltyCardName: "GAMMAplus",
  loyaltyPointSlug: "Punten",
  loyaltyPointsOverview: "puntenoverzicht",
  loyaltyCardName: "GAMMAplus kaart",
  loyaltyCardSlug: "gamma-plus-kaart",
  giftcardName: "Cadeaukaart",
  giftcardSlug: "cadeaukaart",
  storeName: "winkel",
  storesName: "Winkels",
  storesSlug: "winkels-en-openingsuren",
  adviceName: "Doe-het-zelf advies",
  adviceSlug: "doe-het-zelf",
  serviceName: "Services",
  serviceSlug: "klanteninformatie",
  servicePhone: "0800 62 610",
  servicePhoneLink: "tel:+3280062610",
  promoName: "Promo",
  promoSlug: "promo/acties",
  baseFont: "Overpass, sans-serif",
  brandFont: "Overpass, sans-serif",
  borderRadius: 8,
  primaryBackgroundColor: g,
  secondaryBackgroundColor: t,
  pageBackgroundColor: t,
  pageBackgroundColorTransparent: `${t}00`,
  footerBackgroundColor: t,
  brandColor: n,
  brandColorHighlight: B,
  brandColorSecondary: g,
  linkColor: n,
  linkHoverColor: n,
  linkHighlightColor: B,
  buttonColor: n,
  buttonFocusBorderColor: X,
  buttonFocusColor: T,
  buttonHighlightColor: B,
  buttonBorderRadius: 300,
  badgeBackgroundColor: u,
  primaryButtonColor: d,
  progressColor: d,
  progressBackgroundColor: u,
  secondaryButtonColor: g,
  secondaryButtonTextColor: n,
  secondaryButtonFocusColor: t,
  dangerButtonColor: A,
  dangerButtonFocusColor: M,
  inputBackgroundColor: g,
  badgeColor: f,
  notificationColor: d,
  errorColor: A,
  successColor: d,
  mutedColor: s,
  borderColor: u,
  dividerColor: u,
  titleColor: y,
  textColor: y,
  iconColor: s,
  secondaryTextColor: S,
  searchPromotionColor: G,
  selectionBackground: "rgba(0, 147, 216, 0.25)",
  formInputFloatingLabelBackgroundColor: t,
  formInputFloatingLabelTextColor: f,
  formInputBorderColor: s,
  formInputBorderFocussedColor: n,
  formInputPlaceholderColor: s,
  formInputTextColor: y,
  neutral50: t,
  neutral200: u,
  neutral400: x,
  neutral500: s,
  neutral700: S,
  neutral800: f,
  gutterSmall: 5,
  gutter: 10,
  gutterMedium: 15,
  gutterLarge: 30,
  gutterXL: 50,
  gutterXXL: 70,
  gridGap: 20,
  fonts: [
    {
      family: "Overpass"
    }
  ]
}, F = "#000", i = "#FFF", l = "#003878", v = "rgba(0, 56, 120, 0.2)", D = "#000545", o = "#F0F2F6", C = "#A1B5C5", H = "#7D98AA", m = "#6D818D", b = "#51646F", L = "#36444D", k = "#74C200", O = "#69AA0A", N = "#E55026", E = "#CC370D", K = "#FF7300", R = 5, _ = 10, z = 15, q = 30, V = 50, W = 70, j = 20, so = {
  name: "gamma_nl",
  domain: "gamma.nl",
  gamma: !0,
  brandName: "GAMMA",
  contentfulSpaceId: "7k5qhlbm8iou",
  contentfulToken: "264fa5643e4db3af05892ce1cec30e1880b98e082f9e0e3074590b6d67c508a7",
  formulaLoyaltyCardName: "GAMMA Voordeelpas",
  loyaltyPointSlug: "Punten",
  loyaltyPointsOverview: "puntenoverzicht",
  loyaltyCardName: "GAMMA Voordeelpas",
  loyaltyCardSlug: "gamma-voordeelpas",
  giftcardName: "Cadeaupas",
  giftcardSlug: "cadeaupas",
  storeName: "bouwmarkt",
  storesName: "Bouwmarkten",
  storesSlug: "bouwmarkten",
  adviceName: "Klusadvies",
  adviceSlug: "klusadvies",
  serviceName: "Klantenservice",
  serviceSlug: "klantenservice",
  servicePhone: "088 – 010 86 10",
  servicePhoneLink: "tel:+31880108610",
  promoName: "Actie",
  promoSlug: "aanbiedingen-en-acties",
  baseFont: "Overpass, sans-serif",
  brandFont: "Overpass, sans-serif",
  borderRadius: 8,
  primaryBackgroundColor: i,
  secondaryBackgroundColor: o,
  pageBackgroundColor: o,
  pageBackgroundColorTransparent: `${o}00`,
  footerBackgroundColor: o,
  brandColor: l,
  brandColorHighlight: v,
  brandColorSecondary: i,
  linkColor: l,
  linkHoverColor: l,
  linkHighlightColor: v,
  buttonColor: l,
  buttonFocusBorderColor: O,
  buttonFocusColor: D,
  buttonBorderRadius: 300,
  buttonHighlightColor: v,
  primaryButtonColor: k,
  progressColor: k,
  progressBackgroundColor: o,
  secondaryButtonColor: i,
  secondaryButtonTextColor: l,
  secondaryButtonFocusColor: o,
  dangerButtonColor: N,
  dangerButtonFocusColor: E,
  inputBackgroundColor: i,
  badgeColor: L,
  badgeBackgroundColor: C,
  notificationColor: k,
  errorColor: N,
  successColor: k,
  mutedColor: b,
  borderColor: C,
  dividerColor: C,
  titleColor: F,
  textColor: F,
  iconColor: m,
  secondaryTextColor: b,
  searchPromotionColor: K,
  selectionBackground: "rgba(0, 147, 216, 0.25)",
  formInputFloatingLabelBackgroundColor: o,
  formInputFloatingLabelTextColor: b,
  formInputBorderColor: m,
  formInputBorderFocussedColor: l,
  formInputPlaceholderColor: m,
  formInputTextColor: F,
  neutral50: o,
  neutral200: C,
  neutral400: H,
  neutral500: m,
  neutral700: b,
  neutral800: L,
  gutterSmall: R,
  gutter: _,
  gutterMedium: z,
  gutterLarge: q,
  gutterXL: V,
  gutterXXL: W,
  gridGap: j,
  fonts: [
    {
      family: "Overpass"
    }
  ]
}, p = "#FFF", r = "#111316", J = "rgba(17, 19, 22, 0.2)", a = "#F1F4F8", e = "#DCE1E8", Q = "#ABB3BF", $ = "#878E9C", c = "#5B6573", h = "#4B525B", w = "#FD7D0A", U = "#C36231", Y = "#C20344", P = "#E55026", Z = "#B93A16", I = "#0BBA5F", oo = 5, ro = 10, eo = 15, to = 30, ao = 50, no = 70, lo = 20, co = {
  name: "karwei_nl",
  domain: "karwei.nl",
  karwei: !0,
  brandName: "Karwei",
  contentfulSpaceId: "ufyps4xa8qu5",
  contentfulToken: "2441ab9b85cbb65b61520dcf6c4948ef4f7e07619837f16aba5be8096a85c2b7",
  formulaLoyaltyCardName: "Club Karwei-kaart",
  loyaltyCardName: "Club Karwei-kaart",
  loyaltyCardSlug: "club-karwei-kaart",
  loyaltyPointsOverview: "gespaarde punten",
  loyaltyPointSlug: "Punten",
  giftcardName: "Cadeaukaart",
  giftcardSlug: "cadeaukaart",
  storeName: "bouwmarkt",
  storesName: "Bouwmarkten",
  storesSlug: "vestigingen",
  adviceName: "Advies",
  adviceSlug: "klusadvies",
  serviceName: "Klantenservice",
  serviceSlug: "klantenservice",
  servicePhone: "088 – 010 86 20",
  servicePhoneLink: "tel:+31880108620",
  promoName: "Actie",
  promoSlug: "acties-en-aanbiedingen",
  baseFont: "Akkurat, sans-serif",
  brandFont: "Akkurat, sans-serif",
  borderRadius: 4,
  primaryBackgroundColor: p,
  secondaryBackgroundColor: a,
  pageBackgroundColor: a,
  pageBackgroundColorTransparent: `${a}00`,
  footerBackgroundColor: a,
  brandColor: r,
  brandColorHighlight: J,
  brandColorSecondary: p,
  linkColor: r,
  linkHoverColor: r,
  linkHighlightColor: e,
  buttonColor: r,
  buttonFocusBorderColor: U,
  buttonFocusColor: e,
  buttonBorderRadius: 300,
  buttonHighlightColor: e,
  primaryButtonColor: w,
  progressColor: I,
  progressBackgroundColor: e,
  secondaryButtonColor: p,
  secondaryButtonTextColor: r,
  secondaryButtonFocusColor: a,
  dangerButtonColor: P,
  dangerButtonFocusColor: Z,
  inputBackgroundColor: p,
  badgeColor: h,
  badgeBackgroundColor: e,
  notificationColor: w,
  errorColor: P,
  successColor: I,
  mutedColor: c,
  borderColor: e,
  dividerColor: e,
  titleColor: r,
  textColor: r,
  iconColor: $,
  secondaryTextColor: c,
  searchPromotionColor: Y,
  selectionBackground: "rgba(0, 143, 157, 0.25)",
  formInputFloatingLabelBackgroundColor: a,
  formInputFloatingLabelTextColor: h,
  formInputBorderColor: c,
  formInputBorderFocussedColor: $,
  formInputPlaceholderColor: c,
  formInputTextColor: r,
  neutral50: a,
  neutral200: e,
  neutral400: Q,
  neutral500: $,
  neutral700: c,
  neutral800: h,
  gutterSmall: oo,
  gutter: ro,
  gutterMedium: eo,
  gutterLarge: to,
  gutterXL: ao,
  gutterXXL: no,
  gridGap: lo,
  fonts: [
    {
      family: "Akkurat"
    }
  ]
};
export {
  uo as gamma_be,
  so as gamma_nl,
  co as karwei_nl
};
