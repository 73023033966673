import { forwardRef, useId } from "react";

import { cn } from "@intergamma/common/cn";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

export const Root = forwardRef<
  HTMLDivElement,
  RadioGroupPrimitive.RadioGroupProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function Root({ className, ...props }, ref) {
    return (
      <RadioGroupPrimitive.Root
        className={cn("flex flex-col gap-4", className)}
        {...props}
        ref={ref}
      />
    );
  },
);

export const Radio = forwardRef<
  HTMLButtonElement,
  RadioGroupPrimitive.RadioGroupItemProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function Radio({ children, className, ...props }, ref) {
    const id = useId();

    return (
      <div className={cn("flex items-center gap-2", className)}>
        <RadioGroupPrimitive.Item
          className={cn(
            "peer size-6 min-w-0 rounded-full border bg-white gamma:border-ignew-neutral-200 karwei:border-ignew-neutral-100",
            "enabled:hover:bg-ignew-neutral-25",
            "gamma:disabled:border-ignew-neutral-50 gamma:disabled:bg-ignew-neutral-50 karwei:disabled:border-ignew-neutral-100 karwei:disabled:bg-ignew-neutral-100",
            "data-[state=checked]:border-0",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            className,
          )}
          id={id}
          ref={ref}
          {...props}
        >
          <Indicator />
        </RadioGroupPrimitive.Item>
        <label
          className="gamma:text-ignew-functional-primary-800 gamma:peer-disabled:text-ignew-neutral-400 karwei:peer-disabled:text-ignew-neutral-600"
          htmlFor={id}
        >
          {children}
        </label>
      </div>
    );
  },
);

export const Item = RadioGroupPrimitive.RadioGroupItem;

export const Indicator = forwardRef<
  HTMLSpanElement,
  RadioGroupPrimitive.RadioGroupIndicatorProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function Indicator() {
    return (
      <RadioGroupPrimitive.Indicator className="text-brand-primary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle
            cx="12"
            cy="12"
            r="7.5"
            stroke="currentColor"
            strokeWidth="9"
          />
        </svg>
      </RadioGroupPrimitive.Indicator>
    );
  },
);

export const RadioGroup = {
  Root,
  Item,
  Indicator,
  Radio,
};
