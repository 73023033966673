function n(r) {
  return {
    /**
     * Asserts that sessionStorage or localStorage is available.
     *
     * Returns true even on quota exceeded error, so we won't silently ignore when we're using too much
     * space, unless we haven't stored anything yet which will happen when the browser has set a very
     * strict size limit (i.e. Safari Private Browsing sets quota to 0).
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
     */
    isAvailable() {
      let t;
      try {
        const e = "__storage_test__";
        return t = window[r], t.setItem(e, e), t.removeItem(e), !0;
      } catch (e) {
        return e instanceof window.DOMException && (e.code === 22 || e.code === 1014 || e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED") && t && t.length !== 0;
      }
    },
    /**
     * Retrieve an item from localStorage.
     *
     * @param {string} key Item key
     * @return {string} The item value, or undefined if not found
     */
    getItem(t) {
      try {
        return window[r] && window[r].getItem(t);
      } catch {
        return;
      }
    },
    /**
     * Store an item in localStorage, checking for availability.
     *
     * @param {string} key Item key
     * @param {string} value Item value
     * @return {boolean} true if succeeded, false otherwise
     */
    setItem(t, e) {
      try {
        return this.isAvailable() && window[r].setItem(t, e), !0;
      } catch {
        return !1;
      }
    },
    /**
     * Remove an item from localStorage.
     *
     * @param {string} key Item key
     * @return {void}
     */
    removeItem(t) {
      try {
        return window[r] && window[r].removeItem(t);
      } catch {
        return;
      }
    },
    /**
     * Retrieve an object from localStorage, using JSON deserialization.
     *
     * @param {string} key Item key
     * @return {string|array|object}
     */
    getJson(t) {
      try {
        const e = this.getItem(t);
        return e && JSON.parse(e);
      } catch {
        return;
      }
    },
    /**
     * Store an object in localStorage using JSON serialization.
     *
     * @param {any} key Item key
     * @param {string|array|object} data Item value, must be JSON-serializable
     * @return {boolean} true if succeeded, false otherwise
     */
    setJson(t, e) {
      try {
        return this.setItem(t, JSON.stringify(e));
      } catch {
        return !1;
      }
    }
  };
}
const s = n("localStorage"), a = n("sessionStorage");
export {
  s as localStorage,
  a as sessionStorage
};
