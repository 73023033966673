import { forwardRef, type SVGProps } from "react";

export interface ProIconProps extends SVGProps<SVGSVGElement> {}

export const Pro = forwardRef<SVGSVGElement, ProIconProps>((props, ref) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <mask
      id="path-1-outside-1_7397_107583"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
      fill="black"
    >
      <rect fill="white" width="18" height="18" />
      <path d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" />
    </mask>
    <path
      d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
      fill="#003878"
    />
    <path
      d="M16 9C16 12.866 12.866 16 9 16V18C13.9706 18 18 13.9706 18 9H16ZM9 16C5.13401 16 2 12.866 2 9H0C0 13.9706 4.02944 18 9 18V16ZM2 9C2 5.13401 5.13401 2 9 2V0C4.02944 0 0 4.02944 0 9H2ZM9 2C12.866 2 16 5.13401 16 9H18C18 4.02944 13.9706 0 9 0V2Z"
      fill="white"
      mask="url(#path-1-outside-1_7397_107583)"
    />
    <path
      d="M8.66722 11.8359C8.78944 11.7621 8.85055 11.7252 8.91592 11.7111C8.97134 11.6992 9.02866 11.6992 9.08408 11.7111C9.14945 11.7252 9.21056 11.7621 9.33278 11.8359L10.7833 12.7113C11.1986 12.962 11.4063 13.0873 11.5534 13.0618C11.6756 13.0406 11.781 12.964 11.8389 12.8543C11.9086 12.7222 11.8535 12.4861 11.7433 12.0137L11.3583 10.3635C11.3259 10.2245 11.3097 10.155 11.3165 10.0885C11.3222 10.0321 11.3399 9.97757 11.3684 9.92853C11.4019 9.8707 11.4559 9.82396 11.5638 9.73048L12.8463 8.61941C13.213 8.30179 13.3963 8.14297 13.4175 7.99514C13.4351 7.87239 13.3947 7.74843 13.3083 7.65953C13.2042 7.55248 12.9625 7.53198 12.4791 7.49097L10.792 7.34783C10.6498 7.33577 10.5788 7.32974 10.5176 7.30276C10.4658 7.27989 10.4195 7.24627 10.3816 7.20413C10.3369 7.15443 10.3091 7.08877 10.2535 6.95744L9.59294 5.39901C9.40438 4.95411 9.3101 4.73166 9.17624 4.66596C9.06508 4.6114 8.93492 4.6114 8.82376 4.66596C8.6899 4.73166 8.59562 4.95411 8.40706 5.39901L7.74654 6.95744C7.69088 7.08877 7.66305 7.15443 7.6184 7.20413C7.58055 7.24627 7.53418 7.27989 7.48235 7.30276C7.42123 7.32974 7.35017 7.33577 7.20804 7.34783L5.52089 7.49097C5.03753 7.53198 4.79585 7.55248 4.69173 7.65953C4.60527 7.74843 4.56492 7.87239 4.58251 7.99514C4.60368 8.14297 4.78701 8.30179 5.15366 8.61941L6.4362 9.73048C6.54411 9.82396 6.59806 9.8707 6.63161 9.92853C6.66005 9.97757 6.67775 10.0321 6.68353 10.0885C6.69034 10.155 6.67412 10.2245 6.64169 10.3635L6.25674 12.0137C6.14654 12.4861 6.09144 12.7222 6.16112 12.8543C6.21897 12.964 6.32443 13.0406 6.44661 13.0618C6.59375 13.0873 6.80139 12.962 7.21668 12.7113L8.66722 11.8359Z"
      fill="white"
    />
  </svg>
));

(Pro as React.FC).displayName = "Pro";
