import { useState } from "react";

import { Button } from "@intergamma/button";
import { useConfig } from "@intergamma/config";
import { DialogBottomSheet, DialogExtended } from "@intergamma/dialog";
import { cn } from "@intergamma/common/cn";

interface ConfirmDialogProps {
  trigger: React.ReactNode;
  title: string;
  message: string;
  buttonLabels: {
    confirm: string;
    cancel: string;
  };
  action: () => void;
  hasDarkPattern?: boolean;
}

export function ConfirmDialog({
  trigger,
  title,
  message,
  action,
  buttonLabels,
  hasDarkPattern = true,
}: ConfirmDialogProps) {
  const [open, setOpen] = useState(false);
  const { isMobile } = useConfig();

  const submit = () => {
    action();
    setOpen(false);
  };

  const Dialog = isMobile ? DialogBottomSheet : DialogExtended;

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)} trigger={trigger}>
      <div className="p-4" data-testid="confirm-dialog">
        <h3 className="whitespace-pre-wrap py-6 text-200 font-bold text-brand-primary">
          {title}
        </h3>
        <p>{message}</p>
        <div
          className={cn("mt-6 flex flex-row gap-2", {
            "flex-row-reverse": !hasDarkPattern,
          })}
        >
          <Button
            onClick={submit}
            $wide
            $secondary={hasDarkPattern}
            data-testid="confirm-button"
          >
            {buttonLabels.confirm}
          </Button>
          <Button
            $wide
            onClick={() => setOpen(false)}
            $secondary={!hasDarkPattern}
            className="!ml-0"
          >
            {buttonLabels.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
