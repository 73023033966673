import { createHttpClient } from "@intergamma/account";

import { SessionResponse } from "./types/Session";

export interface CSRFService {
  getGatewayCSRF(): Promise<string>;
}

export function createCsrfService(MYACCOUNT_URL: string): CSRFService {
  const gatewayClient = createHttpClient(`${MYACCOUNT_URL}/gateway`, {
    withCredentials: true,
  });

  return {
    async getGatewayCSRF(): Promise<string> {
      const session = await gatewayClient.get<SessionResponse>("/session");

      return session.csrfToken;
    },
  };
}
