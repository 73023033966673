import {
  LoyaltyCardPointsToDiscountStatusResponse,
  LoyaltyToDiscountResponse,
} from "../types/LoyaltyCardStatus";

export function checkLoyaltyPointsStatus(response: LoyaltyToDiscountResponse) {
  if (
    response.status ===
    LoyaltyCardPointsToDiscountStatusResponse.INVALID_RECAPTCHA
  ) {
    throw new Error("INVALID_RECAPTCHA");
  }

  if (
    response.status === LoyaltyCardPointsToDiscountStatusResponse.UNAUTHORIZED
  ) {
    throw new Error("UNAUTHORIZED");
  }

  if (
    response.status === LoyaltyCardPointsToDiscountStatusResponse.NETWORK_ERROR
  ) {
    throw new Error("NETWORK_ERROR");
  }

  if (
    response.status ===
    LoyaltyCardPointsToDiscountStatusResponse.INSUFFICIENT_BALANCE
  ) {
    throw new Error("INSUFFICIENT_BALANCE");
  }

  if (
    response.status === LoyaltyCardPointsToDiscountStatusResponse.FAILED_UNKNOWN
  ) {
    throw new Error("FAILED_UNKNOWN");
  }

  if (
    response.status ===
    LoyaltyCardPointsToDiscountStatusResponse.LOYALTY_CARD_REGISTERED
  ) {
    throw new Error("LOYALTY_CARD_REGISTERED");
  }

  if (
    response.status ===
    LoyaltyCardPointsToDiscountStatusResponse.CREATE_DISCOUNT_CODE_FAILED
  ) {
    throw new Error("CREATE_DISCOUNT_CODE_FAILED");
  }

  return response;
}
