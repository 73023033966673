import { ReactElement, useEffect } from "react";

import Cookies from "js-cookie";

import { initDataDog } from "~/helpers/tracking/datadog-logging";
import { initDataDogRum } from "~/helpers/tracking/datadog-rum";

import { useApiKeysConfig, useBaseConfig } from "./MyAccountConfigContext";

interface DataDogLoggingProps {
  children: ReactElement;
}

export function DataDogLogging({ children }: DataDogLoggingProps) {
  const { brand, version, isProduction, fullBrand, isLocal } = useBaseConfig();
  const { dataDog } = useApiKeysConfig();

  useEffect(() => {
    initDataDog({
      brand,
      env: isProduction ? "prd" : "acc",
      clientKey: dataDog.clientKey,
      serviceName: "myaccount-frontend",
      logDestination: dataDog.logDestination,
      logLevel: dataDog.logLevel,
      preferredStore: Cookies.get("PREFERRED-STORE"),
      skin: fullBrand,
      version,
    });

    if (!isLocal) {
      initDataDogRum({
        applicationId: dataDog.rumApplicationId,
        clientToken: dataDog.rumClientKey,
        serviceName: "myaccount-frontend",
        env: isProduction ? "prd" : "acc",
        version,
      });
    }
  }, [
    brand,
    dataDog.clientKey,
    dataDog.logDestination,
    dataDog.logLevel,
    dataDog.rumApplicationId,
    dataDog.rumClientKey,
    fullBrand,
    isProduction,
    version,
    isLocal,
  ]);

  return children;
}
