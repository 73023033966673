import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface LoyaltyCardIconProps extends SVGProps<SVGSVGElement> {}

export const LoyaltyCard = forwardRef<SVGSVGElement, LoyaltyCardIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn("karwei:hidden", className)}
        {...props}
        ref={ref}
      >
        <path
          d="M16.25 10.5C15.8358 10.5 15.5 10.8358 15.5 11.25C15.5 11.6642 15.8358 12 16.25 12H18.75C19.1642 12 19.5 11.6642 19.5 11.25C19.5 10.8358 19.1642 10.5 18.75 10.5H16.25Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 7C0.75 4.92893 2.42893 3.25 4.5 3.25H19.5C21.5711 3.25 23.25 4.92893 23.25 7V17C23.25 19.0711 21.5711 20.75 19.5 20.75H4.5C2.42893 20.75 0.75 19.0711 0.75 17V7ZM4.5 4.75C3.25736 4.75 2.25 5.75736 2.25 7V14H21.75V7C21.75 5.75736 20.7426 4.75 19.5 4.75H4.5ZM2.25 17V15.5H21.75V17C21.75 18.2426 20.7426 19.25 19.5 19.25H4.5C3.25736 19.25 2.25 18.2426 2.25 17Z"
          fill="currentColor"
        />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn("gamma:hidden", className)}
        {...props}
        ref={ref}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 4H3C2.44772 4 2 4.44772 2 5V15H22V5C22 4.44771 21.5523 4 21 4ZM2 19V16H22V19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19ZM3 3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3H3ZM20 11H16V12H20V11Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(LoyaltyCard as React.FC).displayName = "LoyaltyCard";
