import type { ReactNode, ReactElement } from "react";

import { WithoutDevices } from "@intergamma/devices";
import { media } from "@intergamma/theme";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Breadcrumbs, BreadcrumbItem } from "~/components/Breadcrumbs";
import { Link } from "~/components/Link";
import { MopinionFeedback } from "~/components/MopinionFeedback";

import { useDocumentTitle } from "~/hooks/useDocumentTitle";

interface PageLayoutProps {
  title?: string;
  heading?: string | ReactElement;
  breadcrumbs?: { url: string; label: string | ReactElement }[];
  children: ReactNode;
}

export function PageLayout({
  title,
  heading,
  breadcrumbs,
  children,
}: PageLayoutProps) {
  const { t } = useTranslation("common");

  useDocumentTitle({
    appTitle: t("MyAccount"),
    pageTitle: title,
  });

  return (
    <StyledPage id="page">
      <StyledTop>
        <Container>
          <WithoutDevices devices={["app"]}>
            <>
              {breadcrumbs && (
                <nav title="breadcrumbs">
                  <Breadcrumbs>
                    {breadcrumbs.map((item) => (
                      <BreadcrumbItem key={item.url}>
                        <Link to={item.url}>{item.label}</Link>
                      </BreadcrumbItem>
                    ))}
                  </Breadcrumbs>
                </nav>
              )}
              {heading && <Title>{heading}</Title>}
            </>
          </WithoutDevices>
        </Container>
      </StyledTop>
      <MainWrapper>
        <Container as="main">
          {children}
          <MopinionFeedback />
        </Container>
      </MainWrapper>
    </StyledPage>
  );
}

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  ${media.md`
    max-width: 768px;
    padding: 0 24px;
  `};
  ${media.lg`
    max-width: 1024px;
    padding: 0 32px;
  `};
  ${media.xl`
    max-width: 1264px;
    padding: 0 32px;
  `};
`;

export const StyledTop = styled.div`
  ${Breadcrumbs} {
    margin-bottom: 1rem;
  }
`;

export const StyledPage = styled.div`
  width: 100%;
  min-height: 80vh;
  padding: 0 1rem;

  ${media.md`
    padding: 0;
  `}
`;

const Title = styled.h1`
  margin: 1rem 0;
  font-weight: 700;
  line-height: 42px;
  font-size: 2.25em;
  font-family: ${(props) => props.theme.brandFont};
  color: ${(props) => props.theme.colors.black};
`;

const MainWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};

  ${Container} {
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: 2rem;
  }
`;
