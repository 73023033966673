import styled from "styled-components";

import { Loader } from "~/components/Loader";

export function LoadingPageLayout() {
  return (
    <LoadingPageLayoutContainer>
      <Loader />
    </LoadingPageLayoutContainer>
  );
}

const LoadingPageLayoutContainer = styled.div`
  min-height: 80vh;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: relative;
`;
