import { forwardRef, type SVGProps } from "react";

export interface InfoIconProps extends SVGProps<SVGSVGElement> {}

export const Info = forwardRef<SVGSVGElement, InfoIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 16.9265C11.589 16.9265 11.2558 16.5933 11.2558 16.1823V10.6009C11.2558 10.1899 11.589 9.85673 12 9.85673C12.411 9.85673 12.7442 10.1899 12.7442 10.6009V16.1823C12.7442 16.5933 12.411 16.9265 12 16.9265Z"
      fill="currentColor"
    />
    <path
      d="M11.1816 7.81023C11.1816 8.22123 11.5148 8.55442 11.9258 8.55442C12.3368 8.55442 12.6699 8.22123 12.6699 7.81023V7.72093C12.6699 7.30993 12.3368 6.97674 11.9258 6.97674C11.5148 6.97674 11.1816 7.30993 11.1816 7.72093V7.81023Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 3.39535C7.24778 3.39535 3.39535 7.24778 3.39535 12C3.39535 16.7522 7.24778 20.6047 12 20.6047C16.7522 20.6047 20.6047 16.7522 20.6047 12C20.6047 7.24778 16.7522 3.39535 12 3.39535Z"
      fill="currentColor"
    />
  </svg>
));

(Info as React.FC).displayName = "Info";
