import { datadogRum } from "@datadog/browser-rum";

interface InitDataDogRumProps {
  applicationId: string;
  clientToken: string;
  serviceName: string;
  env: string;
  version: string;
}

export function initDataDogRum({
  applicationId,
  clientToken,
  serviceName,
  env,
  version,
}: InitDataDogRumProps) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.eu",
    service: serviceName,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    env,
    version,
    compressIntakeRequests: true,
  });
}
