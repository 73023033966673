import { forwardRef, type SVGProps } from "react";

export interface ToolIconProps extends SVGProps<SVGSVGElement> {}

export const Tool = forwardRef<SVGSVGElement, ToolIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.8512 7.62317C13.6969 7.78055 13.6105 7.99213 13.6105 8.21249C13.6105 8.43286 13.6969 8.64444 13.8512 8.80181L15.1982 10.1488C15.3556 10.3031 15.5671 10.3895 15.7875 10.3895C16.0079 10.3895 16.2195 10.3031 16.3768 10.1488L19.5507 6.97492C19.9741 7.91041 20.1022 8.95271 19.9182 9.96289C19.7341 10.9731 19.2466 11.9032 18.5205 12.6293C17.7944 13.3553 16.8643 13.8429 15.8541 14.0269C14.8439 14.211 13.8017 14.0828 12.8662 13.6595L7.04874 19.4769C6.71381 19.8118 6.25956 20 5.78591 20C5.31226 20 4.858 19.8118 4.52308 19.4769C4.18816 19.142 4 18.6877 4 18.2141C4 17.7404 4.18816 17.2862 4.52308 16.9513L10.3405 11.1338C9.91718 10.1983 9.789 9.15605 9.97306 8.14586C10.1571 7.13567 10.6447 6.20557 11.3707 5.4795C12.0968 4.75343 13.0269 4.26588 14.0371 4.08182C15.0473 3.89776 16.0896 4.02594 17.0251 4.44927L13.8596 7.61475L13.8512 7.62317Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

(Tool as React.FC).displayName = "Tool";
