import { forwardRef, type SVGProps } from "react";

export interface CustomerServiceIconProps extends SVGProps<SVGSVGElement> {}

export const CustomerService = forwardRef<
  SVGSVGElement,
  CustomerServiceIconProps
>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_3024_8855)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C7.99594 2 4.75 5.24594 4.75 9.25V9.6875C4.75 9.70789 4.74919 9.7281 4.74759 9.74808C5.92335 10.2373 6.75 11.3971 6.75 12.75V14.75C6.75 16.5449 5.29493 18 3.5 18C1.70507 18 0.25 16.5449 0.25 14.75V12.75C0.25 11.0392 1.57189 9.63713 3.25 9.50947V9.25C3.25 4.41751 7.16751 0.5 12 0.5C16.8325 0.5 20.75 4.41751 20.75 9.25V9.50947C22.4281 9.63713 23.75 11.0392 23.75 12.75V14.75C23.75 16.5219 22.3321 17.9625 20.569 17.9993C19.9102 20.6751 17.5279 22.6729 14.6668 22.7478C14.2349 23.4963 13.4263 24 12.5 24H11.5C10.1193 24 9 22.8807 9 21.5C9 20.1193 10.1193 19 11.5 19H12.5C13.788 19 14.8484 19.974 14.9851 21.2255C16.9823 21.0229 18.6139 19.583 19.1012 17.6844C18.0064 17.1616 17.25 16.0441 17.25 14.75V12.75C17.25 11.398 18.0755 10.2389 19.25 9.74908V9.25C19.25 5.24594 16.0041 2 12 2ZM20.5 16.5C21.4665 16.5 22.25 15.7165 22.25 14.75V12.75C22.25 11.7835 21.4665 11 20.5 11C19.5335 11 18.75 11.7835 18.75 12.75V14.75C18.75 15.7165 19.5335 16.5 20.5 16.5ZM10.5 21.5C10.5 20.9477 10.9477 20.5 11.5 20.5H12.5C13.0523 20.5 13.5 20.9477 13.5 21.5C13.5 22.0523 13.0523 22.5 12.5 22.5H11.5C10.9477 22.5 10.5 22.0523 10.5 21.5ZM3.5 11C2.5335 11 1.75 11.7835 1.75 12.75V14.75C1.75 15.7165 2.5335 16.5 3.5 16.5C4.4665 16.5 5.25 15.7165 5.25 14.75V12.75C5.25 11.7835 4.4665 11 3.5 11Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3024_8855">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

(CustomerService as React.FC).displayName = "CustomerService";
