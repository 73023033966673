import { jsxs as t, jsx as e } from "react/jsx-runtime";
function r(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M17.0251 4.26392C17.1824 4.41175 19.2796 6.5 19.5 6.5M17.0251 4.26392C16.0896 3.85823 15.5102 2.32375 14.5 2.50014C13.4898 2.67653 12.0968 4.55541 11.3707 5.25122C10.6447 5.94704 10.1571 6.83839 9.97306 7.80649C9.789 8.77458 9.91718 9.77345 10.3405 10.67L4.52308 16.245C4.18816 16.566 4 17.0013 4 17.4552C4 17.9091 4.18816 18.3444 4.52308 18.6654C4.858 18.9864 5.31226 19.1667 5.78591 19.1667C6.25956 19.1667 6.71381 18.9864 7.04874 18.6654L12.8662 13.0904C13.8017 13.4961 14.8439 13.6189 15.8541 13.4425C16.8643 13.2661 17.7944 12.7989 18.5205 12.1031C19.2466 11.4073 21.3159 9.96821 21.5 9.00012C21.6841 8.03202 19.9233 7.39651 19.5 6.5M17.0251 4.26392L14.5 6.50014M19.5 6.5C19.7204 6.5 17.5 8.49986 18 8L19.5 6.5Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M11 6.5L17 12.5", strokeLinecap: "round" })
      ]
    }
  );
}
function i(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "23",
      viewBox: "0 0 24 23",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M17.6001 5.36646H6.40005C5.51639 5.36646 4.80005 6.05295 4.80005 6.89979V17.6332C4.80005 18.48 5.51639 19.1665 6.40005 19.1665H17.6001C18.4837 19.1665 19.2001 18.48 19.2001 17.6332V6.89979C19.2001 6.05295 18.4837 5.36646 17.6001 5.36646Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M15.2001 3.83325V6.89993",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M8.80005 3.83325V6.89993",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M4.80005 9.96655H19.2001",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function l(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M.938 3.37a.75.75 0 0 1 .75-.75h1.527a2.75 2.75 0 0 1 2.674 2.108l.017.072H19.31c2.57 0 4.378 2.526 3.55 4.959l-1.966 5.777a1.75 1.75 0 0 1-1.657 1.186H8.602a1.75 1.75 0 0 1-1.701-1.342L4.43 5.078a1.25 1.25 0 0 0-1.216-.959H1.688a.75.75 0 0 1-.75-.75ZM6.266 6.3l2.093 8.73a.25.25 0 0 0 .243.192h10.635a.25.25 0 0 0 .237-.17l1.966-5.776A2.25 2.25 0 0 0 19.31 6.3H6.266Z",
            fill: "#003878"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M10.49 20.13a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM17.964 21.38a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z",
            fill: "#003878"
          }
        )
      ]
    }
  );
}
function d(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ t("g", { clipPath: "url(#a)", fill: "#636362", children: [
          /* @__PURE__ */ e(
            "path",
            {
              fillRule: "evenodd",
              clipRule: "evenodd",
              d: "M0 3.5h3.049a.5.5 0 0 1 .484.376l3.442 13.495A1.5 1.5 0 0 0 8.428 18.5h11.547a1.5 1.5 0 0 0 1.46-1.158L23.7 7.675a1.5 1.5 0 0 0-1.46-1.842H5.064L4.502 3.63A1.5 1.5 0 0 0 3.05 2.5H0v1Zm7.944 13.624L5.319 6.834h16.92a.5.5 0 0 1 .486.613l-2.264 9.667a.5.5 0 0 1-.486.386H8.428a.5.5 0 0 1-.484-.376Z"
            }
          ),
          /* @__PURE__ */ e("path", { d: "M11.328 21.816a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM18.681 21.816a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" })
        ] }),
        /* @__PURE__ */ e("defs", { children: /* @__PURE__ */ e("clipPath", { id: "a", children: /* @__PURE__ */ e("path", { fill: "#fff", d: "M0 0h24v24H0z" }) }) })
      ]
    }
  );
}
function h(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e("path", { d: "M17.657 19.0717C18.0476 19.4623 18.6807 19.4623 19.0713 19.0717C19.4618 18.6812 19.4618 18.0481 19.0713 17.6575L13.4144 12.0007L19.0713 6.34383C19.4618 5.9533 19.4618 5.32014 19.0713 4.92961C18.6808 4.53909 18.0476 4.53909 17.6571 4.92961L12.0002 10.5865L6.34334 4.92961C5.95281 4.53909 5.31965 4.53909 4.92912 4.92961C4.5386 5.32014 4.5386 5.9533 4.92912 6.34383L10.586 12.0007L4.92917 17.6575C4.53865 18.0481 4.53864 18.6812 4.92917 19.0717C5.31969 19.4623 5.95286 19.4623 6.34338 19.0717L12.0002 13.4149L17.657 19.0717Z" })
    }
  );
}
function C(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("rect", { x: "4.8", y: "3.8", width: "14.4", height: "15.4", rx: "1.2" }),
        /* @__PURE__ */ e("path", { d: "M7 15L9 13L11 14L14 11L15 10L17 12", strokeLinecap: "round" })
      ]
    }
  );
}
function a(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.6693 6.32534C16.1641 4.82696 14.1641 4 12.0339 4C7.64323 4 4.06771 7.55671 4.06771 11.9274C4.0651 13.3247 4.43229 14.6883 5.13021 15.8911L4 20L8.22396 18.8957C9.38542 19.5282 10.6979 19.86 12.0312 19.8626H12.0339C16.4245 19.8626 19.9974 16.3059 20 11.9326C20 9.81465 19.1719 7.82372 17.6693 6.32534ZM12.0339 18.5224H12.0312C10.8438 18.5224 9.67708 18.2035 8.66146 17.6047L8.41927 17.4621L5.91146 18.1154L6.58073 15.6837L6.42448 15.4349C5.76042 14.385 5.41146 13.1717 5.41146 11.9274C5.41146 8.29553 8.38281 5.34025 12.0365 5.34025C13.8047 5.34025 15.4661 6.02722 16.7161 7.27155C17.9661 8.51847 18.6536 10.1724 18.6536 11.9326C18.6536 15.5671 15.6823 18.5224 12.0339 18.5224Z"
        }
      )
    }
  );
}
function s(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "23",
      viewBox: "0 0 24 23",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M12 19.1666C16.4183 19.1666 20 15.7341 20 11.4999C20 7.26574 16.4183 3.83325 12 3.83325C7.58172 3.83325 4 7.26574 4 11.4999C4 15.7341 7.58172 19.1666 12 19.1666Z",
            fill: "white",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M12 7.66675V12.2667L15.2 13.8001" })
      ]
    }
  );
}
function c(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25ZM12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75Z"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M10.9788 16.9964C10.7274 17.0187 10.4783 16.9363 10.298 16.7714L6.2572 13.0754C5.91911 12.7661 5.91362 12.2596 6.24496 11.9441C6.57629 11.6285 7.11898 11.6234 7.45707 11.9327L10.7726 14.9653L16.4386 7.344C16.7084 6.98101 17.2425 6.89092 17.6314 7.1428C18.0204 7.39468 18.1169 7.89313 17.847 8.25613L11.6021 16.6561C11.4582 16.8497 11.2301 16.9742 10.9788 16.9964Z"
          }
        )
      ]
    }
  );
}
function u(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M17.8 6H6.6C5.71635 6 5 6.49746 5 7.11111V14.8889C5 15.5025 5.71635 16 6.6 16H17.8C18.6837 16 19.4 15.5025 19.4 14.8889V7.11111C19.4 6.49746 18.6837 6 17.8 6Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M5 9.3335H19.4", strokeLinecap: "round", strokeLinejoin: "round" })
      ]
    }
  );
}
function w(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M5 7H6.55556H19", strokeLinecap: "round", strokeLinejoin: "round" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M18 7V17.5C18 17.8978 17.8194 18.2794 17.4979 18.5607C17.1764 18.842 16.7404 19 16.2857 19H7.71429C7.25963 19 6.82359 18.842 6.5021 18.5607C6.18061 18.2794 6 17.8978 6 17.5V7M8.57143 7V5.5C8.57143 5.10218 8.75204 4.72064 9.07353 4.43934C9.39502 4.15804 9.83106 4 10.2857 4H13.7143C14.1689 4 14.605 4.15804 14.9265 4.43934C15.248 4.72064 15.4286 5.10218 15.4286 5.5V7",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M10 11V16", strokeLinecap: "round", strokeLinejoin: "round" }),
        /* @__PURE__ */ e("path", { d: "M14 11V16", strokeLinecap: "round", strokeLinejoin: "round" })
      ]
    }
  );
}
function p(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "25",
      height: "24",
      viewBox: "0 0 25 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M21.0435 13V17C21.0435 17.5304 20.8327 18.0391 20.4577 18.4142C20.0826 18.7893 19.5739 19 19.0435 19H5.04346C4.51302 19 4.00432 18.7893 3.62924 18.4142C3.25417 18.0391 3.04346 17.5304 3.04346 17V13",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M7.04346 10L12.0435 15L17.0435 10",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M12.0435 15V3", strokeLinecap: "round", strokeLinejoin: "round" })
      ]
    }
  );
}
function L(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "25",
      viewBox: "0 0 24 25",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M15.0551 3.68388C16.1291 2.60993 17.8703 2.60993 18.9442 3.68387L21.3518 6.09141C22.4257 7.16535 22.4257 8.90655 21.3518 9.98049L11.2119 20.1203C10.9721 20.3601 10.6678 20.5251 10.336 20.5952L5.49148 21.6179C4.25394 21.8791 3.15648 20.7817 3.41774 19.5441L4.44048 14.6997C4.51053 14.3678 4.67549 14.0635 4.9153 13.8237L15.0551 3.68388ZM17.8836 4.74453C17.3954 4.25638 16.6039 4.25638 16.1158 4.74453L15.2964 5.56397L19.3962 9.81468L20.2911 8.91983C20.7792 8.43168 20.7792 7.64022 20.2911 7.15207L17.8836 4.74453ZM6.06031 14.8L14.2355 6.6248L18.3354 10.8755L10.2356 18.9753L6.06031 14.8ZM5.6014 16.4624L4.88539 19.854C4.84807 20.0308 5.00485 20.1876 5.18164 20.1502L8.57319 19.4342L5.6014 16.4624Z",
          fill: "currentColor"
        }
      )
    }
  );
}
function g(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M14.6666 4H20V9.33333" }),
        /* @__PURE__ */ e("path", { d: "M9.33333 20.0001H4V14.6667" }),
        /* @__PURE__ */ e("path", { d: "M20.0001 4L13.7778 10.2222" }),
        /* @__PURE__ */ e("path", { d: "M4 20.0001L10.2222 13.7778" })
      ]
    }
  );
}
function f(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M3 12C3 12 6.27273 5.45459 12 5.45459C17.7273 5.45459 21 12 21 12C21 12 17.7273 18.5455 12 18.5455C6.27273 18.5455 3 12 3 12Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M12 14.4545C13.3556 14.4545 14.4545 13.3556 14.4545 12C14.4545 10.6443 13.3556 9.54541 12 9.54541C10.6443 9.54541 9.54541 10.6443 9.54541 12C9.54541 13.3556 10.6443 14.4545 12 14.4545Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function v(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("rect", { width: "24", height: "23" }),
        /* @__PURE__ */ e("path", { d: "M6.8 10.8H17.2V17.5C17.2 18.1627 16.6627 18.7 16 18.7H8C7.33726 18.7 6.8 18.1627 6.8 17.5V10.8Z" }),
        /* @__PURE__ */ e("path", { d: "M6 7.3H18C18.6627 7.3 19.2 7.83726 19.2 8.5V9.5C19.2 10.1627 18.6627 10.7 18 10.7H6C5.33726 10.7 4.8 10.1627 4.8 9.5V8.5C4.8 7.83726 5.33726 7.3 6 7.3Z" }),
        /* @__PURE__ */ e("path", { d: "M11.9964 5.72727C11.9964 5.87118 11.9975 5.99992 11.9985 6.11789C12.0003 6.33562 12.0019 6.51668 11.9956 6.68875C11.9861 6.95135 11.9592 7.07179 11.9369 7.12645C11.9357 7.12945 11.935 7.1314 11.9348 7.13264C11.9341 7.13259 11.933 7.13286 11.9312 7.13372C11.8844 7.15588 11.7435 7.2 11.4126 7.2C10.7479 7.2 9.69449 7.02694 8.82698 6.68438C8.3937 6.51329 8.05761 6.3195 7.84304 6.12504C7.63293 5.93463 7.60005 5.80364 7.60005 5.72727C7.60005 4.67388 8.46985 3.8 9.56759 3.8C10.1328 3.8 10.7668 4.03211 11.255 4.42318C11.7447 4.81544 11.9964 5.28774 11.9964 5.72727Z" }),
        /* @__PURE__ */ e("path", { d: "M12.0036 5.72727C12.0036 5.87118 12.0025 5.99992 12.0015 6.11789C11.9997 6.33562 11.9981 6.51668 12.0044 6.68875C12.0139 6.95135 12.0408 7.07179 12.0631 7.12645C12.0643 7.12945 12.065 7.1314 12.0652 7.13264C12.0659 7.13259 12.067 7.13286 12.0688 7.13372C12.1156 7.15588 12.2565 7.2 12.5874 7.2C13.2521 7.2 14.3055 7.02694 15.173 6.68438C15.6063 6.51329 15.9424 6.3195 16.157 6.12504C16.3671 5.93463 16.4 5.80364 16.4 5.72727C16.4 4.67388 15.5301 3.8 14.4324 3.8C13.8672 3.8 13.2332 4.03211 12.745 4.42318C12.2553 4.81544 12.0036 5.28774 12.0036 5.72727Z" }),
        /* @__PURE__ */ e("path", { d: "M12 18.5V10.5" }),
        /* @__PURE__ */ e("path", { d: "M12 10.5V5.5" })
      ]
    }
  );
}
function M(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M4.80005 9.19992L12 3.83325L19.2 9.19992V17.6333C19.2 18.0399 19.0315 18.4299 18.7314 18.7175C18.4314 19.005 18.0244 19.1666 17.6 19.1666H6.40005C5.9757 19.1666 5.56874 19.005 5.26868 18.7175C4.96862 18.4299 4.80005 18.0399 4.80005 17.6333V9.19992Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M9.6001 19.1667V11.5H14.4001V19.1667",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function k(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M12 4V7.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M12 16.7998V19.9998",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M6.34399 6.34399L8.60799 8.60799",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M15.3921 15.3918L17.6561 17.6558",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M4 12H7.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M16.7999 12H19.9999",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M6.34399 17.6558L8.60799 15.3918",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M15.3921 8.60799L17.6561 6.34399",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function Z(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e("path", { d: "M4 11.5789L20 4L12.4211 20L10.7368 13.2632L4 11.5789Z" })
    }
  );
}
function H(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M17.644 6.4c0 3.535-2.83 6.4-6.322 6.4S4.998 9.935 4.998 6.4 7.83 0 11.322 0c3.491 0 6.322 2.865 6.322 6.4Zm-1.5 0c0 2.724-2.176 4.9-4.822 4.9-2.647 0-4.823-2.176-4.823-4.9s2.176-4.9 4.823-4.9c2.646 0 4.822 2.176 4.822 4.9Z",
            fill: "#003878"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M2.518 23.26c.366-3.657 3.416-6.51 7.125-6.51h3.951c3.71 0 6.76 2.854 7.126 6.51.04.407.368.74.777.74.41 0 .745-.333.71-.74-.37-4.487-4.085-8.01-8.613-8.01H9.643c-4.527 0-8.242 3.523-8.613 8.01-.034.407.3.74.71.74s.737-.333.778-.74Z",
            fill: "#003878"
          }
        )
      ]
    }
  );
}
function x(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "22",
      height: "23",
      fill: "none",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M17.217 6.23c0 3.44-2.783 6.228-6.217 6.228-3.434 0-6.217-2.789-6.217-6.229S7.566 0 11 0c3.434 0 6.217 2.789 6.217 6.23Zm-.956 0A5.266 5.266 0 0 1 11 11.5a5.266 5.266 0 0 1-5.26-5.27A5.266 5.266 0 0 1 11 .957c2.905 0 5.26 2.36 5.26 5.271Z",
            fill: "#595857"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M.957 23c0-3.97 3.211-7.188 7.173-7.188h5.74c3.962 0 7.174 3.219 7.174 7.188H22c0-4.499-3.64-8.146-8.13-8.146H8.13C3.64 14.854 0 18.501 0 23h.957Z",
            fill: "#595857"
          }
        )
      ]
    }
  );
}
function V(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M10 19H6.66667C6.22464 19 5.80072 18.8361 5.48816 18.5444C5.17559 18.2527 5 17.857 5 17.4444V6.55556C5 6.143 5.17559 5.74733 5.48816 5.45561C5.80072 5.16389 6.22464 5 6.66667 5H10",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M15 16L19 12L15 8",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M19 12H10", strokeLinecap: "round", strokeLinejoin: "round" })
      ]
    }
  );
}
function m(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M11.8 4H6.4C5.07452 4 4 5.07452 4 6.4V17.6C4 18.9255 5.07452 20 6.4 20H17.6C18.9255 20 20 18.9255 20 17.6V12.2H18.4V17.6C18.4 18.0419 18.0419 18.4 17.6 18.4H6.4C5.95817 18.4 5.6 18.0419 5.6 17.6V6.4C5.6 5.95817 5.95817 5.6 6.4 5.6H11.8V4Z"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M13.7891 4.80005H19.1224V10.1334" }),
        /* @__PURE__ */ e("path", { d: "M19.1224 4.80005L12.9001 11.0223" })
      ]
    }
  );
}
function R(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M13.0928 3.98205L19.0379 6.83074C19.2855 6.94866 19.4939 7.13044 19.6396 7.35564C19.7853 7.58085 19.8626 7.84054 19.8628 8.10553V14.8925C19.8626 15.1575 19.7853 15.4172 19.6396 15.6424C19.4939 15.8676 19.2855 16.0494 19.0379 16.1673L13.0928 19.016C12.8863 19.115 12.6586 19.1666 12.4277 19.1666C12.1968 19.1666 11.9691 19.115 11.7626 19.016L5.81749 16.1673C5.57012 16.0479 5.3625 15.8646 5.21806 15.638C5.07362 15.4115 4.99811 15.1509 5.00004 14.8854V8.10553C5.00018 7.84054 5.07746 7.58085 5.22318 7.35564C5.36891 7.13044 5.57729 6.94866 5.82492 6.83074L11.77 3.98205C11.9755 3.88418 12.2019 3.83325 12.4314 3.83325C12.6609 3.83325 12.8873 3.88418 13.0928 3.98205V3.98205Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M5.23779 7.33643L12.4314 10.7833L19.6249 7.33643",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M12.4314 19.1583V10.7832",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M8.7157 5.44189L16.1471 9.00276",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function j(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e("path", { d: "M15.5556 4L20 8.44444L8.44444 20H4V15.5556L15.5556 4Z" })
    }
  );
}
function B(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M12.987 9.29834C10.69 9.29834 8.82153 11.167 8.82153 13.4638C8.82153 15.7606 10.6902 17.6292 12.987 17.6292C15.2837 17.6292 17.1524 15.7606 17.1524 13.4638C17.1524 11.167 15.2837 9.29834 12.987 9.29834ZM12.987 16.2867C11.4304 16.2867 10.164 15.0203 10.164 13.4638C10.164 11.9073 11.4305 10.6408 12.987 10.6408C14.5435 10.6408 15.8099 11.9073 15.8099 13.4638C15.8099 15.0202 14.5436 16.2867 12.987 16.2867Z" }),
        /* @__PURE__ */ e("path", { d: "M19.8562 7.05027H13.3226H12.9869V5.53919C12.9869 4.69054 12.2967 4 11.448 4H7.99767C7.14903 4 6.45876 4.69045 6.45876 5.53919V7.05036H6.11751C4.95002 7.05027 4 8.00011 4 9.1676V17.8831C4 19.0504 4.95002 20 6.11751 20H19.8559C21.0237 20 21.9732 19.0504 21.9732 17.8831V9.1676C21.9735 8.00011 21.0237 7.05027 19.8562 7.05027ZM7.80098 5.53919C7.80098 5.43068 7.88916 5.34259 7.99767 5.34259H11.448C11.5563 5.34259 11.6444 5.43077 11.6444 5.53928V7.05045H7.80098V5.53919ZM19.8562 18.6575H6.11751C5.69016 18.6575 5.3425 18.31 5.3425 17.8831V9.1676C5.3425 8.74025 5.69016 8.39277 6.11751 8.39277H6.61H7.80098H11.6443H13.3223H19.8559C20.2832 8.39277 20.6307 8.74025 20.6307 9.1676V17.8831H20.631C20.631 18.31 20.2835 18.6575 19.8562 18.6575Z" }),
        /* @__PURE__ */ e("path", { d: "M19.8113 9.50195H18.0957V10.8444H19.8113V9.50195Z" })
      ]
    }
  );
}
function S(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M12 19.1668C16.4183 19.1668 20 15.7343 20 11.5002C20 7.26598 16.4183 3.8335 12 3.8335C7.58172 3.8335 4 7.26598 4 11.5002C4 15.7343 7.58172 19.1668 12 19.1668Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M10.4001 8.43359L15.2001 11.5003L10.4001 14.5669V8.43359Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function A(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M6 3.95674H14.9648V16.8431C14.9648 17.5058 14.4275 18.0431 13.7648 18.0431H6C5.33726 18.0431 4.8 17.5058 4.8 16.8431V5.15674C4.8 4.494 5.33726 3.95674 6 3.95674Z" }),
        /* @__PURE__ */ e("path", { d: "M15.3098 3.95674H18C18.6627 3.95674 19.2 4.494 19.2 5.15674V6.64696C19.2 7.3097 18.6627 7.84696 18 7.84696H15.3098V3.95674Z" }),
        /* @__PURE__ */ e("path", { d: "M7.13721 7.07837H9.88232", strokeLinecap: "round" }),
        /* @__PURE__ */ e("path", { d: "M7.13721 11H12.6274", strokeLinecap: "round" }),
        /* @__PURE__ */ e("path", { d: "M7.13721 14.9214H11.8431", strokeLinecap: "round" })
      ]
    }
  );
}
function U(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M5.5 7.67993H6.94H18.46" }),
        /* @__PURE__ */ e("path", { d: "M9.09994 7.68005V6.24005C9.09994 5.85814 9.25166 5.49187 9.52171 5.22181C9.79176 4.95176 10.158 4.80005 10.5399 4.80005H13.4199C13.8019 4.80005 14.1681 4.95176 14.4382 5.22181C14.7082 5.49187 14.8599 5.85814 14.8599 6.24005V7.68005M17.0199 7.68005V17.76C17.0199 18.142 16.8682 18.5082 16.5982 18.7783C16.3281 19.0483 15.9619 19.2 15.5799 19.2H8.37994C7.99803 19.2 7.63176 19.0483 7.36171 18.7783C7.09166 18.5082 6.93994 18.142 6.93994 17.76V7.68005H17.0199Z" }),
        /* @__PURE__ */ e("path", { d: "M10.54 11.28V15.6" }),
        /* @__PURE__ */ e("path", { d: "M13.4199 11.28V15.6" })
      ]
    }
  );
}
function G(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M4 4.85547V10.0373H9.15454",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M6.15632 14.3556C6.71335 15.945 7.76912 17.3094 9.16456 18.2431C10.56 19.1769 12.2195 19.6295 13.893 19.5326C15.5666 19.4358 17.1635 18.7948 18.4431 17.7063C19.7228 16.6177 20.6159 15.1406 20.9879 13.4974C21.3598 11.8543 21.1905 10.1341 20.5054 8.59607C19.8203 7.05806 18.6565 5.78554 17.1895 4.97024C15.7224 4.15494 14.0315 3.84103 12.3716 4.07582C10.7116 4.3106 9.17251 5.08135 7.98618 6.27194L4 10.0374",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function P(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "#000",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e("path", { d: "M8.3792 2.75C8.79342 2.74974 9.12899 2.41375 9.12873 1.99953C9.12848 1.58532 8.79248 1.24974 8.37827 1.25L6.6975 1.25105C4.45152 1.25362 2.63159 3.0746 2.63159 5.32095V7.2779C2.63159 7.69212 2.96738 8.0279 3.38159 8.0279C3.79581 8.0279 4.13159 7.69212 4.13159 7.2779V5.32095C4.13159 3.90287 5.28079 2.75288 6.69921 2.75105L8.3792 2.75Z" }),
        /* @__PURE__ */ e("path", { d: "M15.0313 2C15.0313 1.58579 15.3671 1.25 15.7813 1.25H17.425C19.6684 1.25 21.5002 3.08179 21.5002 5.32514V7.2779C21.5002 7.69212 21.1644 8.0279 20.7502 8.0279C20.336 8.0279 20.0002 7.69212 20.0002 7.2779V5.32514C20.0002 3.91021 18.84 2.75 17.425 2.75H15.7813C15.3671 2.75 15.0313 2.41421 15.0313 2Z" }),
        /* @__PURE__ */ e("path", { d: "M9.7533 8.57536L9.7533 14.5451C9.7533 14.9593 9.41751 15.2951 9.0033 15.2951C8.58908 15.2951 8.2533 14.9593 8.2533 14.5451L8.2533 8.57536C8.2533 8.16114 8.58908 7.82536 9.0033 7.82536C9.41751 7.82536 9.7533 8.16114 9.7533 8.57536Z" }),
        /* @__PURE__ */ e("path", { d: "M12.8159 8.57536V14.5451C12.8159 14.9593 12.4801 15.2951 12.0659 15.2951C11.6517 15.2951 11.3159 14.9593 11.3159 14.5451V8.57536C11.3159 8.16114 11.6517 7.82536 12.0659 7.82536C12.4801 7.82536 12.8159 8.16114 12.8159 8.57536Z" }),
        /* @__PURE__ */ e("path", { d: "M15.8785 14.5451V8.57536C15.8785 8.16114 15.5428 7.82536 15.1285 7.82536C14.7143 7.82536 14.3785 8.16114 14.3785 8.57536V14.5451C14.3785 14.9593 14.7143 15.2951 15.1285 15.2951C15.5428 15.2951 15.8785 14.9593 15.8785 14.5451Z" }),
        /* @__PURE__ */ e("path", { d: "M9.10046 21.25C9.10046 21.6642 8.76468 22 8.35046 22H6.70675C4.46339 22 2.63161 20.1682 2.63161 17.9249L2.63161 15.9721C2.63161 15.5579 2.96739 15.2221 3.38161 15.2221C3.79582 15.2221 4.13161 15.5579 4.13161 15.9721L4.13161 17.9249C4.13161 19.3398 5.29182 20.5 6.70675 20.5H8.35046C8.76468 20.5 9.10046 20.8358 9.10046 21.25Z" }),
        /* @__PURE__ */ e("path", { d: "M15.7525 20.5C15.3383 20.5003 15.0027 20.8363 15.003 21.2505C15.0032 21.6647 15.3392 22.0003 15.7534 22L17.4342 21.999C19.6802 21.9964 21.5001 20.1754 21.5001 17.929V15.9721C21.5001 15.5579 21.1643 15.2221 20.7501 15.2221C20.3359 15.2221 20.0001 15.5579 20.0001 15.9721V17.929C20.0001 19.3471 18.8509 20.4971 17.4325 20.499L15.7525 20.5Z" })
      ]
    }
  );
}
function D(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L20.2929 21.7071L21.7071 20.2929L18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2Z"
        }
      )
    }
  );
}
function E(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e("path", { d: "M19.7091 10.8545L19.696 16.5465C19.6685 16.6865 18.9884 19.668 13.6925 20.242C13.4844 20.6884 13.0347 21 12.5091 21H11.8545C11.1313 21 10.5455 20.4135 10.5455 19.6909C10.5455 18.9683 11.1313 18.3818 11.8545 18.3818H12.5091C12.9542 18.3818 13.3456 18.605 13.5825 18.9441C17.5092 18.5095 18.2979 16.6394 18.4 16.3324V10.8545C18.4 7.42604 15.2831 4.96364 11.8545 4.96364C8.42604 4.96364 5.30909 7.42604 5.30909 10.8545V14.7818C5.30909 14.9618 5.16182 15.1091 4.98182 15.1091H4.32727C4.14727 15.1091 4 14.9618 4 14.7818V10.8545C4 6.70407 7.70407 3 11.8545 3C16.005 3 19.7091 6.70407 19.7091 10.8545ZM7.84415 12.4909H6.1888C6.00815 12.4909 5.96364 12.648 5.96364 12.8287V18.0545C5.96364 18.2345 6.00815 18.3818 6.1888 18.3818H7.84415C8.02415 18.3818 8.17142 18.2345 8.17142 18.0545C8.17142 18.0545 8.49869 17.0727 8.49869 15.4364C8.49869 13.8 8.17142 12.8234 8.17142 12.8234C8.17142 12.6428 8.02415 12.4909 7.84415 12.4909ZM17.7455 15.8252V12.8287C17.7455 12.648 17.7009 12.4909 17.5203 12.4909H15.8649C15.6849 12.4909 15.5377 12.6428 15.5377 12.8234C15.5377 12.8234 15.2104 13.7974 15.2104 15.4337C15.2104 16.0039 15.3177 16.788 15.3884 17.3496C16.5692 17.0217 17.2441 16.4287 17.7455 15.8252Z" })
    }
  );
}
function K(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M4.04346 9V17C4.04346 17.5304 4.25417 18.0391 4.62924 18.4142C5.00432 18.7893 5.51302 19 6.04346 19H18.0435C18.5739 19 19.0826 18.7893 19.4577 18.4142C19.8327 18.0391 20.0435 17.5304 20.0435 17V9",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M16.0435 7L12.0435 3L8.04346 7",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M12.0435 3L12 15", strokeLinecap: "round", strokeLinejoin: "round" })
      ]
    }
  );
}
function y(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "23",
      viewBox: "0 0 24 23",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e("path", { d: "M12 5.53821L13.7985 9.20979L13.9721 9.56433L14.3627 9.62186L18.3974 10.2161L15.4746 13.0849L15.1949 13.3595L15.2606 13.746L15.949 17.7905L12.3512 15.8838L12 15.6977L11.6488 15.8838L8.051 17.7905L8.73937 13.746L8.80515 13.3595L8.52536 13.0849L5.60258 10.2161L9.63729 9.62186L10.0279 9.56433L10.2015 9.20979L12 5.53821Z" })
    }
  );
}
function O(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e(
        "path",
        {
          d: "M13.8512 7.30546C13.6969 7.45628 13.6105 7.65904 13.6105 7.87022C13.6105 8.08141 13.6969 8.28417 13.8512 8.43499L15.1982 9.72588C15.3556 9.87371 15.5671 9.95651 15.7875 9.95651C16.0079 9.95651 16.2195 9.87371 16.3768 9.72588L19.5507 6.68422C19.9741 7.58073 20.1022 8.5796 19.9182 9.54769C19.7341 10.5158 19.2466 11.4071 18.5205 12.103C17.7944 12.7988 16.8643 13.266 15.8541 13.4424C14.8439 13.6188 13.8017 13.496 12.8662 13.0903L7.04874 18.6653C6.71381 18.9863 6.25956 19.1666 5.78591 19.1666C5.31226 19.1666 4.858 18.9863 4.52308 18.6653C4.18816 18.3443 4 17.909 4 17.4551C4 17.0012 4.18816 16.5658 4.52308 16.2449L10.3405 10.6698C9.91718 9.77333 9.789 8.77447 9.97306 7.80637C10.1571 6.83827 10.6447 5.94692 11.3707 5.2511C12.0968 4.55529 13.0269 4.08805 14.0371 3.91166C15.0473 3.73527 16.0896 3.85811 17.0251 4.2638L13.8596 7.29739L13.8512 7.30546Z",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      )
    }
  );
}
function W(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            d: "M14.9091 5.2229H4V14.2835H14.9091V5.2229Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M14.9089 8.70776H17.818L19.9998 10.7987V14.2835H14.9089V8.70776Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M7.27253 17.7685C8.27668 17.7685 9.09071 16.9884 9.09071 16.0261C9.09071 15.0638 8.27668 14.2837 7.27253 14.2837C6.26837 14.2837 5.45435 15.0638 5.45435 16.0261C5.45435 16.9884 6.26837 17.7685 7.27253 17.7685Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M16.7271 17.7685C17.7313 17.7685 18.5453 16.9884 18.5453 16.0261C18.5453 15.0638 17.7313 14.2837 16.7271 14.2837C15.723 14.2837 14.9089 15.0638 14.9089 16.0261C14.9089 16.9884 15.723 17.7685 16.7271 17.7685Z",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      ]
    }
  );
}
function z(n) {
  return /* @__PURE__ */ e(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.6693 6.32534C16.1641 4.82696 14.1641 4 12.0339 4C7.64323 4 4.06771 7.55671 4.06771 11.9274C4.0651 13.3247 4.43229 14.6883 5.13021 15.8911L4 20L8.22396 18.8957C9.38542 19.5282 10.6979 19.86 12.0312 19.8626H12.0339C16.4245 19.8626 19.9974 16.3059 20 11.9326C20 9.81465 19.1719 7.82372 17.6693 6.32534ZM12.0339 18.5224H12.0312C10.8438 18.5224 9.67708 18.2035 8.66146 17.6047L8.41927 17.4621L5.91146 18.1154L6.58073 15.6837L6.42448 15.4349C5.76042 14.385 5.41146 13.1717 5.41146 11.9274C5.41146 8.29553 8.38281 5.34025 12.0365 5.34025C13.8047 5.34025 15.4661 6.02722 16.7161 7.27155C17.9661 8.51847 18.6536 10.1724 18.6536 11.9326C18.6536 15.5671 15.6823 18.5224 12.0339 18.5224ZM15.6641 13.5865C15.4661 13.488 14.487 13.0084 14.3047 12.9436C14.1224 12.8762 13.9896 12.8451 13.8568 13.0421C13.724 13.2417 13.3437 13.6876 13.2266 13.8198C13.112 13.9494 12.9948 13.9676 12.7969 13.8691C12.5964 13.7706 11.9557 13.5606 11.1953 12.884C10.6042 12.3603 10.2031 11.7097 10.0885 11.5126C9.97135 11.313 10.0755 11.2067 10.1745 11.1082C10.2656 11.0201 10.375 10.8775 10.474 10.7609C10.5729 10.6468 10.6068 10.5638 10.6745 10.4316C10.7396 10.2994 10.7057 10.1828 10.6562 10.0843C10.6068 9.98574 10.2083 9.00842 10.0417 8.61179C9.88021 8.22553 9.71615 8.27997 9.59375 8.2722C9.47917 8.26701 9.34635 8.26701 9.21354 8.26701C9.08073 8.26701 8.86458 8.31627 8.68229 8.51588C8.5 8.7129 7.98698 9.19248 7.98698 10.1672C7.98698 11.1419 8.70052 12.0855 8.79948 12.2178C8.89844 12.3474 10.2031 14.3487 12.2005 15.2067C12.6745 15.4115 13.0443 15.5334 13.3333 15.6241C13.8099 15.7745 14.2448 15.7537 14.5885 15.7045C14.9714 15.6474 15.7656 15.2249 15.9323 14.7609C16.0964 14.2994 16.0964 13.9028 16.0469 13.8198C15.9974 13.7369 15.8646 13.6876 15.6641 13.5865Z"
        }
      )
    }
  );
}
function I() {
  return /* @__PURE__ */ e("svg", { width: "24", height: "24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ e(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.939 5.785A3.88 3.88 0 0 0 13.71 7.17l-1.53 2.55-1.476-2.46a3.988 3.988 0 0 0-7.247.927l-.137.466a5.182 5.182 0 0 0 1.654 5.443l7.197 5.997 7.11-6.128a5.196 5.196 0 0 0 1.665-5.125l-.133-.562a3.88 3.88 0 0 0-1.874-2.493Zm-6.515.613a5.38 5.38 0 0 1 9.85 1.536l.132.562a6.696 6.696 0 0 1-2.146 6.605l-7.326 6.315a1.163 1.163 0 0 1-1.503.013l-7.417-6.18a6.682 6.682 0 0 1-2.133-7.02l.137-.465A5.488 5.488 0 0 1 11.99 6.49l.19.316.244-.407Z",
      fill: "#003878"
    }
  ) });
}
function T() {
  return /* @__PURE__ */ e("svg", { width: "24", height: "24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ e(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.155 4.25a4.282 4.282 0 0 0-4.152 3.234l-.106.418a4.836 4.836 0 0 0 .167 2.898l.18.475a6.453 6.453 0 0 0 1.928 2.692l6.83 5.635 6.829-5.635a6.453 6.453 0 0 0 1.928-2.692l.18-.475c.351-.926.41-1.938.167-2.898L21 7.484a4.282 4.282 0 0 0-7.974-.882L12.002 8.63 10.976 6.6A4.282 4.282 0 0 0 7.155 4.25ZM2.033 7.239A5.282 5.282 0 0 1 11.87 6.15l.133.261.132-.261a5.282 5.282 0 0 1 9.836 1.088l.105.418a5.836 5.836 0 0 1-.2 3.497l-.18.476a7.453 7.453 0 0 1-2.228 3.109l-7.465 6.16-7.466-6.16a7.453 7.453 0 0 1-2.227-3.11l-.18-.475a5.836 5.836 0 0 1-.201-3.497l.105-.418Z",
      fill: "#636362"
    }
  ) });
}
function N(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "30",
      height: "28",
      fill: "none",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M11.7 11.8c2.647 0 4.823-2.176 4.823-4.9S14.347 2 11.7 2C9.054 2 6.878 4.176 6.878 6.9s2.176 4.9 4.822 4.9Zm0 1.5c3.492 0 6.323-2.865 6.323-6.4S15.193.5 11.7.5C8.21.5 5.378 3.365 5.378 6.9s2.83 6.4 6.322 6.4ZM10.022 16.25c-3.709 0-6.76 2.854-7.125 6.51-.041.407-.369.74-.778.74-.41 0-.744-.333-.71-.74.371-4.487 4.086-8.01 8.613-8.01h3.951c4.528 0 8.242 3.523 8.614 8.01.034.407-.301.74-.71.74-.41 0-.738-.333-.778-.74-.366-3.657-3.417-6.51-7.126-6.51h-3.951Z",
            fill: "#003878"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M30 19a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z", fill: "#fff" }),
        /* @__PURE__ */ e("path", { d: "M29 19a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fill: "#6BBD51" }),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M20.545 22.497a.76.76 0 0 1-.61-.222l-2.704-2.69a.8.8 0 0 1-.011-1.112.757.757 0 0 1 1.088-.011L20.36 20.5l3.239-4.662a.759.759 0 0 1 1.07-.198c.35.248.436.738.194 1.095l-3.758 5.427a.767.767 0 0 1-.56.335Z",
            fill: "#fff"
          }
        )
      ]
    }
  );
}
function b(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "28",
      height: "28",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M17.217 7.23c0 3.44-2.783 6.228-6.217 6.228-3.434 0-6.217-2.789-6.217-6.229S7.566 1 11 1c3.434 0 6.217 2.789 6.217 6.23Zm-.956 0A5.266 5.266 0 0 1 11 12.5a5.266 5.266 0 0 1-5.26-5.27A5.266 5.266 0 0 1 11 1.957c2.905 0 5.26 2.36 5.26 5.271Z",
            fill: "#595857"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M.957 24c0-3.97 3.211-7.188 7.173-7.188h5.74c3.962 0 7.174 3.218 7.174 7.188H22c0-4.499-3.64-8.146-8.13-8.146H8.13C3.64 15.854 0 19.501 0 24h.957Z",
            fill: "#595857"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M28 20a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fill: "#fff" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M27.113 20a7.111 7.111 0 1 1-14.222 0 7.111 7.111 0 0 1 14.222 0Z",
            fill: "#008933"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M20.002 27.111a7.111 7.111 0 1 0 0-14.222 7.111 7.111 0 0 0 0 14.222Z",
            fill: "#008933"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "m23.855 17.16-4.29 5.665-2.897-2.897.61-.61 2.167 2.166 3.7-4.818.71.494Z",
            fill: "#fff"
          }
        )
      ]
    }
  );
}
function q(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "30",
      height: "28",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M11.7 11.8c2.647 0 4.823-2.176 4.823-4.9S14.347 2 11.7 2C9.054 2 6.878 4.176 6.878 6.9s2.176 4.9 4.822 4.9Zm0 1.5c3.492 0 6.323-2.865 6.323-6.4S15.193.5 11.7.5C8.21.5 5.378 3.365 5.378 6.9s2.83 6.4 6.322 6.4ZM10.022 16.25c-3.709 0-6.76 2.854-7.125 6.51-.041.407-.369.74-.778.74-.41 0-.744-.333-.71-.74.371-4.487 4.086-8.01 8.613-8.01h3.951c4.528 0 8.242 3.523 8.614 8.01.034.407-.301.74-.71.74-.41 0-.738-.333-.778-.74-.366-3.657-3.417-6.51-7.126-6.51h-3.951Z",
            fill: "#003878"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M30 19a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z", fill: "#fff" }),
        /* @__PURE__ */ e("path", { d: "M29 19a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fill: "#E9562E" }),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M23.183 15.726a.772.772 0 0 1 1.091 1.091L22.091 19l2.183 2.183a.772.772 0 0 1-1.091 1.091L21 20.091l-2.183 2.183a.772.772 0 0 1-1.091-1.091L19.909 19l-2.183-2.183a.772.772 0 0 1 1.091-1.091L21 17.909l2.183-2.183Z",
            fill: "#fff"
          }
        )
      ]
    }
  );
}
function F(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      width: "28",
      height: "27",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M17.217 6.23c0 3.44-2.783 6.228-6.217 6.228-3.434 0-6.217-2.789-6.217-6.229S7.566 0 11 0c3.434 0 6.217 2.789 6.217 6.23Zm-.956 0A5.266 5.266 0 0 1 11 11.5a5.266 5.266 0 0 1-5.26-5.27A5.266 5.266 0 0 1 11 .957c2.905 0 5.26 2.36 5.26 5.271Z",
            fill: "#595857"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M.957 23c0-3.97 3.211-7.188 7.173-7.188h5.74c3.962 0 7.174 3.219 7.174 7.188H22c0-4.499-3.64-8.146-8.13-8.146H8.13C3.64 14.854 0 18.501 0 23h.957Z",
            fill: "#595857"
          }
        ),
        /* @__PURE__ */ e("path", { d: "M28 19a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fill: "#fff" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M27.113 19a7.111 7.111 0 1 1-14.222 0 7.111 7.111 0 0 1 14.222 0Z",
            fill: "#D7000B"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M20.002 26.111a7.111 7.111 0 1 0 0-14.222 7.111 7.111 0 0 0 0 14.222Z",
            fill: "#D7000B"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "m23.206 16.543-.755-.754L20 18.24l-2.451-2.45-.755.753 2.452 2.452-2.452 2.451.755.754 2.45-2.451 2.452 2.451.755-.754-2.452-2.451 2.451-2.452Z",
            fill: "#fff"
          }
        )
      ]
    }
  );
}
function J(n) {
  return /* @__PURE__ */ t(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "30",
      height: "28",
      fill: "none",
      ...n,
      children: [
        /* @__PURE__ */ e(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M16.144 6.4c0 2.724-2.176 4.9-4.822 4.9-2.647 0-4.823-2.176-4.823-4.9s2.176-4.9 4.823-4.9c2.646 0 4.822 2.176 4.822 4.9Zm1.5 0c0 3.535-2.83 6.4-6.322 6.4S4.998 9.935 4.998 6.4 7.83 0 11.322 0c3.491 0 6.322 2.865 6.322 6.4ZM2.518 23.26c.366-3.657 3.416-6.51 7.125-6.51h3.951c3.71 0 6.76 2.854 7.126 6.51.04.407.368.74.777.74.41 0 .745-.333.71-.74-.37-4.487-4.085-8.01-8.613-8.01H9.643c-4.527 0-8.242 3.523-8.613 8.01-.034.407.3.74.71.74s.737-.333.778-.74Z",
            fill: "#003878"
          }
        ),
        /* @__PURE__ */ t(
          "mask",
          {
            id: "a",
            maskUnits: "userSpaceOnUse",
            x: "12",
            y: "10",
            width: "18",
            height: "18",
            fill: "#000",
            children: [
              /* @__PURE__ */ e("path", { fill: "#fff", d: "M12 10h18v18H12z" }),
              /* @__PURE__ */ e("path", { d: "M29 19a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z" })
            ]
          }
        ),
        /* @__PURE__ */ e("path", { d: "M29 19a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fill: "#003878" }),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M28 19a7 7 0 0 1-7 7v2a9 9 0 0 0 9-9h-2Zm-7 7a7 7 0 0 1-7-7h-2a9 9 0 0 0 9 9v-2Zm-7-7a7 7 0 0 1 7-7v-2a9 9 0 0 0-9 9h2Zm7-7a7 7 0 0 1 7 7h2a9 9 0 0 0-9-9v2Z",
            fill: "#fff",
            mask: "url(#a)"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            d: "M20.667 21.836c.122-.074.184-.11.249-.125a.4.4 0 0 1 .168 0c.065.014.127.051.249.125l1.45.875c.416.251.623.376.77.35a.4.4 0 0 0 .286-.207c.07-.132.015-.368-.096-.84l-.385-1.65c-.032-.14-.048-.209-.041-.276a.398.398 0 0 1 .051-.16c.034-.057.088-.104.196-.198l1.282-1.11c.367-.318.55-.477.572-.625a.4.4 0 0 0-.11-.335c-.104-.108-.346-.128-.829-.169l-1.687-.143c-.142-.012-.213-.018-.274-.045a.4.4 0 0 1-.136-.099c-.045-.05-.073-.115-.129-.247l-.66-1.558c-.189-.445-.283-.667-.417-.733a.4.4 0 0 0-.352 0c-.134.066-.228.288-.417.733l-.66 1.558c-.056.132-.084.197-.129.247a.4.4 0 0 1-.136.099c-.06.027-.132.033-.274.045l-1.687.143c-.483.041-.725.061-.83.169a.4.4 0 0 0-.108.335c.02.148.204.307.57.624l1.283 1.111c.108.094.162.14.196.198.028.05.046.104.051.16.007.067-.009.136-.041.275l-.385 1.65c-.11.473-.166.71-.096.841a.4.4 0 0 0 .286.208c.147.025.354-.1.77-.35l1.45-.876Z",
            fill: "#fff"
          }
        )
      ]
    }
  );
}
export {
  r as Brush,
  i as Calendar,
  l as CartGamma,
  d as CartKarwei,
  C as Chart,
  a as Chat,
  s as Clock,
  h as Close,
  c as Correct,
  u as CreditCard,
  w as Delete,
  p as Download,
  L as Edit,
  g as Enlarge,
  f as Eye,
  v as Gift,
  I as HeartGamma,
  T as HeartKarwei,
  M as Home,
  k as Loading,
  Z as Location,
  V as Logout,
  m as NewWindow,
  R as Package,
  j as Pen,
  B as Photo,
  S as Play,
  A as Receipt,
  U as Remove,
  G as RotateCCW,
  P as Scan,
  D as Search,
  E as Service,
  K as Share,
  y as Star,
  O as Tool,
  W as Truck,
  H as UserGamma,
  J as UserGammaPro,
  x as UserKarwei,
  N as UserLoggedInGamma,
  b as UserLoggedInKarwei,
  q as UserLoggedOutGamma,
  F as UserLoggedOutKarwei,
  z as WhatsApp
};
