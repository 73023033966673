import { forwardRef, type SVGProps } from "react";

export interface DownloadIconProps extends SVGProps<SVGSVGElement> {}

export const Download = forwardRef<SVGSVGElement, DownloadIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M15.4801 12.5762L12 15.4763L8.51985 12.5762C8.20164 12.311 8.15865 11.8381 8.42382 11.5199C8.689 11.2017 9.16192 11.1587 9.48013 11.4238L11.25 12.8987V4C11.25 3.58579 11.5858 3.25 12 3.25C12.4142 3.25 12.75 3.58579 12.75 4V12.8987L14.5198 11.4238C14.8381 11.1587 15.311 11.2017 15.5762 11.5199C15.8413 11.8381 15.7983 12.311 15.4801 12.5762Z" />
      <path d="M3.75 12C3.75 11.5858 3.41421 11.25 3 11.25C2.58579 11.25 2.25 11.5858 2.25 12V18C2.25 19.5188 3.48122 20.75 5 20.75H19C20.5188 20.75 21.75 19.5188 21.75 18V12C21.75 11.5858 21.4142 11.25 21 11.25C20.5858 11.25 20.25 11.5858 20.25 12V18C20.25 18.6903 19.6904 19.25 19 19.25H5C4.30964 19.25 3.75 18.6903 3.75 18V12Z" />
    </svg>
  ),
);

(Download as React.FC).displayName = "Download";
