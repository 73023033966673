import { createHttpClient } from "@intergamma/account";

import { ZipCityResponse } from "../base-api/generated";

export type DutchAddressRequest = { zipCode: string; houseNumber: string };
export type BelgiumCityRequest = { zipOrCity: string };
export type BelgiumStreetRequest = {
  zipCode: string;
  street: string;
  language: "nl" | "fr";
};
export type BelgiumStreetResponse = string[];

export type DutchAddressResponse = {
  city: string;
  houseNumber: string;
  street: string;
  validated: true;
  zipCode: string;
};

export function createZipCodeService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/zipcode`, {
    withCredentials: true,
  });

  return {
    getDutchAddress({
      zipCode,
      houseNumber,
    }: DutchAddressRequest): Promise<DutchAddressResponse | null> {
      return baseClient.get<DutchAddressResponse>(
        `/nl?zipCode=${zipCode}&houseNumber=${houseNumber}`,
      );
    },
    getBelgiumCity({
      zipOrCity,
    }: BelgiumCityRequest): Promise<ZipCityResponse[] | null> {
      return baseClient.get<ZipCityResponse[]>(
        `/be/city?zipOrCity=${zipOrCity}`,
      );
    },
    getBelgiumStreet({
      zipCode,
      street,
      language,
    }: BelgiumStreetRequest): Promise<BelgiumStreetResponse | null> {
      return baseClient.get<BelgiumStreetResponse>(
        `/be/street?zipCode=${zipCode}&street=${street}&language=${language}`,
      );
    },
  };
}
