import { Suspense } from "react";

import { useAuthenticate } from "@intergamma/account";
import {
  IconsNavigation,
  FavoritesIconNavigation,
  CartIconNavigation,
  TopNavigation,
  LanguageSelector,
  StoreLocatorDropdown,
  UserIconNavigation,
  HeaderContainer,
  useChangeStoreMutation,
  useNearbyStoresQuery,
  useNumberOfProductsQuery,
  BaseNavigation,
  LoadingSearch,
  ShopfrontSearchMobile,
  ShopfrontSearchDesktop,
} from "@intergamma/header";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useSessionData } from "~/features/session-data/SessionDataContext";

import { useAlternateUrls } from "~/hooks/useAlternateUrls";
import { useCategoriesQuery } from "~/hooks/useCategoriesQuery";

export function Header() {
  const { t } = useTranslation("ig-header");
  const sessionData = useSessionData();
  const { authenticate, error, isLoading } = useAuthenticate();

  const { categories, onSelect } = useCategoriesQuery();
  const { data: numberOfProducts } = useNumberOfProductsQuery();
  const { mutateAsync: onSaveStore } = useChangeStoreMutation();
  const { data: nearbyStores, isPending: isLoadingNearbyStores } =
    useNearbyStoresQuery(sessionData.preferredStore ?? undefined);

  const alternateUrls = useAlternateUrls();

  return (
    <HeaderContainer>
      <BaseNavigation
        store={sessionData.preferredStore ?? undefined}
        categories={categories}
        onSelect={onSelect}
      />
      <Suspense>
        <Suspense fallback={<LoadingSearch />}>
          <div className="contents lg:hidden">
            <ShopfrontSearchMobile />
          </div>
          <div className="hidden lg:contents">
            <ShopfrontSearchDesktop />
          </div>
        </Suspense>
      </Suspense>
      <TopNavigation>
        <LanguageSelector alternateURL={alternateUrls} />
        <StoreLocatorDropdown
          isLoadingNearbyStores={isLoadingNearbyStores}
          nearbyStores={nearbyStores ?? []}
          store={sessionData.preferredStore ?? undefined}
          onSaveStore={onSaveStore}
        />
      </TopNavigation>
      <IconsNavigation>
        <UserIconNavigation
          user={sessionData}
          onLogin={(body) =>
            authenticate(body).then(() => {
              toast.success(t("loginSuccess"));
            })
          }
          errorMessage={error}
          isLoading={isLoading}
        />
        <FavoritesIconNavigation />
        <CartIconNavigation numberOfProducts={numberOfProducts ?? 0} />
      </IconsNavigation>
    </HeaderContainer>
  );
}
