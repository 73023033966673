import { forwardRef, type SVGProps } from "react";

export interface StarOutlineProps extends SVGProps<SVGSVGElement> {}

export const StarOutline = forwardRef<SVGSVGElement, StarOutlineProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0568 2.92279C10.7841 1.21194 13.216 1.21193 13.9433 2.92279L15.7647 7.20764C15.9344 7.60687 16.3118 7.87965 16.7452 7.91631L21.4 8.31005C23.2603 8.46741 24.0146 10.7785 22.6035 11.9973L19.0657 15.053C18.7367 15.3371 18.5927 15.7796 18.6916 16.2022L19.7535 20.7409C20.1777 22.5536 18.2059 23.9824 16.6076 23.0206L12.6059 20.6125C12.2333 20.3883 11.7668 20.3883 11.3942 20.6125L7.3925 23.0206C5.79414 23.9824 3.82239 22.5536 4.24653 20.7409L5.30849 16.2022C5.40738 15.7796 5.26333 15.3371 4.93436 15.053L1.3966 11.9973C-0.0145184 10.7785 0.739812 8.46741 2.60011 8.31005L7.25491 7.91631C7.68825 7.87965 8.06565 7.60687 8.23536 7.20764L10.0568 2.92279ZM12.6478 3.47023C12.4054 2.89995 11.5947 2.89994 11.3523 3.47023L9.53085 7.75508C9.15749 8.63339 8.32721 9.23351 7.37385 9.31416L2.71906 9.7079C2.09896 9.76035 1.84752 10.5307 2.31789 10.937L5.85565 13.9927C6.57939 14.6178 6.89628 15.5912 6.67874 16.5209L5.61678 21.0596C5.4754 21.6639 6.13264 22.1401 6.66543 21.8195L10.6671 19.4114C11.4869 18.9181 12.5132 18.9181 13.333 19.4114L17.3346 21.8195C17.8674 22.1401 18.5247 21.6639 18.3833 21.0596L17.3213 16.5209C17.1038 15.5912 17.4207 14.6178 18.1444 13.9927L21.6822 10.937C22.1526 10.5307 21.9011 9.76035 21.281 9.7079L16.6262 9.31416C15.6729 9.23351 14.8426 8.63339 14.4692 7.75508L12.6478 3.47023Z"
      />
    </svg>
  ),
);

(StarOutline as React.FC).displayName = "StarOutline";
