import {
  createContext,
  ReactNode,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";

import { DeviceType, getDeviceType } from "~/helpers/getDeviceType";
import { getKioskData, KioskData } from "~/helpers/getKioskData";
import { addRequestModeToLogContext } from "~/helpers/tracking/datadog-logging";

export interface Device {
  type: DeviceType | null;
  kioskData: KioskData | null;
}

const DeviceContext = createContext<Device | undefined>(undefined);

DeviceContext.displayName = "Device";

interface DeviceContextProviderProps {
  children: ReactNode;
}

export function DeviceContextProvider({
  children,
}: DeviceContextProviderProps) {
  const [type, setType] = useState<Device["type"]>(null);
  const [kioskData, setKioskData] = useState<Device["kioskData"]>(null);

  const device: Device = useMemo(
    () => ({
      kioskData,
      type,
    }),
    [kioskData, type],
  );

  useEffect(() => {
    setDeviceContext();

    async function setDeviceContext() {
      const deviceType = await getDeviceType();

      setType(deviceType);
      addRequestModeToLogContext(deviceType);

      if (deviceType === "kiosk") {
        setKioskData(await getKioskData());
      }
    }
  }, []);

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  );
}

export function useDeviceContext() {
  const context = useContext(DeviceContext);

  if (context === undefined) {
    throw new Error(
      "useDeviceContext must be used within a DeviceContextProvider",
    );
  }

  return context;
}
