import { forwardRef, type SVGProps } from "react";

export interface ContactIconProps extends SVGProps<SVGSVGElement> {}

export const Contact = forwardRef<SVGSVGElement, ContactIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00006 5.75C2.00006 3.67893 3.67899 2 5.75006 2H18.75C20.821 2 22.5 3.67893 22.5 5.75V17.0055C22.5 19.0766 20.821 20.7555 18.75 20.7555H7.07982L5.02261 22.9349C3.93564 24.0865 1.99998 23.3173 2 21.7337L2.00006 5.75ZM5.75006 3.5C4.50742 3.5 3.50006 4.50736 3.50006 5.75L3.5 21.7337C3.5 21.9599 3.77652 22.0698 3.9318 21.9053L6.211 19.4907C6.35269 19.3406 6.54999 19.2555 6.75641 19.2555H18.75C19.9926 19.2555 21 18.2481 21 17.0055V5.75C21 4.50736 19.9926 3.5 18.75 3.5H5.75006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6367 12.9844C16.4141 12.8736 15.3125 12.3341 15.1074 12.2612C14.9023 12.1854 14.7529 12.1504 14.6035 12.372C14.4541 12.5966 14.0264 13.0982 13.8945 13.2469C13.7656 13.3927 13.6338 13.4132 13.4111 13.3023C13.1855 13.1915 12.4648 12.9553 11.6094 12.1941C10.9443 11.605 10.4932 10.873 10.3643 10.6513C10.2324 10.4268 10.3496 10.3072 10.4609 10.1964C10.5635 10.0972 10.6865 9.93681 10.7979 9.80557C10.9092 9.67725 10.9473 9.58393 11.0234 9.43519C11.0967 9.28645 11.0586 9.15522 11.0029 9.04439C10.9473 8.93357 10.499 7.83409 10.3115 7.38788C10.1299 6.95334 9.94531 7.01458 9.80762 7.00583C9.67871 7 9.5293 7 9.37988 7C9.23047 7 8.98731 7.05541 8.78223 7.27997C8.57715 7.50162 8 8.04115 8 9.13772C8 10.2343 8.80273 11.2959 8.91406 11.4446C9.02539 11.5904 10.4932 13.8419 12.7402 14.8072C13.2734 15.0376 13.6895 15.1747 14.0146 15.2767C14.5508 15.4459 15.04 15.4226 15.4268 15.3671C15.8574 15.303 16.751 14.8276 16.9385 14.3056C17.123 13.7865 17.123 13.3402 17.0674 13.2469C17.0117 13.1536 16.8623 13.0982 16.6367 12.9844Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Contact as React.FC).displayName = "Contact";
