import { jsx as u } from "react/jsx-runtime";
import { useMemo as r, useState as f, useRef as c, useLayoutEffect as l, createContext as a, useContext as d } from "react";
function C() {
  const e = r(
    () => typeof window < "u" && (window == null ? void 0 : window.matchMedia("(min-width: 768px)")) || null,
    []
  ), [t, n] = f(!(e != null && e.matches)), o = c(!0);
  return l(() => (e && (e.onchange = (s) => {
    o.current && n(!s.matches);
  }), () => {
    o.current = !1;
  }), [e]), t;
}
const i = a(null);
i.displayName = "ConfigContext";
function g({ config: e, children: t }) {
  const n = C(), o = r(
    () => ({ ...e, isMobile: n }),
    [e, n]
  );
  return /* @__PURE__ */ u(i.Provider, { value: o, children: t });
}
function w() {
  const e = d(i);
  if (e === null)
    throw new Error("useConfig can only be used in a ConfigProvider");
  return e;
}
export {
  g as ConfigProvider,
  w as useConfig
};
