import { jsx as t } from "react/jsx-runtime";
import { isValidElement as o, Fragment as s, Children as c, cloneElement as d } from "react";
function f({ children: r }) {
  return i(r);
}
const a = {
  "data-mop-suppress": "",
  "data-hj-suppress": "",
  "data-dd-privacy": "mask"
};
function i(r) {
  const n = o(r) && r.type !== s;
  return Array.isArray(r) ? c.map(r, (p) => i(p)) : n ? d(r, a) : /* @__PURE__ */ t("span", { ...a, children: r });
}
export {
  f as PrivacyMask
};
