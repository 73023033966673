import { createHttpClient } from "@intergamma/account";

import { mapLoyaltyBalanceResponse } from "./mappers/mapLoyaltyBalanceResponse";
import { mapLoyaltyDashboardOverviewResponse } from "./mappers/mapLoyaltyDashboardOverviewResponse";
import type {
  LoyaltyClaimPoints,
  LoyaltyClaimPointsBody,
  LoyaltyClaimPointsResponse,
} from "./types/ClaimPoints";
import type {
  LoyaltyDashboardOverviewResponse,
  LoyaltyPointBalanceResponse,
  LoyaltyDashboardOverview,
  LoyaltyPointBalance,
} from "./types/LoyaltyPoints";

export function createLoyaltyPointsService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/loyaltypoints`, {
    withCredentials: true,
  });

  return {
    getLoyaltyDashboardOverview(): Promise<LoyaltyDashboardOverview> {
      return baseClient
        .get<LoyaltyDashboardOverviewResponse>("/dashboard/overview")
        .then((response) => mapLoyaltyDashboardOverviewResponse(response));
    },
    getLoyaltyPointBalance(): Promise<LoyaltyPointBalance> {
      return baseClient
        .get<LoyaltyPointBalanceResponse>("/balance")
        .then((response) => mapLoyaltyBalanceResponse(response));
    },
    claimPoints(payload: LoyaltyClaimPointsBody): Promise<LoyaltyClaimPoints> {
      return baseClient.post<LoyaltyClaimPointsResponse>("/claim", payload);
    },
  };
}
