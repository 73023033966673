export const isMobileApp =
  typeof window !== "undefined" &&
  window?.navigator.userAgent.includes("IGApp");

export const device = isMobileApp ? "app" : "web";

export enum MobileOperatingSystem {
  ANDROID,
  IOS,
  UNKNOWN,
}

export function getMobileOperatingSystem(): MobileOperatingSystem {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return MobileOperatingSystem.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone/.test(userAgent)) {
    return MobileOperatingSystem.IOS;
  }

  return MobileOperatingSystem.UNKNOWN;
}
