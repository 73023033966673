import { forwardRef, type SVGProps } from "react";

export interface HouseIconProps extends SVGProps<SVGSVGElement> {}

export const House = forwardRef<SVGSVGElement, HouseIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1412 1.39692C11.4114 1.18801 11.7886 1.18801 12.0588 1.39692L20.6588 8.04692C20.8425 8.18895 20.95 8.40805 20.95 8.64023V19.0902C20.95 19.7945 20.6686 20.469 20.169 20.9656C19.6696 21.4621 18.9933 21.7402 18.2889 21.7402H4.91111C4.20675 21.7402 3.53036 21.4621 3.03097 20.9656C2.53144 20.469 2.25 19.7945 2.25 19.0902V9.13107C2.25 8.5893 2.50093 8.07808 2.92951 7.74667L11.1412 1.39692ZM9.48329 20.2402H13.7166V12.2402H9.48329V20.2402ZM15.2166 20.2402V11.4902C15.2166 11.076 14.8808 10.7402 14.4666 10.7402H8.73329C8.31908 10.7402 7.98329 11.076 7.98329 11.4902V20.2402H4.91111C4.60176 20.2402 4.30595 20.118 4.08853 19.9019C3.87126 19.6859 3.75 19.3938 3.75 19.0902V9.13107C3.75 9.05367 3.78585 8.98064 3.84707 8.9333L11.6 2.9383L19.45 9.00836V19.0902C19.45 19.3938 19.3287 19.6859 19.1115 19.9019C18.894 20.118 18.5982 20.2402 18.2889 20.2402H15.2166Z"
      fill="currentColor"
    />
  </svg>
));

(House as React.FC).displayName = "House";
