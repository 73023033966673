import { forwardRef, type SVGProps } from "react";

export interface NotificationIconProps extends SVGProps<SVGSVGElement> {}

export const Notification = forwardRef<SVGSVGElement, NotificationIconProps>(
  (props, ref) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 1C7.44358 1 3.74993 4.69365 3.74993 9.25V12.7256C3.74993 14.3453 3.18694 15.9146 2.15733 17.1649C1.06198 18.4951 2.00818 20.5 3.73129 20.5H8.77511C8.90276 22.1781 10.3048 23.5 12.0156 23.5C13.7264 23.5 15.1285 22.1781 15.2562 20.5H20.2689C21.9921 20.5 22.9382 18.495 21.8428 17.1649C20.813 15.9146 20.2499 14.3452 20.2499 12.7254V9.25C20.2499 4.69365 16.5563 1 11.9999 1ZM5.24993 9.25C5.24993 5.52208 8.27201 2.5 11.9999 2.5C15.7279 2.5 18.7499 5.52208 18.7499 9.25V12.7254C18.7499 14.6931 19.434 16.5996 20.6849 18.1185C20.9744 18.4701 20.7244 19 20.2689 19H3.73129C3.27583 19 3.02573 18.4701 3.31526 18.1185C4.56601 16.5996 5.24993 14.6932 5.24993 12.7256V9.25ZM13.7479 20.5H10.2834C10.4047 21.3481 11.134 22 12.0156 22C12.8973 22 13.6266 21.3481 13.7479 20.5Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Notification as React.FC).displayName = "Notification";
