// @todo More properties to be added later
export type Formula = {
  id: "karwei_nl" | "gamma_nl" | "gamma_be";
  brand: "gamma" | "karwei";
  brandName: string;
  baseDomain: string;
  contentfulSpaceId: string;
  contentfulToken: string;
};

export function getDomain(formulaId: Formula["id"]) {
  const subDomain = window.location.hostname.includes("acceptatie")
    ? ".acceptatie"
    : "";

  let domain = ".gamma.nl";

  if (formulaId === "gamma_be") {
    domain = ".gamma.be";
  }

  if (formulaId === "karwei_nl") {
    domain = ".karwei.nl";
  }

  return `${subDomain}${domain}`;
}
