import { forwardRef, type SVGProps } from "react";

export interface StarProps extends SVGProps<SVGSVGElement> {}

export const Star = forwardRef<SVGSVGElement, StarProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M11.9998 18.9142L17.2993 22.1127C18.4353 22.7984 19.8367 21.7799 19.5352 20.4877L18.1288 14.4592L22.8127 10.4015C23.8156 9.53268 23.2795 7.88529 21.9574 7.77312L15.7933 7.25016L13.3809 1.55821C12.864 0.338667 11.1356 0.338671 10.6188 1.55821L8.20633 7.25016L2.04227 7.77312C0.720134 7.88529 0.184023 9.53268 1.18692 10.4015L5.87083 14.4592L4.46445 20.4877C4.16301 21.7799 5.56435 22.7984 6.70033 22.1127L11.9998 18.9142Z" />
  </svg>
));

(Star as React.FC).displayName = "Star";
