import { LocalDateResponse } from "~/services/types/LocalDateResponse";

export interface PurchaseDetailPayload {
  purchaseId: string;
}
export interface FindPurchasePayload extends PurchaseDetailPayload {
  postalCode: string;
}

export interface PurchaseStatusPayload extends PurchaseDetailPayload {
  hash: string;
  locale: "nl_BE" | "fr_BE" | "nl";
}

export enum PurchaseType {
  ORDER = "ORDER",
  RECEIPT = "RECEIPT",
}

export enum PurchaseState {
  ORDER_RECEIVED = "ORDER_RECEIVED",
  ORDER_PACKING = "ORDER_PACKING",
  ORDER_READY_FOR_SHIPPING = "ORDER_READY_FOR_SHIPPING",
  ORDER_SHIPPED = "ORDER_SHIPPED",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  DELIVERY_REFUSED = "DELIVERY_REFUSED",
  DELIVERY_LOST = "DELIVERY_LOST",
  RETURN_REGISTERED = "RETURN_REGISTERED",
  RETURN_SHIPPED = "RETURN_SHIPPED",
  RETURN_BROUGHT_TO_STORE = "RETURN_BROUGHT_TO_STORE",
  CANCELLED = "CANCELLED",
  RETURN_REFUNDED = "RETURN_REFUNDED",
  REPLACED = "REPLACED",
  PACKING_ISSUES = "PACKING_ISSUES",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  SHIPPED_TO_STORE = "SHIPPED_TO_STORE",
  ORDER_READY_FOR_PICKUP = "ORDER_READY_FOR_PICKUP",
  ORDER_PICKED_UP = "ORDER_PICKED_UP",
  COMPLETED = "COMPLETED",
  RESERVED = "RESERVED",
  RENTAL_IN_PROGRESS = "RENTAL_IN_PROGRESS",
  RENTED = "RENTED",
  IN_PROGRESS = "IN_PROGRESS",
  UNKNOWN = "UNKNOWN",
  STORE_BOUGHT = "STORE_BOUGHT",
}

export enum InvoiceType {
  PURCHASE = "PURCHASE",
  CREDIT = "CREDIT",
}

export type PurchaseAddress = {
  to?: string;
  street: string;
  houseNumber: string;
  houseNumberExt: string | null;
  zipcode: string;
  city: string;
  country: string;
};

export type StoreAddress = {
  street: string;
  streetNumber: string;
  apartment: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
};

export type PurchaseLoyalty = {
  cardNumber: string | null;
  pointsGranted: number;
  pointsUsed: number;
};

export type Payment = {
  method: string;
  amount: number;
};

export type Manual = {
  code: string;
  types: string[];
  languages: string[];
};

export type Material = {
  key: string;
  value: string;
};

export type MixedPaint = {
  id: string | null;
  productDisplayName: string | null;
  productName: string | null;
  productCode: string | null;
  productType: string | null;
  celumId: string | null;
  colorDisplayName: string | null;
  colorName: string | null;
  colorCode: string | null;
  fandeckName: string | null;
};

export type ConfiguredProduct = {
  billOfMaterials: Material[];
  mixedPaint: MixedPaint | null;
};

export type Invoice = {
  type: InvoiceType;
  invoiceId: string;
};

export type DiscountResponse = {
  id: string;
  value: number;
  description: string | null;
};

export type DeliveryWindowResponse = {
  start: LocalDateResponse | null;
  end: LocalDateResponse | null;
};

export type DateTime = {
  date: Date;
  // A separate time field is needed because the Date always defaults to 0:00.
  // This can be implied as the time can be midnight. By using a separate time
  // we can check if there's an actual time set.
  time: string | null;
};

export type DeliveryWindow = {
  start: DateTime | null;
  end: DateTime | null;
};

export type DeliveryResponse = {
  locationType: string;
  chosenTime: LocalDateResponse | null;
  deliveryWindow: DeliveryWindowResponse | null;
};

export type StoreResponse = {
  uid: string;
  shortName: string;
  name: string;
  slug: string;
  address: StoreAddress;
};

export type Store = {
  name: string;
  address: StoreAddress;
  id: string;
};

export enum PurchaseStatusType {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  PENDING = "pending",
}

export type PurchaseStatus = {
  state: PurchaseState;
  type: PurchaseStatusType;
};

export type TrackingLink = {
  url?: string;
  code: string;
};

export type PurchaseItemSummaryResponse = {
  code: string;
  name: string;
  state: string;
  imageUrl: string | null;
  imageWidth: number | null;
  url: string | null;
  deliveryWindow: DeliveryWindowResponse | null;
  pickupCode: string | null;
  tracking: TrackingLink | null;
};

export type PurchaseResponse = {
  purchaseId: string;
  type: string;
  state: string;
  purchasedOn: LocalDateResponse;
  items: PurchaseItemSummaryResponse[];
};

/**
 * Response from the purchases endpoint, the items belonging to a purchase.
 * Used mainly for the purchases overview page.
 */
export type PurchaseItemSummary = {
  code: string;
  name: string;
  status: PurchaseStatus;
  imageUrl: string | null;
  imageWidth: number | null;
  url: string | null;
  pickupCode: string | null;
  delivery: DeliveryWindow | null;
  tracking: TrackingLink | null;
};

/**
 * Response from the purchases endpoint, basic information without details.
 * Used mainly for the purchases overview page.
 */
export type Purchase = {
  purchaseId: string;
  type: PurchaseType;
  status: PurchaseStatus;
  purchasedOn: DateTime;
  items: PurchaseItemSummary[];
};

export interface PurchaseItemResponse {
  code: string;
  name: string;
  state: string;
  imageUrl: string | null;
  imageWidth: number | null;
  url: string | null;
  quantity: number | null;
  subtotalAmount: number;
  deliveryInformation: DeliveryResponse | null;
  cancellable: boolean;
  discounts: DiscountResponse[];
  deposit: number | null;
  pickupCode: string | null;
  manuals: Manual[];
  configuredProduct: ConfiguredProduct | null;
  returnUntil: LocalDateResponse | null;
  warrantyExpirationDate: LocalDateResponse | null;
  tracking: TrackingLink | null;
}

export interface PurchaseDetailsResponse {
  purchaseId: string;
  type: string;
  state: string;
  purchasedOn: LocalDateResponse;
  totalAmount: number;
  deliveryCosts: number | null;
  items: PurchaseItemResponse[];
  deliveryAddress: PurchaseAddress | null;
  invoiceAddress: PurchaseAddress | null;
  store: StoreResponse | null;
  loyalty: PurchaseLoyalty | null;
  payments: Payment[];
  invoices: Invoice[];
  discounts: DiscountResponse[];
}

// Response from the purchases/[purchaseId] endpoint, the details of items belonging to a purchase.
// Used mainly for the purchase details page.
export interface PurchaseItem extends PurchaseItemSummary {
  quantity: number | null;
  subtotalAmount: number | null;
  manuals: Manual[];
  warrantyExpirationDate: string | null;
  returnDuration: number | null;
  cancellable: boolean;
  discountAmount: number;
  deposit: number | null;
  isDropshipment: boolean;
  tracking: TrackingLink | null;
  configuredProduct: ConfiguredProduct | null;
  isReviewable: boolean;
}

/**
 * Response from the purchases/[purchaseId] endpoint, the details of a purchase.
 * Used mainly for the purchase details page.
 */
export interface PurchaseDetails {
  purchaseId: string;
  type: PurchaseType;
  status: PurchaseStatus;
  purchasedOn: DateTime;
  totalAmount: number;
  totalDiscountAmount: number;
  deliveryCosts: number;
  items: PurchaseItem[];
  deliveryAddress: PurchaseAddress | null;
  invoiceAddress: PurchaseAddress | null;
  store: Store | null;
  loyalty: PurchaseLoyalty | null;
  payments: Payment[];
  totalQuantity: number;
  invoices: Invoice[];
  loyaltyPointsDiscount: number;
}
