import { createHttpClient } from "@intergamma/account";

import { SavedConfiguration } from "./types/SavedConfiguration";

export function createSavedConfigurationsService(MYACCOUNT_URL: string) {
  const savedConfigurationsClient = createHttpClient(
    `${MYACCOUNT_URL}/api/product-configurations`,
    {
      withCredentials: true,
    },
  );

  return {
    getSavedConfigurations(): Promise<SavedConfiguration[]> {
      return savedConfigurationsClient.get<SavedConfiguration[]>("/");
    },

    deleteSavedConfiguration(configurationId: string): Promise<void> {
      return savedConfigurationsClient.delete(`/${configurationId}`);
    },
  };
}
