import { jsxs as l, jsx as o } from "react/jsx-runtime";
import { forwardRef as i, createRef as p } from "react";
import { cn as c } from "@intergamma/common/cn";
import * as r from "@radix-ui/react-dialog";
import { DialogTitle as u } from "@radix-ui/react-dialog";
import { DialogRoot as h } from "./DialogRoot.js";
import { Dialog as q, DialogContent as A } from "./Dialog.js";
import { DialogCloseIcon as G, StyledCloseIcon as H } from "./DialogCloseIcon.js";
import { DialogClose as K } from "./DialogClose.js";
import { DialogDescription as M } from "./DialogDescription.js";
import { DialogTitle as V } from "./DialogTitle.js";
import { DialogTrigger as Z, UnstyledDialogDescription as ee, UnstyledDialogTitle as oe, UnstyledDialogTrigger as te } from "./DialogTrigger.js";
import { X as w } from "react-feather";
function $({
  trigger: e,
  animationDirection: t = "ltr",
  children: n,
  ...a
}) {
  return /* @__PURE__ */ l(h, { ...a, children: [
    e,
    /* @__PURE__ */ o(v, { $animationDirection: t, children: n })
  ] });
}
const D = i(
  ({ children: e, $animationDirection: t, container: n, ...a }, s) => /* @__PURE__ */ l(r.Portal, { container: n, children: [
    /* @__PURE__ */ o(
      _,
      {
        ...a,
        animationDirection: t,
        ref: s,
        children: e
      }
    ),
    /* @__PURE__ */ o(y, {})
  ] })
), y = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] bg-[hsla(200,60%,12%,0.75)]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), _ = i(
  ({ animationDirection: e = "ltr", ...t }, n) => /* @__PURE__ */ o(
    r.Content,
    {
      ...t,
      ref: n,
      className: c(
        "fixed inset-y-0 z-[99999] w-[90vw] max-w-[500px] overflow-auto bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
        e === "rtl" ? "left-auto right-0" : "left-0 right-auto",
        e === "rtl" ? "motion-safe:animate-dialog-content-show-rtl" : "motion-safe:animate-dialog-content-show-ltr",
        "focus:outline-none"
      ),
      children: t.children
    }
  )
), v = D;
function x() {
  return /* @__PURE__ */ o(C, { "data-testid": "close-button", children: /* @__PURE__ */ o(b, {}) });
}
const C = ({ ...e }) => /* @__PURE__ */ o(
  r.Close,
  {
    ...e,
    className: "absolute right-4 top-4 h-auto cursor-pointer border-none bg-transparent p-0 leading-none shadow-none focus:shadow-none"
  }
), b = () => /* @__PURE__ */ o(w, { className: "text-[#003878]" });
function B({
  trigger: e,
  wide: t = !1,
  children: n,
  container: a,
  dialogTitle: s,
  ...d
}) {
  const m = p(), f = (g) => {
    g.target !== m.current && g.preventDefault();
  };
  return /* @__PURE__ */ l(h, { ...d, children: [
    e,
    /* @__PURE__ */ o(r.Portal, { container: a, children: /* @__PURE__ */ o(N, { ref: m, children: /* @__PURE__ */ l(
      O,
      {
        onInteractOutside: f,
        "data-testid": "dialog-content",
        wide: t,
        children: [
          s && /* @__PURE__ */ o(u, { className: "sr-only", children: s }),
          /* @__PURE__ */ o(x, {}),
          n
        ]
      }
    ) }) })
  ] });
}
const N = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] flex flex-col items-center bg-[hsla(200,60%,12%,0.75)]",
      "overflow-y-auto py-8 backdrop-blur-[2px]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), O = i(({ wide: e = !1, children: t, ...n }, a) => /* @__PURE__ */ o(
  r.Content,
  {
    ...n,
    ref: a,
    className: c(
      "relative z-[99999] m-auto rounded-[0.8rem] bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
      "w-[90vw]",
      e ? "max-w-[690px]" : "max-w-[348px]",
      "animate-dialog-content-show",
      "focus:outline-none"
    ),
    children: t
  }
));
function E({
  trigger: e,
  children: t,
  container: n,
  dialogTitle: a,
  ...s
}) {
  const d = p(), m = (f) => {
    f.target !== d.current && f.preventDefault();
  };
  return /* @__PURE__ */ l(h, { ...s, children: [
    e,
    /* @__PURE__ */ o(r.Portal, { container: n, children: /* @__PURE__ */ o(I, { ref: d, children: /* @__PURE__ */ l(
      z,
      {
        onInteractOutside: m,
        "data-testid": "dialog-content",
        children: [
          a && /* @__PURE__ */ o(u, { className: "sr-only", children: a }),
          /* @__PURE__ */ l(R, { children: [
            /* @__PURE__ */ o(x, {}),
            t
          ] })
        ]
      }
    ) }) })
  ] });
}
const I = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] flex flex-col justify-end overflow-y-auto bg-[hsla(200,60%,12%,0.75)] backdrop-blur-[2px]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), z = i((e, t) => /* @__PURE__ */ o(
  r.Content,
  {
    ...e,
    ref: t,
    className: c(
      "relative z-[99999] h-[93svh] w-full rounded-[0.8rem_0.8rem_0_0] bg-white",
      "motion-safe:animate-dialog-content-expand-height",
      "focus:outline-none"
    ),
    children: e.children
  }
)), R = ({ children: e }) => /* @__PURE__ */ o("div", { className: "h-auto w-full rounded-[0.8rem_0.8rem_0_0] bg-white", children: e });
export {
  q as Dialog,
  E as DialogBottomSheet,
  K as DialogClose,
  G as DialogCloseIcon,
  A as DialogContent,
  M as DialogDescription,
  B as DialogExtended,
  h as DialogRoot,
  V as DialogTitle,
  Z as DialogTrigger,
  $ as Drawer,
  v as DrawerContent,
  H as StyledCloseIcon,
  ee as UnstyledDialogDescription,
  oe as UnstyledDialogTitle,
  te as UnstyledDialogTrigger
};
