import type { MoneyResponse, Money } from "~/services/types/Money";

export enum LoyaltyCardPointsToDiscountStatusResponse {
  NEW = "NEW",
  NETWORK_ERROR = "NETWORK_ERROR",
  SUCCESSFULLY_CONVERTED = "SUCCESSFULLY_CONVERTED",
  CAN_CONVERT = "CAN_CONVERT",
  UNAUTHORIZED = "UNAUTHORIZED",
  LOYALTY_CARD_REGISTERED = "LOYALTY_CARD_REGISTERED",
  CREATE_DISCOUNT_CODE_FAILED = "CREATE_DISCOUNT_CODE_FAILED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  FAILED_UNKNOWN = "FAILED_UNKNOWN",
  INVALID_RECAPTCHA = "INVALID_RECAPTCHA",
}

interface BaseLoyaltyToDiscountResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse;
  balance: number;
}

interface LoyaltyToDiscountStatusNewResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.NEW;
}

interface LoyaltyToDiscountInvalidReCaptchaResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.INVALID_RECAPTCHA;
}

interface LoyaltyToDiscountInsufficientBalanceResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.INSUFFICIENT_BALANCE;
}

interface LoyaltyToDiscountRegisteredLoyaltyCardResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.LOYALTY_CARD_REGISTERED;
}

interface LoyaltyToDiscountNetworkErrorResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.NETWORK_ERROR;
}

interface LoyaltyToDiscountUnauthorizedResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.UNAUTHORIZED;
}

interface LoyaltyToDiscountCreateFailedResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.CREATE_DISCOUNT_CODE_FAILED;
}

interface LoyaltyToDiscountUnknownResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.FAILED_UNKNOWN;
}

interface LoyaltyToDiscountRegisteredResponse
  extends BaseLoyaltyToDiscountResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.NETWORK_ERROR;
  loyaltyCardName: string;
}

interface LoyaltyToDiscountSuccessFullyConvertedResponse
  extends BaseLoyaltyToDiscountResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.SUCCESSFULLY_CONVERTED;
  discountCode: string;
  loyaltyPointSlug: string;
  pointsConverted: number;
  discountValue: MoneyResponse;
}

interface LoyaltyToDiscountCanConvertResponse
  extends BaseLoyaltyToDiscountResponse {
  status: LoyaltyCardPointsToDiscountStatusResponse.CAN_CONVERT;
  balance: number;
  minimumRequiredBalance: number;
  monetaryValue: MoneyResponse;
}

export type LoyaltyToDiscountResponse =
  | LoyaltyToDiscountSuccessFullyConvertedResponse
  | LoyaltyToDiscountCanConvertResponse
  | LoyaltyToDiscountRegisteredResponse
  | LoyaltyToDiscountStatusNewResponse
  | LoyaltyToDiscountNetworkErrorResponse
  | LoyaltyToDiscountUnauthorizedResponse
  | LoyaltyToDiscountCreateFailedResponse
  | LoyaltyToDiscountUnknownResponse
  | LoyaltyToDiscountInsufficientBalanceResponse
  | LoyaltyToDiscountInvalidReCaptchaResponse
  | LoyaltyToDiscountRegisteredLoyaltyCardResponse;

export enum LoyaltyCardPointsToDiscountStatus {
  NEW = "NEW",
  NETWORK_ERROR = "NETWORK_ERROR",
  SUCCESSFULLY_CONVERTED = "SUCCESSFULLY_CONVERTED",
  CAN_CONVERT = "CAN_CONVERT",
  UNAUTHORIZED = "UNAUTHORIZED",
  LOYALTY_CARD_REGISTERED = "LOYALTY_CARD_REGISTERED",
  CREATE_DISCOUNT_CODE_FAILED = "CREATE_DISCOUNT_CODE_FAILED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  FAILED_UNKNOWN = "FAILED_UNKNOWN",
  INVALID_RECAPTCHA = "INVALID_RECAPTCHA",
}

interface BaseLoyaltyToDiscount {
  status: LoyaltyCardPointsToDiscountStatus;
  balance: number;
}

interface LoyaltyToDiscountStatusNew {
  status: LoyaltyCardPointsToDiscountStatus.NEW;
}

interface LoyaltyToDiscountInvalidReCaptcha {
  status: LoyaltyCardPointsToDiscountStatus.INVALID_RECAPTCHA;
}

interface LoyaltyToDiscountInsufficientBalance {
  status: LoyaltyCardPointsToDiscountStatus.INSUFFICIENT_BALANCE;
}

interface LoyaltyToDiscountNetworkError {
  status: LoyaltyCardPointsToDiscountStatus.NETWORK_ERROR;
}

interface LoyaltyToDiscountRegisteredLoyaltyCard {
  status: LoyaltyCardPointsToDiscountStatus.LOYALTY_CARD_REGISTERED;
}

interface LoyaltyToDiscountUnauthorized {
  status: LoyaltyCardPointsToDiscountStatus.UNAUTHORIZED;
}

interface LoyaltyToDiscountCreateFailed {
  status: LoyaltyCardPointsToDiscountStatus.CREATE_DISCOUNT_CODE_FAILED;
}

interface LoyaltyToDiscountUnknown {
  status: LoyaltyCardPointsToDiscountStatus.FAILED_UNKNOWN;
}

interface LoyaltyToDiscountRegistered extends BaseLoyaltyToDiscount {
  status: LoyaltyCardPointsToDiscountStatus.NETWORK_ERROR;
  loyaltyCardName: string;
}

export interface LoyaltyToDiscountSuccessFullyConverted
  extends BaseLoyaltyToDiscount {
  status: LoyaltyCardPointsToDiscountStatus.SUCCESSFULLY_CONVERTED;
  discountCode: string;
  loyaltyPointSlug: string;
  pointsConverted: number;
  discountValue: Money;
}

export interface LoyaltyToDiscountCanConvert extends BaseLoyaltyToDiscount {
  status: LoyaltyCardPointsToDiscountStatus.CAN_CONVERT;
  balance: number;
  minimumRequiredBalance: number;
  monetaryValue: Money;
  loyaltyCardNumber: string;
}

export type LoyaltyToDiscount =
  | LoyaltyToDiscountSuccessFullyConverted
  | LoyaltyToDiscountCanConvert
  | LoyaltyToDiscountRegistered
  | LoyaltyToDiscountStatusNew
  | LoyaltyToDiscountNetworkError
  | LoyaltyToDiscountUnauthorized
  | LoyaltyToDiscountCreateFailed
  | LoyaltyToDiscountUnknown
  | LoyaltyToDiscountInsufficientBalance
  | LoyaltyToDiscountInvalidReCaptcha
  | LoyaltyToDiscountRegisteredLoyaltyCard;

export interface LoyaltyBalanceCheckBody {
  hash: string;
  recaptchaResponse: string;
  loyaltyCardNumber: string;
}

export interface LoyaltyConvertBody {
  hash: string;
  recaptchaResponse: string;
  loyaltyCardNumber: string;
}
