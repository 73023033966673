import type { Brands } from "./Brands";

export interface DomainConfig {
  loyaltyCardPrefixes: string[];
  phoneNumberCustomerService: {
    forHref: string;
    forDisplay: string;
  };
}

const gammaNlDomainConfig: DomainConfig = {
  phoneNumberCustomerService: {
    forHref: "tel:+31880108610",
    forDisplay: "088-010 86 10",
  },
  loyaltyCardPrefixes: ["2611", "2615", "2616"],
};

const gammaBeDomainConfig: DomainConfig = {
  phoneNumberCustomerService: {
    forHref: "tel:080062610",
    forDisplay: "0800 62 610",
  },
  loyaltyCardPrefixes: ["2613", "2614", "2617", "2618", "2629"],
};

const karweiNlDomainConfig: DomainConfig = {
  loyaltyCardPrefixes: ["2612", "2619"],

  phoneNumberCustomerService: {
    forHref: "tel:+31880108620",
    forDisplay: "088-010 86 20",
  },
};

export function getDomainConfig(brand: Brands) {
  switch (brand) {
    case "gamma_nl":
      return gammaNlDomainConfig;
    case "gamma_be":
      return gammaBeDomainConfig;
    case "karwei_nl":
      return karweiNlDomainConfig;
    default:
      return gammaNlDomainConfig;
  }
}
