import { ReactNode } from "react";

import { useConfig } from "@intergamma/config";
import { cn } from "@intergamma/common/cn";

export type HeadingType = "h1" | "h2" | "h3" | "h4";

interface HeadingProps {
  type: HeadingType;
  children: ReactNode;
  className?: string;
}

export function Heading({ type, children, className }: HeadingProps) {
  const { brand } = useConfig();

  const color =
    brand === "gamma" ? "text-brand-primary" : "text-ignew-neutral-1000";

  switch (type) {
    case "h1":
      return (
        <h1
          className={cn(
            "my-6 font-primary text-500 font-bold md:text-800",
            color,
            className,
          )}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <h3 className={cn("font-primary text-400 font-bold", color, className)}>
          {children}
        </h3>
      );
    case "h3":
      return (
        <h3 className={cn("font-primary text-200 font-bold", color, className)}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h3 className={cn("font-primary font-bold", color, className)}>
          {children}
        </h3>
      );
    default:
      throw new Error("Invalid heading type");
  }
}
