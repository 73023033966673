import type { Config } from "@intergamma/config";
import * as formatters from "@intergamma/formatters";
import type { Theme } from "@intergamma/theme";

import i18next, { Resource } from "i18next";
import BrowserLanguageDetector, {
  CustomDetector,
  DetectorOptions,
} from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend"; // fallback http load
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";

import { log } from "./helpers/tracking/datadog-logging";

interface InitializeI18nProps {
  theme: Theme;
  config: Config;
  debug?: boolean;
  resources?: Resource;
}

function configureI18n({
  theme,
  config,
  debug,
  resources,
}: InitializeI18nProps) {
  if (config.locales.length > 1) {
    const languageDetector = new BrowserLanguageDetector();

    languageDetector.addDetector(igUrlDetector);
    languageDetector.addDetector(igLanguageNavigatorDetector);

    i18next.use(languageDetector);
  }

  const loadPath =
    config.brand === "karwei"
      ? "/my/locales/karwei_{{lng}}/{{ns}}.json"
      : "/my/locales/{{lng}}/{{ns}}.json";

  return i18next
    .use(HttpApi)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
      backend: {
        loadPath,
      },
      debug: !!debug,
      defaultNS: "common",
      detection: getBrowserDetectionOptions(config),
      fallbackLng: config.locales,
      missingInterpolationHandler: (text) => {
        log.warn(
          `Missing value for translation interpolation. Translation content: ${text}`,
        );
      },
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        log.warn(
          `Missing ${lng} translation with key "${key}" and namespace "${ns}". Falling back to: "${fallbackValue}"`,
        );
      },
      saveMissing: false, // Turned off for now as MyAccount is missing too many keys
      interpolation: {
        defaultVariables: { ...theme, ...config },
        escapeValue: false,
        format: (value, format, lng) => {
          if (!format) {
            return value;
          }

          if (format in formatters) {
            const formatKey = format as keyof typeof formatters;

            const formatFunction = formatters[formatKey];

            return formatFunction(value as never, lng, i18next);
          }

          return value;
        },
      },
      ns: ["common"],
      react: {
        transKeepBasicHtmlNodesFor: ["strong", "i", "p", "br", "b"],
        transSupportBasicHtmlNodes: true,
        useSuspense: true,
      },
      supportedLngs: config.locales,
      resources,
    });
}

const igUrlDetector: CustomDetector = {
  name: "igUrlDetector",
  lookup() {
    const { pathname } = window.location;

    if (pathname.startsWith("/my/fr")) {
      return "fr-BE";
    }

    if (pathname.startsWith("/my/nl")) {
      return "nl-BE";
    }

    return undefined;
  },
  cacheUserLanguage,
};

const igLanguageNavigatorDetector: CustomDetector = {
  name: "igLanguageNavigatorDetector",
  lookup() {
    if (typeof navigator !== "undefined") {
      const foundLanguage = navigator.language;

      if (foundLanguage.includes("fr")) {
        return "fr-BE";
      }

      if (foundLanguage.includes("nl")) {
        return "nl-BE";
      }
    }

    return undefined;
  },
  cacheUserLanguage,
};

function getBrowserDetectionOptions(config: Config) {
  return {
    caches: ["igUrlDetector", "igLanguageNavigatorDetector"],
    cookieDomain: config.cookieDomain,
    lookupCookie: `${config.cookiePrefix}-LOCALE`,
    order: ["igUrlDetector", "igLanguageNavigatorDetector"],
  };
}

const fallBackCookie = "intergamma";

function cacheUserLanguage(lng: string, options: DetectorOptions) {
  Cookies.set(options?.lookupCookie ?? fallBackCookie, lng.replace("-", "_"), {
    expires: 365,
    domain: options.cookieDomain,
    sameSite: "strict",
  });
}

export default configureI18n;
