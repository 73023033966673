import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface CrossIconProps extends SVGProps<SVGSVGElement> {}

export const Cross = forwardRef<SVGSVGElement, CrossIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("karwei:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M17.6566 19.0713C18.0471 19.4618 18.6802 19.4618 19.0708 19.0713C19.4613 18.6807 19.4613 18.0476 19.0708 17.657L13.4139 12.0002L19.0708 6.34334C19.4613 5.95281 19.4613 5.31965 19.0708 4.92912C18.6803 4.5386 18.0471 4.5386 17.6566 4.92912L11.9997 10.586L6.34285 4.92913C5.95232 4.5386 5.31916 4.5386 4.92864 4.92913C4.53811 5.31965 4.53811 5.95282 4.92864 6.34334L10.5855 12.0002L4.92868 17.657C4.53816 18.0476 4.53816 18.6807 4.92868 19.0713C5.31921 19.4618 5.95237 19.4618 6.34289 19.0713L11.9997 13.4144L17.6566 19.0713Z"
          fill="currentColor"
        />
      </svg>
      <svg
        className={cn("gamma:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.7071L18.6465 19.3536L19.3536 18.6465L12.7071 12L19.3536 5.35359L18.6465 4.64648L12 11.2929L5.35359 4.64648L4.64648 5.35359L11.2929 12L4.64648 18.6465L5.35359 19.3536L12 12.7071Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(Cross as React.FC).displayName = "Cross";
