import { jsxs as a, jsx as e } from "react/jsx-runtime";
import { cn as i } from "@intergamma/common/cn";
import * as n from "@radix-ui/react-dialog";
import { DialogRoot as r } from "./DialogRoot.js";
function p({ trigger: o, children: t, ...l }) {
  return /* @__PURE__ */ a(r, { ...l, children: [
    o,
    /* @__PURE__ */ e(c, { children: t })
  ] });
}
function s({
  children: o,
  ...t
}) {
  return /* @__PURE__ */ a(n.Portal, { children: [
    /* @__PURE__ */ e(x, { ...t, children: o }),
    /* @__PURE__ */ e(m, {})
  ] });
}
const m = (o) => /* @__PURE__ */ e(
  n.Overlay,
  {
    ...o,
    className: i(
      "fixed inset-0 z-[9999] bg-[hsla(200,60%,12%,0.75)]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
), x = ({
  className: o,
  ...t
}) => /* @__PURE__ */ e(
  n.Content,
  {
    ...t,
    className: i(
      "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
      "z-[99999] rounded-[0.8rem] bg-white",
      "max-h-[85vh] w-[90vw] max-w-[450px]",
      "shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
      "focus:outline-none",
      "motion-safe:animate-dialog-content-show-with-blur",
      o
    ),
    children: t.children
  }
), c = s;
export {
  p as Dialog,
  c as DialogContent
};
