import { jsx as e } from "react/jsx-runtime";
import { forwardRef as m } from "react";
import { cn as t } from "@intergamma/common";
import * as i from "@radix-ui/react-popover";
const s = m((r, o) => /* @__PURE__ */ e(
  i.Trigger,
  {
    ...r,
    ref: o,
    className: t(
      "m-0 h-auto cursor-pointer border-none bg-transparent p-0",
      r.className
    )
  }
));
export {
  s as DropdownTrigger
};
