import { dispatchMobileAppEvent } from "@intergamma/mobile-app";

import { useSuspenseQuery } from "@tanstack/react-query";

import { useBaseApiService } from "~/features/shared/ServicesContext";

export const QUERY_KEY = "session-data";

export function useSessionDataQuery() {
  const baseApiService = useBaseApiService();

  return useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () =>
      baseApiService.getSessionData().catch((error) => {
        if (error.status === 401) {
          // This event needs to be sent so the app can refresh the JWTs.
          dispatchMobileAppEvent({
            app: "myaccount",
            type: "IG_UNAUTHORIZED",
          });
        }

        return null;
      }),
  });
}
