import { createContext, ReactNode, useContext } from "react";

import { useSessionDataQuery } from "./hooks/useSessionDataQuery";

import type { SessionData } from "~/services/base-api/types/Session";

const SessionDataContext = createContext<SessionData | null>(null);

interface SessionDataProviderProps {
  children: ReactNode;
}

export function SessionDataProvider({ children }: SessionDataProviderProps) {
  const { data: sessionData } = useSessionDataQuery();

  if (!sessionData) {
    return null;
  }

  if (sessionData.isLoggedIn && window.fcWidget) {
    window.fcWidget.on("user:created", () => {
      window.fcWidget?.user.setFirstName(sessionData.firstName);
      window.fcWidget?.user.setEmail(sessionData.email);
    });
  }

  return (
    <SessionDataContext.Provider value={sessionData}>
      {children}
    </SessionDataContext.Provider>
  );
}

export function useSessionData() {
  const context = useContext(SessionDataContext);

  if (context === null) {
    throw new Error("useSessionData must be used with a SessionDataProvider ");
  }

  return context;
}
