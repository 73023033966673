import { createContext, ReactNode, useContext, useMemo } from "react";

import { useMyAccountConfig } from "~/config/MyAccountConfigContext";

import { createBaseApiService } from "~/services/base-api";
import { createCsrfService } from "~/services/csrf-service";
import { createGatewayService } from "~/services/gateway";
import { createLoyaltyCardsService } from "~/services/loyalty-cards";
import { createLoyaltyPointsService } from "~/services/loyalty-points";
import { createLoyaltyToDiscountService } from "~/services/loyalty-to-discount";
import { createOptInsService } from "~/services/opt-ins";
import { createOrdersService } from "~/services/orders";
import { createProfileService } from "~/services/profile";
import { createPurchasesService } from "~/services/purchases";
import { createReceiptService } from "~/services/receipts";
import { createRegistrationService } from "~/services/registration";
import { createSavedConfigurationsService } from "~/services/saved-configurations";
import { createStoresService } from "~/services/stores";
import { createZipCodeService } from "~/services/zip-code";

type BaseApiService = ReturnType<typeof createBaseApiService>;
type CsrfService = ReturnType<typeof createCsrfService>;

type GatewayService = ReturnType<typeof createGatewayService>;
type RegistrationService = ReturnType<typeof createRegistrationService>;
type LoyaltyCardsService = ReturnType<typeof createLoyaltyCardsService>;
type LoyaltyPointsService = ReturnType<typeof createLoyaltyPointsService>;
type LoyaltyToDiscountService = ReturnType<
  typeof createLoyaltyToDiscountService
>;
type ReceiptService = ReturnType<typeof createReceiptService>;
type OptInsService = ReturnType<typeof createOptInsService>;
type OrdersService = ReturnType<typeof createOrdersService>;
type PurchasesService = ReturnType<typeof createPurchasesService>;
type SavedConfigurationsService = ReturnType<
  typeof createSavedConfigurationsService
>;
type StoresService = ReturnType<typeof createStoresService>;
type ProfileService = ReturnType<typeof createProfileService>;
type ZipCodeService = ReturnType<typeof createZipCodeService>;

interface Services {
  baseApiService: BaseApiService;
  csrfService: CsrfService;
  gatewayService: GatewayService;
  registrationService: RegistrationService;
  loyaltyCardsService: LoyaltyCardsService;
  loyaltyPointsService: LoyaltyPointsService;
  loyaltyToDiscountService: LoyaltyToDiscountService;
  receiptService: ReceiptService;
  optInsService: OptInsService;
  ordersService: OrdersService;
  purchasesService: PurchasesService;
  savedConfigurationsService: SavedConfigurationsService;
  storesService: StoresService;
  profileService: ProfileService;
  zipCodeService: ZipCodeService;
}

const ServicesContext = createContext<Services | null>(null);

interface ServicesProviderProps {
  children: ReactNode;
}
export function ServicesProvider({ children }: ServicesProviderProps) {
  const {
    baseConfig: { MYACCOUNT_URL, CHECKOUT_URL },
  } = useMyAccountConfig();

  const baseApiService = useMemo(
    () => createBaseApiService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const csrfService = useMemo(
    () => createCsrfService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const gatewayService = useMemo(
    () => createGatewayService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const registrationService = useMemo(
    () => createRegistrationService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const loyaltyCardsService = useMemo(
    () => createLoyaltyCardsService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const loyaltyPointsService = useMemo(
    () => createLoyaltyPointsService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const loyaltyToDiscountService = useMemo(
    () => createLoyaltyToDiscountService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const optInsService = useMemo(
    () => createOptInsService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const receiptService = useMemo(
    () => createReceiptService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const ordersService = useMemo(
    () => createOrdersService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const purchasesService = useMemo(
    () => createPurchasesService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const savedConfigurationsService = useMemo(
    () => createSavedConfigurationsService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const storesService = useMemo(
    () => createStoresService(CHECKOUT_URL),
    [CHECKOUT_URL],
  );

  const profileService = useMemo(
    () => createProfileService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const zipCodeService = useMemo(
    () => createZipCodeService(MYACCOUNT_URL),
    [MYACCOUNT_URL],
  );

  const services = useMemo(
    () => ({
      baseApiService,
      csrfService,
      gatewayService,
      registrationService,
      loyaltyCardsService,
      loyaltyPointsService,
      loyaltyToDiscountService,
      receiptService,
      optInsService,
      ordersService,
      purchasesService,
      savedConfigurationsService,
      storesService,
      profileService,
      zipCodeService,
    }),
    [
      baseApiService,
      csrfService,
      gatewayService,
      registrationService,
      loyaltyCardsService,
      loyaltyPointsService,
      loyaltyToDiscountService,
      receiptService,
      optInsService,
      ordersService,
      purchasesService,
      savedConfigurationsService,
      storesService,
      profileService,
      zipCodeService,
    ],
  );

  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  );
}

export function useServices() {
  const context = useContext(ServicesContext);

  if (context === null) {
    throw new Error("useServices must be used with a ServicesProvider ");
  }

  return context;
}

export function useBaseApiService() {
  const { baseApiService } = useServices();

  return baseApiService;
}

export function useCsrfService() {
  const { csrfService } = useServices();

  return csrfService;
}

export function useGatewayService() {
  const { gatewayService } = useServices();

  return gatewayService;
}

export function useRegistrationService() {
  const { registrationService } = useServices();

  return registrationService;
}

export function useLoyaltyCardsService() {
  const { loyaltyCardsService } = useServices();

  return loyaltyCardsService;
}

export function useLoyaltyPointsService() {
  const { loyaltyPointsService } = useServices();

  return loyaltyPointsService;
}

export function useLoyaltyToDiscountService() {
  const { loyaltyToDiscountService } = useServices();

  return loyaltyToDiscountService;
}

export function useReceiptService() {
  const { receiptService } = useServices();

  return receiptService;
}

export function useOptInsService() {
  const { optInsService } = useServices();

  return optInsService;
}

export function useOrdersService() {
  const { ordersService } = useServices();

  return ordersService;
}

export function usePurchasesService() {
  const { purchasesService } = useServices();

  return purchasesService;
}

export function useSavedConfigurationsService() {
  const { savedConfigurationsService } = useServices();

  return savedConfigurationsService;
}

export function useStoreService() {
  const { storesService } = useServices();

  return storesService;
}

export function useProfileService() {
  const { profileService } = useServices();

  return profileService;
}

export function useZipCodeService() {
  const { zipCodeService } = useServices();

  return zipCodeService;
}
