import { forwardRef, type SVGProps } from "react";

export interface CartIconProps extends SVGProps<SVGSVGElement> {}

export const Cart = forwardRef<SVGSVGElement, CartIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.9375 3.36914C0.9375 2.95493 1.27329 2.61914 1.6875 2.61914H3.21473C4.48652 2.61914 5.5924 3.49121 5.88894 4.72794L5.90632 4.80046H19.3103C21.88 4.80046 23.6884 7.32639 22.8603 9.75894L20.8935 15.5363C20.6521 16.2454 19.986 16.7223 19.2369 16.7223H8.60232C7.793 16.7223 7.08926 16.1673 6.90056 15.3803L4.43028 5.07769C4.29549 4.51554 3.79282 4.11914 3.21473 4.11914H1.6875C1.27329 4.11914 0.9375 3.78335 0.9375 3.36914ZM6.26598 6.30046L8.35921 15.0306C8.38617 15.143 8.4867 15.2223 8.60232 15.2223H19.2369C19.3439 15.2223 19.439 15.1542 19.4735 15.0529L21.4403 9.27555C21.9371 7.81601 20.8521 6.30046 19.3103 6.30046H6.26598Z"
    />
    <path d="M10.4891 20.1307C10.4891 20.8211 9.92943 21.3807 9.23907 21.3807C8.54872 21.3807 7.98907 20.8211 7.98907 20.1307C7.98907 19.4404 8.54872 18.8807 9.23907 18.8807C9.92943 18.8807 10.4891 19.4404 10.4891 20.1307Z" />
    <path d="M17.9644 21.3807C18.6547 21.3807 19.2144 20.8211 19.2144 20.1307C19.2144 19.4404 18.6547 18.8807 17.9644 18.8807C17.274 18.8807 16.7144 19.4404 16.7144 20.1307C16.7144 20.8211 17.274 21.3807 17.9644 21.3807Z" />
  </svg>
));

(Cart as React.FC).displayName = "Cart";
