import type { DetailedHTMLProps, HTMLAttributes } from "react";

import { cn } from "@intergamma/common/cn";

export interface ContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export function Container({ className, ...props }: ContainerProps) {
  return (
    <div
      className={cn(
        "mx-auto my-0 max-w-[540px] md:max-w-[730px] lg:max-w-[960px] xl:max-w-[1168px]",
        className,
      )}
      {...props}
    />
  );
}
