import { createHttpClient } from "@intergamma/account";

import type { ActivateRegistrationBody } from "./types/ActivateRegistration";
import type {
  RegisterByLoyaltyCard,
  RegisterByLoyaltyCardBody,
  RegisterByLoyaltyCardResponse,
} from "./types/RegisterLoyaltyCard";

export function createRegistrationService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api`, {
    withCredentials: true,
  });

  return {
    registerByLoyaltyCard(
      body: RegisterByLoyaltyCardBody,
    ): Promise<RegisterByLoyaltyCard> {
      return baseClient.post<RegisterByLoyaltyCardResponse>(
        "/register-loyalty-card",
        body,
      );
    },
    activateRegistration(payload: ActivateRegistrationBody): Promise<void> {
      return baseClient.post<void>("/activate-registration", payload);
    },
  };
}
