const e = /^([1-9][0-9]{3})\s*(\S.+)$/, s = /^([1-9][0-9]{3})$/, t = /^[1-9][0-9]{3}\s?(?!sa|sd|ss)[a-z]{2}$/i, n = /^(0[1-9]|[12][0-9]|3[01])[-./](0[1-9]|1[0-2])[-./]([0-9]{4})$/, o = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, c = /(.*?)\s?([0-9 ]+[0-9])\s?$/, g = /[\p{C}<>/"=()$]/u, R = /^([1-9][0-9]{3})[- ]?([0-9]{4})[- ]?([0-9]{4})[- ]?([0-9]{4})[- ]?([0-9]{3})$/, a = /^([1-9][0-9]*)$/, x = /^(\+?[0-9]+)([ -]?[0-9]+)*$/, r = /^\s*$/, $ = /^3910([0-9]{20})$/, i = /([^0-9]*)([0-9\s]*)([^0-9]*)/, u = /^([0-9]*)$/, A = /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/, m = new RegExp("^((?!\\p{Emoji_Presentation}).)*$", "u");
export {
  e as beMunicipalityRegex,
  s as bePostalCodeRegex,
  n as dateRegex,
  o as emailRegex,
  m as emojiRegex,
  c as endsWithNumberRegex,
  g as forbiddenCharactersRegex,
  R as giftcardNumberRegex,
  a as naturalNumberRegex,
  t as nlPostalCodeRegex,
  r as notBlankRegex,
  x as phoneNumberRegex,
  $ as receiptNumberInputRegex,
  i as streetNameRegex,
  u as unsignedIntegerRegex,
  A as vatNumberInputRegex
};
