import { forwardRef, type SVGProps } from "react";

export interface MeasurementIconProps extends SVGProps<SVGSVGElement> {}

export const Measurement = forwardRef<SVGSVGElement, MeasurementIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M17.3415 0.965688C16.6581 0.28227 15.55 0.282271 14.8666 0.965688L0.724477 15.1078C0.0410585 15.7912 0.0410607 16.8993 0.724477 17.5827L6.15506 23.0133C6.83847 23.6967 7.94651 23.6967 8.62993 23.0133L22.7721 8.87114C23.4555 8.18772 23.4555 7.07969 22.7721 6.39627L17.3415 0.965688ZM15.9273 2.02635C16.0249 1.92872 16.1832 1.92872 16.2808 2.02635L21.7114 7.45693C21.809 7.55456 21.809 7.71285 21.7114 7.81048L20.1438 9.37813C20.1236 9.35179 20.1015 9.32648 20.0775 9.30242L18.4461 7.6711C18.1532 7.37821 17.6803 7.38014 17.3898 7.6754C17.0993 7.97067 17.1013 8.44747 17.3942 8.74036L19.021 10.3675L19.0255 10.3717C19.0466 10.3928 19.0686 10.4124 19.0915 10.4304L17.67 11.8519C17.6519 11.829 17.6324 11.807 17.6113 11.7859L16.5506 10.7252C16.2577 10.4323 15.7828 10.4323 15.4899 10.7252C15.197 11.0181 15.197 11.493 15.4899 11.7859L16.5506 12.8465C16.5717 12.8677 16.5937 12.8872 16.6166 12.9053L15.194 14.3279C15.1739 14.3015 15.1518 14.2762 15.1277 14.2522L13.3599 12.4844C13.067 12.1915 12.5941 12.1934 12.3036 12.4887C12.0131 12.784 12.0151 13.2608 12.308 13.5537L14.0757 15.3214C14.0968 15.3425 14.1189 15.3621 14.1417 15.3802L12.7202 16.8016C12.7022 16.7788 12.6826 16.7567 12.6615 16.7356L11.6008 15.675C11.308 15.3821 10.8331 15.3821 10.5402 15.675C10.2473 15.9679 10.2473 16.4427 10.5402 16.7356L11.6008 17.7963C11.6219 17.8174 11.644 17.837 11.6668 17.855L7.56927 21.9526C7.47164 22.0502 7.31335 22.0502 7.21572 21.9526L1.78514 16.522C1.68751 16.4244 1.68751 16.2661 1.78514 16.1685L15.9273 2.02635Z" />
    </svg>
  ),
);

(Measurement as React.FC).displayName = "Measurement";
