import { Brands } from "./Brands";

export type SupportedLanguages = "nl" | "nl-BE" | "fr-BE";

export interface LocaleConfig {
  locales: SupportedLanguages[];
  defaultLocale: SupportedLanguages;
}

const gammaNlLocaleConfig: LocaleConfig = {
  locales: ["nl"],
  defaultLocale: "nl",
};

const gammaBeLocaleConfig: LocaleConfig = {
  locales: ["nl-BE", "fr-BE"],
  defaultLocale: "nl-BE",
};

const karweiNlLocaleConfig: LocaleConfig = {
  locales: ["nl"],
  defaultLocale: "nl",
};

export function getLocaleConfig(brand: Brands) {
  switch (brand) {
    case "gamma_nl":
      return gammaNlLocaleConfig;
    case "gamma_be":
      return gammaBeLocaleConfig;
    case "karwei_nl":
      return karweiNlLocaleConfig;
    default:
      return gammaNlLocaleConfig;
  }
}
