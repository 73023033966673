import { forwardRef, type SVGProps } from "react";

export interface ProhibitedIconProps extends SVGProps<SVGSVGElement> {}

export const Prohibited = forwardRef<SVGSVGElement, ProhibitedIconProps>(
  (props, ref) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.791 1.36951C5.85395 1.36951 1.04102 6.18245 1.04102 12.1195C1.04102 18.0566 5.85395 22.8695 11.791 22.8695C17.7281 22.8695 22.541 18.0566 22.541 12.1195C22.541 6.18245 17.7281 1.36951 11.791 1.36951ZM2.54102 12.1195C2.54102 7.01087 6.68238 2.86951 11.791 2.86951C16.8996 2.86951 21.041 7.01087 21.041 12.1195C21.041 17.2281 16.8996 21.3695 11.791 21.3695C6.68238 21.3695 2.54102 17.2281 2.54102 12.1195Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20753 4.53222C4.49413 4.23316 4.9689 4.22306 5.26795 4.50966L19.5513 18.1979C19.8504 18.4845 19.8605 18.9592 19.5739 19.2583C19.2873 19.5573 18.8125 19.5674 18.5134 19.2808L4.2301 5.59264C3.93104 5.30604 3.92094 4.83128 4.20753 4.53222Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Prohibited as React.FC).displayName = "Prohibited";
