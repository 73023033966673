import { useState as a, useEffect as d } from "react";
import { dispatchAdobeEvent as E } from "@intergamma/adobe-tracking";
import { getDomain as m } from "@intergamma/common";
import e from "js-cookie";
const i = "sendIsLoggedInEvent", f = "kdbid";
function k({
  formulaId: s,
  customerId: t,
  hashedId: n
}) {
  const [g, r] = a(
    e.get(i) === "true"
  );
  d(() => {
    const o = m(s);
    !g && t && n && (E({
      type: "is_logged_in",
      data: {
        kdbid: t,
        kdbid_hashed: n
      }
    }), e.set(i, String(!0), { domain: o }), e.set(f, n, { domain: o }), r(!0)), t || (e.set(i, String(!1), { domain: o }), e.remove(f, { domain: o }), r(!1));
  }, [g, t, n, s]);
}
export {
  k as u
};
