import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface CheckMarkIconProps extends SVGProps<SVGSVGElement> {}

export const CheckMark = forwardRef<SVGSVGElement, CheckMarkIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("karwei:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8085 18.2455C10.5152 18.2733 10.2247 18.1704 10.0143 17.9642L5.29998 13.3442C4.90553 12.9576 4.89914 12.3245 5.2857 11.9301C5.67226 11.5356 6.30539 11.5292 6.69984 11.9158L10.568 15.7066L17.1783 6.17991C17.4932 5.72616 18.1162 5.61356 18.57 5.92841C19.0237 6.24325 19.1363 6.86633 18.8215 7.32008L11.5358 17.8201C11.3679 18.0621 11.1018 18.2178 10.8085 18.2455Z"
          fill="currentColor"
        />
      </svg>
      <svg
        className={cn("gamma:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9105 6.28542L10.5691 18.2761L5.14648 12.8534L5.85359 12.1463L10.4309 16.7237L18.0896 5.71436L18.9105 6.28542Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(CheckMark as React.FC).displayName = "CheckMark";
