import { useEffect, useState } from "react";

import { TextField } from "@intergamma/ui/form";
import { Button } from "@intergamma/button";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";

import { SideBar } from "~/components/SideBar/SideBar";

import { useSessionData } from "../session-data/SessionDataContext";
import { QUERY_KEY as PROFILE_QUERY } from "../personal-information/hooks/useGetPersonalInformation";

import { useUpdateEmail } from "./hooks/useUpdateEmail";
import { useChangeEmailForm } from "./hooks/useChangeEmailForm";
import { useVerifyChange } from "./hooks/useVerifyChange";
import { VerifyChange } from "./VerifyChange";

import { EmailUpdateRequest } from "~/services/base-api/generated";

export function ChangeEmail() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { uid } = useSessionData();

  const { t } = useTranslation(["change-email", "urls"]);
  const [showSidebar, setShowSidebar] = useState(false);

  const { isSuccess, isPending, mutate, error, data } = useUpdateEmail();

  const {
    mutate: verify,
    isPending: isPendingVerification,
    isSuccess: isSuccessVerification,
    error: verificationError,
  } = useVerifyChange();

  const form = useChangeEmailForm();

  const {
    register,
    formState: { errors },
  } = form;

  const onSubmit = form.handleSubmit((values: EmailUpdateRequest) => {
    mutate(values);
  });

  useEffect(() => {
    setShowSidebar(true);
  }, []);

  const close = () => {
    setShowSidebar(false);
    setTimeout(() => {
      navigate(t("urls:/profile/personal-information"));
    }, 400);
  };

  const onSuccessfulVerification = () => {
    queryClient.resetQueries({
      queryKey: [PROFILE_QUERY],
    });
    close();
  };

  return (
    <SideBar title={t("change-email:title")} close={close} show={showSidebar}>
      <div>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-4">
            <div>{t("change-email:description")}</div>
            <div>
              <TextField
                {...register("currentEmail")}
                label={t("change-email:currentEmail")}
                error={errors.currentEmail?.message}
                disabled
              />
            </div>
            <div>
              <TextField
                {...register("newEmail")}
                label={t("change-email:newEmail")}
                type="email"
                error={errors.newEmail?.message || error?.message}
                disabled={isSuccess}
              />
            </div>
            {!isSuccess && (
              <div>
                <Button
                  type="submit"
                  $wide
                  $isLoading={isPending}
                  disabled={isPending}
                >
                  {t("change-email:saveChanges")}
                </Button>
              </div>
            )}
          </div>
        </form>

        {isSuccess && data && uid && (
          <VerifyChange
            isLoading={isPendingVerification}
            isSuccess={isSuccessVerification}
            verificationError={verificationError?.message ?? null}
            control={data?.control}
            onSubmit={(code) =>
              verify({ customerId: uid, hash: `${uid}_${code}` })
            }
            onSuccess={onSuccessfulVerification}
            onResend={onSubmit}
          />
        )}
      </div>
    </SideBar>
  );
}
