import { forwardRef, type SVGProps } from "react";

export interface ImageIconProps extends SVGProps<SVGSVGElement> {}

export const Image = forwardRef<SVGSVGElement, ImageIconProps>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5999 6.3C19.5999 7.57025 18.5702 8.6 17.2999 8.6C16.0297 8.6 14.9999 7.57025 14.9999 6.3C14.9999 5.02975 16.0297 4 17.2999 4C18.5702 4 19.5999 5.02975 19.5999 6.3ZM18.0999 6.3C18.0999 6.74183 17.7418 7.1 17.2999 7.1C16.8581 7.1 16.4999 6.74183 16.4999 6.3C16.4999 5.85817 16.8581 5.5 17.2999 5.5C17.7418 5.5 18.0999 5.85817 18.0999 6.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 0.5C1.84315 0.5 0.5 1.84315 0.5 3.5V16.5C0.5 18.1569 1.84315 19.5 3.5 19.5H20.5C22.1569 19.5 23.5 18.1569 23.5 16.5V3.5C23.5 1.84315 22.1569 0.5 20.5 0.5H3.5ZM20.5 2H3.5C2.67157 2 2 2.67157 2 3.5V13.4813L5.85089 7.99211C6.49477 7.07429 7.82354 6.99019 8.578 7.81951L13.8035 13.5636C13.8975 13.6668 14.0578 13.6731 14.1596 13.5776L16.7061 11.1862C17.4449 10.4924 18.6167 10.5694 19.2585 11.3537L22 14.7045V3.5C22 2.67157 21.3284 2 20.5 2ZM2 16.5V16.0592C2.04193 16.0217 2.08026 15.9788 2.11398 15.9307L7.07886 8.85356C7.17084 8.72245 7.36066 8.71043 7.46844 8.82891L12.694 14.573C13.3517 15.2959 14.474 15.34 15.1864 14.671L17.7329 12.2797C17.8385 12.1806 18.0059 12.1915 18.0975 12.3036L21.9195 16.9749L21.9222 16.9781C21.7226 17.5721 21.1612 18 20.5 18H3.5C2.67157 18 2 17.3284 2 16.5Z"
      fill="currentColor"
    />
  </svg>
));

(Image as React.FC).displayName = "Image";
