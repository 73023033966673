import { jsx as e } from "react/jsx-runtime";
import { cn as n } from "@intergamma/common";
import * as o from "@radix-ui/react-dialog";
const a = ({
  children: r,
  className: i,
  ...t
}) => /* @__PURE__ */ e(
  o.Trigger,
  {
    ...t,
    className: n(
      "h-auto cursor-pointer border-none bg-transparent focus:shadow-none",
      i
    ),
    children: r
  }
), l = o.Description, c = o.Title, m = o.Trigger;
export {
  a as DialogTrigger,
  l as UnstyledDialogDescription,
  c as UnstyledDialogTitle,
  m as UnstyledDialogTrigger
};
