var p = (r) => {
  throw TypeError(r);
};
var m = (r, t, i) => t.has(r) || p("Cannot " + i);
var o = (r, t, i) => (m(r, t, "read from private field"), i ? i.call(r) : t.get(r)), u = (r, t, i) => t.has(r) ? p("Cannot add the same private member more than once") : t instanceof WeakSet ? t.add(r) : t.set(r, i), d = (r, t, i, n) => (m(r, t, "write to private field"), n ? n.call(r, i) : t.set(r, i), i);
import l from "js-cookie";
import { experiments as a } from "./config.js";
var c, e, f;
class k {
  constructor() {
    u(this, c, !1);
    u(this, e);
    u(this, f);
  }
  configure(t) {
    d(this, c, t.dev), d(this, e, t.formula), d(this, f, t.onCoinflip);
    for (const [i, n] of Object.entries(a))
      n.scope === "domain" && (this.getVariant(i) || this.setVariant(
        i,
        this.coinflip(i)
      ));
  }
  /**
   * Get active experiments with their chosen variant.
   */
  get active() {
    return Object.entries(a).reduce((t, [i, n]) => {
      const s = l.get(i) ?? "";
      return s in n.variants ? {
        ...t,
        [i]: {
          domain_and_index: n.domain_and_index,
          scope: n.scope,
          variant: s
        }
      } : t;
    }, {});
  }
  getVariant(t) {
    const i = a[t], n = l.get(t) ?? "";
    if (!i)
      throw Error(
        `Could not find experiment "${t}". Make sure to run \`pnpm run build:packages\` if this is a new experiment.`
      );
    return o(this, e) && !i.formula[o(this, e).id] ? "A-control" : Object.keys(i.variants).includes(n) ? n : void 0;
  }
  setVariant(t, i) {
    var n;
    o(this, e) && (l.set(t, i, {
      expires: 35,
      domain: o(this, e).baseDomain,
      sameSite: "strict"
    }), (n = o(this, f)) == null || n.call(
      this,
      t,
      a[t],
      i
    ));
  }
  coinflip(t) {
    const i = a[t], n = Object.keys(i.variants).find(
      (h) => h.startsWith("A-")
    );
    if (o(this, c) && n)
      return n;
    const s = Math.random() * 100;
    for (const [h, v] of Object.entries(i.variants)) {
      const [b, g] = v.allocation;
      if (s >= b && s < g)
        return h;
    }
    throw new Error(`Failed choosing random variant for "${t}"`);
  }
}
c = new WeakMap(), e = new WeakMap(), f = new WeakMap();
const O = new k();
export {
  O as experiments
};
