import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface ChevronUpIconProps extends SVGProps<SVGSVGElement> {}

export const ChevronUp = forwardRef<SVGSVGElement, ChevronUpIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("karwei:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 8.59473L17.5303 14.1251C17.8232 14.4179 17.8232 14.8928 17.5303 15.1857C17.2374 15.4786 16.7626 15.4786 16.4697 15.1857L12 10.716L7.53033 15.1857C7.23744 15.4786 6.76256 15.4786 6.46967 15.1857C6.17678 14.8928 6.17678 14.4179 6.46967 14.1251L12 8.59473Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className={cn("gamma:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9967 7.74658L19.0377 14.656L18.3373 15.3697L12.0034 9.15416L5.79109 15.3664L5.08398 14.6593L11.9967 7.74658Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(ChevronUp as React.FC).displayName = "ChevronUp";
