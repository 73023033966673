import { useEffect } from "react";

import { useConfig } from "@intergamma/config";
import { useIsApp } from "@intergamma/devices";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

export function Chatbot() {
  const { t } = useTranslation("urls");
  const { brand, country } = useConfig();
  const { pathname } = useLocation();
  const { code } = useParams();

  const enabledPages = [
    t("/myaccount"),
    t("/service-and-contact/contact"),
    t("/loyaltyMutations"),
    t("/profile/loyaltycards"),
    t("/profile/personal-information"),
    t("/profile/personal-information/delete"),
    t("/profile/newsletter"),
    t("/purchases"),
    `${t("/purchases")}/${code}`,
  ];

  const isApp = useIsApp();

  const isChatbotEnabled =
    enabledPages.some((page) => pathname === page) && !isApp;

  useEffect(() => {
    // Hide the chatbot element on pages where it's not enabled.
    // Since the element is generated by the chatbot script we don't have direct control over it.
    const element = document.getElementById("fc_frame");

    if (!isChatbotEnabled) {
      element?.setAttribute("style", "display: none");
    } else {
      element?.setAttribute("style", "display: block");
    }
  }, [isChatbotEnabled]);

  if (country !== "nl" || !isChatbotEnabled) {
    return null;
  }

  // The chatbot script requires a `chat` and `widgetId` prop to be passed to the script tag.
  // This is a bad practice because they're not semantically correct attributes for the script tag.
  // We don't have control over the chatbot script so we can't change this.
  // We set the custom props here to prevent typescript errors.
  const customProps = {
    chat: true,
    widgetId:
      brand === "karwei" ? "63cda9f5-d959-470a-af77-2cfca4bf2a84" : undefined,
  };

  // We want to load the chatbot script only once and only on pages where it's enabled.
  // We can't use the `useEffect` hook for this because it would be called on every route change.
  // Instead, we use the `Helmet` component to load the script in the head.
  return (
    <Helmet>
      <script
        src="https://eu.fw-cdn.com/11226867/415211.js"
        async
        {...customProps}
      />
    </Helmet>
  );
}
