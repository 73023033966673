import type {
  LoyaltyPointBalanceResponse,
  LoyaltyPointBalance,
} from "../types/LoyaltyPoints";

export function mapLoyaltyBalanceResponse(
  balance: LoyaltyPointBalanceResponse,
): LoyaltyPointBalance {
  return {
    ...balance,
    mutations: balance.mutations.map((mutation) => ({
      ...mutation,
      timestamp: new Date(mutation.timestamp),
    })),
  };
}
