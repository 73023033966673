import { forwardRef, type SVGProps } from "react";

export interface LogoutIconProps extends SVGProps<SVGSVGElement> {}

export const Logout = forwardRef<SVGSVGElement, LogoutIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M4.5 2.25C2.98122 2.25 1.75 3.48122 1.75 5V19.5C1.75 21.0188 2.98122 22.25 4.5 22.25H9.5C9.91421 22.25 10.25 21.9142 10.25 21.5C10.25 21.0858 9.91421 20.75 9.5 20.75H4.5C3.80964 20.75 3.25 20.1904 3.25 19.5V5C3.25 4.30964 3.80964 3.75 4.5 3.75H9.5C9.91421 3.75 10.25 3.41421 10.25 3C10.25 2.58579 9.91421 2.25 9.5 2.25H4.5Z"
        fill="currentColor"
      />
      <path
        d="M17.5644 8.00612C17.2917 7.69439 16.8178 7.66281 16.5061 7.93557C16.1944 8.20833 16.1628 8.68215 16.4356 8.99388L18.8472 11.75H9.5C9.08579 11.75 8.75 12.0858 8.75 12.5C8.75 12.9142 9.08579 13.25 9.5 13.25H18.8472L16.4356 16.0061C16.1628 16.3178 16.1944 16.7917 16.5061 17.0644C16.8178 17.3372 17.2917 17.3056 17.5644 16.9939L21.4966 12.5L17.5644 8.00612Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Logout as React.FC).displayName = "Logout";
