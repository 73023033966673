import {
  ComponentProps,
  ComponentPropsWithRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useId,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";

type VerifyCodeFieldProps = ComponentPropsWithRef<"input"> & {
  label: string;
  description?: ReactNode;
  error?: ReactNode;
  info?: ComponentProps<typeof Label>["info"];
  icon?: ReactNode;
  optional?: ComponentProps<typeof Label>["optional"];
  controlCharacter?: string;
  controlCharacterProps?: HTMLAttributes<HTMLDivElement> & {
    [dataAttibute: `data-${string}`]: string;
  };
};

export const VerifyCodeField = forwardRef<
  HTMLInputElement,
  VerifyCodeFieldProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function VerifyCodeField(
    {
      className = "",
      label,
      description,
      error,
      info,
      optional,
      controlCharacter,
      maxLength = 4,
      controlCharacterProps = {},
      ...inputProps
    },
    ref,
  ) {
    const id = useId();
    const { className: controlCharacterClassName, ...controlProps } =
      controlCharacterProps;

    return (
      <div className={className}>
        <Label htmlFor={id} info={info} optional={optional}>
          {label}
        </Label>
        <div className="mx-auto flex justify-center gap-1">
          <input
            className={cn(
              "h-10 w-full pl-[calc(50%-56px)] pt-[0.2rem] leading-4 tracking-[0.8rem]",
              "border outline-none transition-colors gamma:rounded-md gamma:border-ignew-neutral-200 gamma:caret-brand-conversion karwei:border-ignew-neutral-100 karwei:caret-brand-primary",
              "gamma:focus:border-ignew-functional-primary-800 karwei:focus:border-ignew-neutral-1000",
              error &&
                "gamma:!border-ignew-functional-quaternary-700 karwei:!border-ignew-functional-quaternary-600",
            )}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="____"
            id={id}
            ref={ref}
            maxLength={maxLength}
            {...inputProps}
          />
          {controlCharacter && (
            <div
              className={cn(
                "flex size-10 shrink-0 items-center justify-center border gamma:rounded-md gamma:border-ignew-neutral-200 gamma:text-brand-promotion karwei:rounded-none karwei:border-ignew-neutral-100 karwei:text-brand-conversion",
                controlCharacterClassName,
              )}
              {...controlProps}
            >
              {controlCharacter}
            </div>
          )}
        </div>
        {error && <Error className="mt-1">{error}</Error>}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
