import React, { forwardRef, type SVGProps } from "react";

export interface ExclamationMarkIconProps extends SVGProps<SVGSVGElement> {}

export const ExclamationMark = forwardRef<
  SVGSVGElement,
  ExclamationMarkIconProps
>((props, ref) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.9202 6.6C12.362 6.6 12.7202 6.95817 12.7202 7.4V13.4C12.7202 13.8418 12.362 14.2 11.9202 14.2C11.4783 14.2 11.1202 13.8418 11.1202 13.4V7.4C11.1202 6.95817 11.4783 6.6 11.9202 6.6Z"
      fill="currentcolor"
    />
    <path
      d="M12.8 16.4C12.8 15.9582 12.4418 15.6 12 15.6C11.5582 15.6 11.2 15.9582 11.2 16.4V16.496C11.2 16.9378 11.5582 17.296 12 17.296C12.4418 17.296 12.8 16.9378 12.8 16.496V16.4Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
      fill="currentcolor"
    />
  </svg>
));

(ExclamationMark as React.FC).displayName = "ExclamationMark";
