import type { LocalDateResponse } from "~/services/types/LocalDateResponse";

export function mapLocalDateResponse([
  year,
  month,
  day,
]: LocalDateResponse): Date {
  return new Date(year, month - 1, day);
}
export function mapLocalDateTimeResponse([
  year,
  month,
  day,
  hours,
  minutes,
]: LocalDateResponse): Date {
  return new Date(year, month - 1, day, hours, minutes);
}
