import { IHttpClient } from "@intergamma/account";

import { AxiosHeaders } from "axios";

import { createCsrfService } from "~/services/csrf-service";

export function withGatewayCsrfToken(
  MYACCOUNT_URL: string,
  client: IHttpClient,
) {
  const csrfService = createCsrfService(MYACCOUNT_URL);

  const handler: ProxyHandler<IHttpClient> = {
    get: (target, prop: keyof IHttpClient) => {
      if (prop === "post") {
        const calledFunction = target[prop];

        const postRequest: IHttpClient["post"] = async (
          endpoint,
          body,
          config,
        ) => {
          const csrfToken = await csrfService.getGatewayCSRF();

          const csrfHeaders = new AxiosHeaders({
            "X-IG-CSRF-TOKEN": csrfToken, // Handle both tokens till migration
          });

          const baseHeaders = config?.headers as AxiosHeaders;

          const headers = AxiosHeaders.concat(csrfHeaders, baseHeaders);

          return calledFunction(endpoint, body, {
            ...config,
            headers,
          });
        };

        return postRequest;
      }

      if (prop === "put") {
        const calledFunction = target[prop];

        const putRequest: IHttpClient["put"] = async (
          endpoint,
          body,
          config,
        ) => {
          const csrfToken = await csrfService.getGatewayCSRF();

          const csrfHeaders = new AxiosHeaders({
            "X-IG-CSRF-TOKEN": csrfToken, // Handle both tokens till migration
          });

          const baseHeaders = config?.headers as AxiosHeaders;
          const headers = AxiosHeaders.concat(csrfHeaders, baseHeaders);

          return calledFunction(endpoint, body, {
            ...config,
            headers,
          });
        };

        return putRequest;
      }

      if (prop === "delete") {
        const calledFunction = target[prop];

        const deleteRequest: IHttpClient["delete"] = async (
          endpoint,
          config,
        ) => {
          const csrfToken = await csrfService.getGatewayCSRF();

          const csrfHeaders = new AxiosHeaders({
            "X-IG-CSRF-TOKEN": csrfToken, // Handle both tokens till migration
          });

          const baseHeaders = config?.headers as AxiosHeaders;
          const headers = AxiosHeaders.concat(csrfHeaders, baseHeaders);

          return calledFunction(endpoint, {
            ...config,
            headers,
          });
        };

        return deleteRequest;
      }

      return target[prop];
    },
  };

  return new Proxy(client, handler);
}
