import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface ChevronLeftIconProps extends SVGProps<SVGSVGElement> {}

export const ChevronLeft = forwardRef<SVGSVGElement, ChevronLeftIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("karwei:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59473 12L14.1251 6.46967C14.4179 6.17678 14.8928 6.17678 15.1857 6.46967C15.4786 6.76256 15.4786 7.23744 15.1857 7.53033L10.716 12L15.1857 16.4697C15.4786 16.7626 15.4786 17.2374 15.1857 17.5303C14.8928 17.8232 14.4179 17.8232 14.1251 17.5303L8.59473 12Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className={cn("gamma:hidden", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.07715 11.7975L14.9866 4.75645L15.7003 5.45685L9.48472 11.7908L15.697 18.0031L14.9899 18.7102L8.07715 11.7975Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(ChevronLeft as React.FC).displayName = "ChevronLeft";
