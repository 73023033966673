import { jsx as e } from "react/jsx-runtime";
import { cn as t } from "@intergamma/common/cn";
const r = "div", c = ({
  as: m,
  children: n,
  className: o
}) => /* @__PURE__ */ e(
  m ?? r,
  {
    className: t(
      "mx-auto px-4 md:max-w-screen-md md:px-6 lg:max-w-screen-lg lg:px-8 xl:max-w-screen-xl",
      o
    ),
    children: n
  }
);
export {
  c as Container
};
