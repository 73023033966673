import { forwardRef, type SVGProps } from "react";

export interface PaintBrushIconProps extends SVGProps<SVGSVGElement> {}

export const PaintBrush = forwardRef<SVGSVGElement, PaintBrushIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4448 1.88407C12.3708 0.810127 10.6296 0.810128 9.55568 1.88407L3.69812 7.74163C2.23365 9.20609 2.23366 11.5805 3.69812 13.0449L5.43416 14.781L1.63754 18.5776C0.435924 19.7792 0.435925 21.7274 1.63754 22.929C2.83915 24.1306 4.78736 24.1306 5.98897 22.929L9.78559 19.1324L10.8635 20.2103C12.3279 21.6748 14.7023 21.6748 16.1668 20.2103L22.0243 14.3527C23.0983 13.2788 23.0983 11.5376 22.0243 10.4636L13.4448 1.88407ZM10.6163 2.94473C11.1045 2.45657 11.8959 2.45657 12.3841 2.94473L14.8216 5.38225L12.8306 7.37331C12.5377 7.6662 12.5377 8.14108 12.8306 8.43397C13.1235 8.72687 13.5983 8.72687 13.8912 8.43397L15.8823 6.44291L17.0912 7.65181L16.1812 8.56184C15.8883 8.85473 15.8883 9.3296 16.1812 9.6225C16.4741 9.91539 16.9489 9.91539 17.2418 9.6225L18.1518 8.71247L20.9637 11.5243C21.4518 12.0125 21.4518 12.8039 20.9637 13.2921L16.9573 17.2985L6.76108 7.1023C6.70856 7.04978 6.65019 7.00668 6.58807 6.973L10.6163 2.94473ZM5.57112 7.98995L4.75878 8.80229C3.8801 9.68097 3.8801 11.1056 4.75878 11.9843L7.55548 14.781L2.6982 19.6383C2.08237 20.2541 2.08237 21.2525 2.6982 21.8684C3.31403 22.4842 4.31248 22.4842 4.92831 21.8684L9.78559 17.0111L11.9241 19.1496C12.8028 20.0283 14.2274 20.0283 15.1061 19.1496L15.8966 18.3591L5.70042 8.16296C5.6479 8.11044 5.6048 8.05207 5.57112 7.98995Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(PaintBrush as React.FC).displayName = "PaintBrush";
