import { useMemo, useState, useLayoutEffect, useRef } from "react";

export function useIsMobile() {
  const mql = useMemo(() => window.matchMedia("(min-width: 768px)"), []);
  const isAlive = useRef(true);
  const [isMobile, setIsMobile] = useState(!mql.matches);

  useLayoutEffect(() => {
    mql.onchange = (e) => {
      if (isAlive.current) {
        setIsMobile(!e.matches);
      }
    };

    return () => {
      isAlive.current = false;
    };
  }, [mql]);

  return isMobile;
}
