import { useEffect } from "react";

interface DocumentTitleProps {
  appTitle: string;
  pageTitle?: string;
}

export function useDocumentTitle({ appTitle, pageTitle }: DocumentTitleProps) {
  useEffect(() => {
    document.title =
      pageTitle === appTitle || pageTitle === "title" || !pageTitle
        ? appTitle
        : `${pageTitle} - ${appTitle}`;
  }, [appTitle, pageTitle]);
}
