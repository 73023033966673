import { Suspense } from "react";

import { LoadingHeader } from "@intergamma/header";
import { MainLegalFooter } from "@intergamma/legal-footer";
import { Footer } from "@intergamma/footer";
import { useTrackLoggedIn } from "@intergamma/account";

import { SessionDataProvider } from "./features/session-data/SessionDataContext";
import { Header } from "./layouts/Header";
import { LoadingPageLayout } from "./layouts/LoadingPageLayout";
import { Routes } from "./Routes";
import { ScrollToTop } from "./components/ScrollToTop";
import { useSessionDataQuery } from "./features/session-data/hooks/useSessionDataQuery";
import { useMyAccountConfig } from "./config/MyAccountConfigContext";

export function App() {
  const { data: sessionData } = useSessionDataQuery();
  const {
    baseConfig: { fullBrand },
  } = useMyAccountConfig();

  useTrackLoggedIn({
    formulaId: fullBrand,
    customerId: sessionData?.uid,
    hashedId: sessionData?.hashedId,
  });

  return (
    <Suspense fallback={<LoadingHeader />}>
      <SessionDataProvider>
        <Header />
        <Suspense fallback={<LoadingPageLayout />}>
          <ScrollToTop />
          <Routes />
        </Suspense>
        <Footer>
          <MainLegalFooter />
        </Footer>
      </SessionDataProvider>
    </Suspense>
  );
}
