import type {
  ReceiptSummaryResponse,
  ReceiptSummary,
} from "../types/ReceiptSummary";

export function mapReceiptSummaryResponse(
  receiptsResponse: ReceiptSummaryResponse[],
): ReceiptSummary[] {
  return receiptsResponse.map((receipt) => ({
    ...receipt,
    grandTotal: {
      amount: receipt.grandTotal.amountIncludingVat,
      currency: receipt.grandTotal.currencyCode,
    },
    createdOn: new Date(receipt.createdOn),
  }));
}
