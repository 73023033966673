function s() {
  return typeof window < "u" && (window == null ? void 0 : window.navigator.userAgent.includes("IGApp"));
}
function u(e) {
  const n = s();
  return !!(e.includes("app") && n);
}
export {
  u as a,
  s as u
};
