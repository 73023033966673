import { media as u } from "@intergamma/theme";
import g, { css as c } from "styled-components";
const i = 15, f = 20, m = 12, r = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
  (t) => `${100 / m * t}%`
), a = (t) => (e) => e.$reverse ? `flex-direction: ${t}-reverse` : `flex-direction: ${t}`, $ = (t) => (e) => {
  if (e.$start === t) return "justify-content: flex-start";
  if (e.$center === t) return "justify-content: center";
  if (e.$end === t) return "justify-content: flex-end";
  if ("around" in e && e.around === t)
    return "justify-content: space-around";
  if ("between" in e && e.between === t)
    return "justify-content: space-between";
  if (t === "xs") return "justify-content: flex-start";
}, o = (t) => (e) => e.$start === t ? "text-align: start" : e.$center === t ? "text-align: center" : e.$end === t ? "text-align: end" : "text-align: start", w = (t) => (e) => e.$top === t ? "align-items: flex-start" : e.$middle === t ? "align-items: center" : e.$bottom === t ? "align-items: flex-end" : "align-items: stretch", s = (t) => (e) => {
  if (t in e) {
    const n = e[t];
    if (n && r[n])
      return `flex-basis: ${r[n]}`;
  }
  if (t === "$xs") return "flex-basis: auto";
}, x = (t) => (e) => {
  if (t in e) {
    const n = e[t];
    if (n && r[n])
      return `max-width: ${r[n]}`;
  }
  if (t === "$xs") return "max-width: 100%";
}, l = (t) => (e) => {
  if (`${t}Offset` in e) {
    const n = e[`${t}Offset`];
    if (n && r[n])
      return `margin-left: ${r[n]}`;
  }
  if (t === "$xs") return "margin-left: 0";
}, d = (t) => (e) => {
  if (e.$first === t) return "order: -1";
  if (e.$last === t) return "order: 1";
  if (e.$initialOrder === t) return "order: initial";
  if (t === "xs") return "order: 0";
}, h = g.div`
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  ${a("row")};
  flex-wrap: wrap;
  margin-left: ${i * -0.5}px;
  margin-right: ${i * -0.5}px;
  ${$("xs")};
  ${o("xs")};
  ${w("xs")};

  ${u.md`
    margin-left: ${f * -0.5}px;
    margin-right: ${f * -0.5}px;
  `};
`, j = g.div`
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
  ${s("$xs")};
  ${a("column")};
  ${x("$xs")};
  padding-right: ${i / 2}px;
  padding-left: ${i / 2}px;
  padding-bottom: ${i}px;
  ${l("$xs")};
  ${d("xs")};
  ${(t) => (t.$start || t.$center || t.$end) && c`
      display: flex;
    `};
  ${$("xs")};

  & > ${h} {
    margin-bottom: ${i * -1}px;
  }

  ${u.md`
    ${s("$md")};
    ${x("$md")};
    padding-right: ${f / 2}px;
    padding-left: ${f / 2}px;
    padding-bottom: ${f}px;
    ${l("$md")};
    ${d("md")};
    ${$("md")};
  `};

  ${u.lg`
    ${s("$lg")};
    ${x("$lg")};
    ${l("$lg")};
    ${d("lg")};
    ${$("lg")};
  `};

  ${u.xl`
    ${s("$xl")};
    ${x("$xl")};
    ${l("$xl")};
    ${d("xl")};
    ${$("xl")};
  `};
`;
export {
  j as Col,
  h as Row
};
