import { lazy, Suspense } from "react";

import styled from "styled-components";

import type { Icon as IconOption } from "~/components/icons";

const Brush = lazy(() =>
  import("~/components/icons/Brush").then((component) => ({
    default: component.Brush,
  })),
);

const Calendar = lazy(() =>
  import("~/components/icons/Calendar").then((component) => ({
    default: component.Calendar,
  })),
);

const Cart = lazy(() =>
  import("~/components/icons/Cart").then((component) => ({
    default: component.Cart,
  })),
);

const Chart = lazy(() =>
  import("~/components/icons/Chart").then((component) => ({
    default: component.Chart,
  })),
);

const Chat = lazy(() =>
  import("~/components/icons/Chat").then((component) => ({
    default: component.Chat,
  })),
);

const Clock = lazy(() =>
  import("~/components/icons/Clock").then((component) => ({
    default: component.Clock,
  })),
);

const Correct = lazy(() =>
  import("~/components/icons/Correct").then((component) => ({
    default: component.Correct,
  })),
);

const CreditCard = lazy(() =>
  import("~/components/icons/CreditCard").then((component) => ({
    default: component.CreditCard,
  })),
);

const Delete = lazy(() =>
  import("~/components/icons/Delete").then((component) => ({
    default: component.Delete,
  })),
);

const Download = lazy(() =>
  import("~/components/icons/Download").then((component) => ({
    default: component.Download,
  })),
);

const Edit = lazy(() =>
  import("~/components/icons/Edit").then((component) => ({
    default: component.Edit,
  })),
);

const Enlarge = lazy(() =>
  import("~/components/icons/Enlarge").then((component) => ({
    default: component.Enlarge,
  })),
);

const Eye = lazy(() =>
  import("~/components/icons/Eye").then((component) => ({
    default: component.Eye,
  })),
);

const Gift = lazy(() =>
  import("~/components/icons/Gift").then((component) => ({
    default: component.Gift,
  })),
);

const Home = lazy(() =>
  import("~/components/icons/Home").then((component) => ({
    default: component.Home,
  })),
);

const Loading = lazy(() =>
  import("~/components/icons/Loading").then((component) => ({
    default: component.Loading,
  })),
);

const Location = lazy(() =>
  import("~/components/icons/Location").then((component) => ({
    default: component.Location,
  })),
);

const LoggedIn = lazy(() =>
  import("~/components/icons/LoggedIn").then((component) => ({
    default: component.LoggedIn,
  })),
);

const LoggedOut = lazy(() =>
  import("~/components/icons/LoggedOut").then((component) => ({
    default: component.LoggedOut,
  })),
);

const User = lazy(() =>
  import("~/components/icons/User").then((component) => ({
    default: component.User,
  })),
);

const Logout = lazy(() =>
  import("~/components/icons/Logout").then((component) => ({
    default: component.Logout,
  })),
);

const NewWindow = lazy(() =>
  import("~/components/icons/NewWindow").then((component) => ({
    default: component.NewWindow,
  })),
);

const Package = lazy(() =>
  import("~/components/icons/Package").then((component) => ({
    default: component.Package,
  })),
);

const Pen = lazy(() =>
  import("~/components/icons/Pen").then((component) => ({
    default: component.Pen,
  })),
);

const Photo = lazy(() =>
  import("~/components/icons/Photo").then((component) => ({
    default: component.Photo,
  })),
);

const Play = lazy(() =>
  import("~/components/icons/Play").then((component) => ({
    default: component.Play,
  })),
);

const Receipt = lazy(() =>
  import("~/components/icons/Receipt").then((component) => ({
    default: component.Receipt,
  })),
);

const Remove = lazy(() =>
  import("~/components/icons/Remove").then((component) => ({
    default: component.Remove,
  })),
);

const RotateCCW = lazy(() =>
  import("~/components/icons/RotateCCW").then((component) => ({
    default: component.RotateCCW,
  })),
);

const Search = lazy(() =>
  import("~/components/icons/Search").then((component) => ({
    default: component.Search,
  })),
);

const Service = lazy(() =>
  import("~/components/icons/Service").then((component) => ({
    default: component.Service,
  })),
);

const Share = lazy(() =>
  import("~/components/icons/Share").then((component) => ({
    default: component.Share,
  })),
);

const Star = lazy(() =>
  import("~/components/icons/Star").then((component) => ({
    default: component.Star,
  })),
);

const Tool = lazy(() =>
  import("~/components/icons/Tool").then((component) => ({
    default: component.Tool,
  })),
);

const Truck = lazy(() =>
  import("~/components/icons/Truck").then((component) => ({
    default: component.Truck,
  })),
);

const WhatsApp = lazy(() =>
  import("~/components/icons/WhatsApp").then((component) => ({
    default: component.WhatsApp,
  })),
);

interface IconProps {
  icon: IconOption;
}

function IconView({ icon }: IconProps) {
  switch (icon) {
    case "Brush":
      return (
        <StyledIconContainer>
          <Brush />
        </StyledIconContainer>
      );
    case "Calendar":
      return (
        <StyledIconContainer>
          <Calendar />
        </StyledIconContainer>
      );
    case "Cart":
      return (
        <StyledIconContainer>
          <Cart />
        </StyledIconContainer>
      );
    case "Chart":
      return (
        <StyledIconContainer>
          <Chart />
        </StyledIconContainer>
      );
    case "Chat":
      return (
        <StyledIconContainer>
          <Chat />
        </StyledIconContainer>
      );
    case "Clock":
      return (
        <StyledIconContainer>
          <Clock />
        </StyledIconContainer>
      );
    case "Correct":
      return (
        <StyledIconContainer>
          <Correct />
        </StyledIconContainer>
      );
    case "CreditCard":
      return (
        <StyledIconContainer>
          <CreditCard />
        </StyledIconContainer>
      );
    case "Delete":
      return (
        <StyledIconContainer>
          <Delete />
        </StyledIconContainer>
      );
    case "Download":
      return (
        <StyledIconContainer>
          <Download />
        </StyledIconContainer>
      );
    case "Edit":
      return (
        <StyledIconContainer>
          <Edit />
        </StyledIconContainer>
      );
    case "Enlarge":
      return (
        <StyledIconContainer>
          <Enlarge />
        </StyledIconContainer>
      );
    case "Eye":
      return (
        <StyledIconContainer>
          <Eye />
        </StyledIconContainer>
      );
    case "Gift":
      return (
        <StyledIconContainer>
          <Gift />
        </StyledIconContainer>
      );
    case "Home":
      return (
        <StyledIconContainer>
          <Home />
        </StyledIconContainer>
      );
    case "Loading":
      return (
        <StyledIconContainer>
          <Loading />
        </StyledIconContainer>
      );
    case "Location":
      return (
        <StyledIconContainer>
          <Location />
        </StyledIconContainer>
      );
    case "LoggedIn":
      return (
        <StyledIconContainer>
          <LoggedIn />
        </StyledIconContainer>
      );
    case "LoggedOut":
      return (
        <StyledIconContainer>
          <LoggedOut />
        </StyledIconContainer>
      );
    case "User":
      return (
        <StyledIconContainer>
          <User />
        </StyledIconContainer>
      );
    case "Logout":
      return (
        <StyledIconContainer>
          <Logout />
        </StyledIconContainer>
      );
    case "NewWindow":
      return (
        <StyledIconContainer>
          <NewWindow />
        </StyledIconContainer>
      );
    case "Package":
      return (
        <StyledIconContainer>
          <Package />
        </StyledIconContainer>
      );
    case "Pen":
      return (
        <StyledIconContainer>
          <Pen />
        </StyledIconContainer>
      );
    case "Photo":
      return (
        <StyledIconContainer>
          <Photo />
        </StyledIconContainer>
      );
    case "Play":
      return (
        <StyledIconContainer>
          <Play />
        </StyledIconContainer>
      );
    case "Receipt":
      return (
        <StyledIconContainer>
          <Receipt />
        </StyledIconContainer>
      );
    case "Remove":
      return (
        <StyledIconContainer>
          <Remove />
        </StyledIconContainer>
      );
    case "RotateCCW":
      return (
        <StyledIconContainer>
          <RotateCCW />
        </StyledIconContainer>
      );
    case "Search":
      return (
        <StyledIconContainer>
          <Search />
        </StyledIconContainer>
      );
    case "Service":
      return (
        <StyledIconContainer>
          <Service />
        </StyledIconContainer>
      );
    case "Share":
      return (
        <StyledIconContainer>
          <Share />
        </StyledIconContainer>
      );
    case "Star":
      return (
        <StyledIconContainer>
          <Star />
        </StyledIconContainer>
      );
    case "Tool":
      return (
        <StyledIconContainer>
          <Tool />
        </StyledIconContainer>
      );
    case "Truck":
      return (
        <StyledIconContainer>
          <Truck />
        </StyledIconContainer>
      );
    case "WhatsApp":
      return (
        <StyledIconContainer>
          <WhatsApp />
        </StyledIconContainer>
      );
    default:
      return null;
  }
}

const StyledIconContainer = styled.div`
  svg {
    width: 1rem;
    height: 1rem;
    stroke: ${({ theme }) => theme.colors.primary};
    margin-right: 0.875rem;
    position: relative;
    top: 2px;
  }
`;

export function Icon(props: IconProps) {
  return (
    <Suspense fallback={null}>
      <IconView {...props} />
    </Suspense>
  );
}
