import { forwardRef, type SVGProps } from "react";

export interface FolderIconProps extends SVGProps<SVGSVGElement> {}

export const Folder = forwardRef<SVGSVGElement, FolderIconProps>(
  (props, ref) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.99 14.8146C16.4377 14.8146 15.99 14.3669 15.99 13.8146V8.42001C15.99 7.86773 16.4377 7.42001 16.99 7.42001H20.7198C21.2721 7.42001 21.7198 7.86773 21.7198 8.42001V13.8146C21.7198 14.3669 21.2721 14.8146 20.7198 14.8146H16.99ZM20.2198 13.3146H17.49V8.92001H20.2198V13.3146Z"
      />
      <path d="M5.24254 9.64174C4.91651 9.64174 4.65222 9.90603 4.65222 10.2321C4.65222 10.5581 4.91651 10.8224 5.24254 10.8224H7.55216C7.87818 10.8224 8.14247 10.5581 8.14247 10.2321C8.14247 9.90603 7.87818 9.64174 7.55216 9.64174H5.24254Z" />
      <path d="M4.65222 12.3277C4.65222 12.0017 4.91651 11.7374 5.24254 11.7374H9.46331C9.78933 11.7374 10.0536 12.0017 10.0536 12.3277C10.0536 12.6537 9.78933 12.918 9.46331 12.918H5.24254C4.91651 12.918 4.65222 12.6537 4.65222 12.3277Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.958008 4.31043C0.958008 3.06778 1.96537 2.06042 3.20801 2.06042H9.29639L9.33289 2.064C10.0614 2.13542 11.1256 2.4477 12.0325 3.16666C12.3924 3.45193 12.7249 3.79983 12.9996 4.21604C13.2743 3.79983 13.6068 3.45193 13.9667 3.16666C14.8736 2.4477 15.9378 2.13542 16.6663 2.064L16.7028 2.06042H22.7912C24.0338 2.06042 25.0412 3.06778 25.0412 4.31043V17.5646C25.0412 18.8072 24.0338 19.8146 22.7912 19.8146H17.6744C16.5086 19.8146 15.6995 19.9985 15.1204 20.2867C14.5548 20.5681 14.1623 20.9718 13.8613 21.5028C13.6585 21.8605 13.2938 21.9622 13.009 21.9301C12.749 21.9679 12.3811 21.9035 12.1507 21.5589C11.4748 20.5481 9.79041 19.8146 8.03706 19.8146H3.20801C1.96537 19.8146 0.958008 18.8072 0.958008 17.5646V4.31043ZM13.7496 6.77275V19.3778C13.9622 19.2167 14.1955 19.0714 14.4521 18.9438C15.3035 18.5201 16.3617 18.3146 17.6744 18.3146H22.7912C23.2054 18.3146 23.5412 17.9788 23.5412 17.5646V4.31043C23.5412 3.89621 23.2054 3.56042 22.7912 3.56042H16.7786C16.2833 3.61576 15.5269 3.844 14.8985 4.34212C14.2837 4.82947 13.7757 5.5832 13.7496 6.77275ZM11.1007 4.34212C11.7154 4.82947 12.2235 5.5832 12.2496 6.77275V19.5707C11.0833 18.7437 9.50151 18.3146 8.03706 18.3146H3.20801C2.79379 18.3146 2.45801 17.9788 2.45801 17.5646V4.31043C2.45801 3.89621 2.79379 3.56042 3.20801 3.56042H9.22064C9.71593 3.61576 10.4723 3.844 11.1007 4.34212Z"
      />
    </svg>
  ),
);

(Folder as React.FC).displayName = "Folder";
